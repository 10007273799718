import { createSlice } from "@reduxjs/toolkit";


export const areaSlice = createSlice({
    name : 'area',
    initialState:{
        modalStatus:null,
        selectedArea : null
    },
    reducers:{
        openAddAreaModal : (state) => {
            state.modalStatus = "add";
        },
        openEditAreaModal : (state,action) => {
            state.modalStatus = "edit";
            state.selectedArea = action.payload;
        },
        openDeleteAreaModal : (state , action) => {
            state.modalStatus = "delete";
            state.selectedArea = action.payload;
        },
        closeAreaModal : (state) => {
            state.modalStatus = null;
            state.selectedArea = null;
        }
    }
});

export const {
    openAddAreaModal,
    openDeleteAreaModal,
    openEditAreaModal,
    closeAreaModal
} = areaSlice.actions;

export default areaSlice.reducer;