import React, { useState, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm , Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  Button,
  OutlinedInput,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  useGetPriceByIdQuery,
  useUpdatePriceMutation,
} from "../../Store/Services/PriceService";
import { useGetAllCatWithSubCatQuery } from "../../Store/Services/CategoryService";
import CategoryList from "../Shared/CategoryList";
import { useGetAllNoResQuery } from "../../Store/Services/NoOfResourceService";
import { useGetAllNoHrsQuery } from "../../Store/Services/NoOfHrsService";
import styles from "./Price.module.css";

const schema = yup
  .object({
    catId: yup.string().required(""),
    noResId: yup.string().required(""),
    noHrsId: yup.string().required(""),
    off: yup.string().required(""),
    amount: yup.string().required(""),
  })
  .required();

const EditPrice = ({ handleClose }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const price = useSelector((state) => state.price);

  const {
    data: priceDetails,
    isSuccess: priceSuccess,
    isLoading: priceLoading,
  } = useGetPriceByIdQuery(price.selectedPrice);

  const { data: categories, isSuccess: categoriesSuccess } =
    useGetAllCatWithSubCatQuery();
  const { data: noResData, isSuccess: noResSuccess } = useGetAllNoResQuery();
  const { data: noHrsData, isSuccess: noHrsSuccess } = useGetAllNoHrsQuery();

  
  const priceData = useMemo(() => {
    if (
      priceSuccess &&
      Array.isArray(priceDetails) &&
      priceDetails.length > 0
    ) {
      return priceDetails[0];
    }
    return { id: "" };
  }, [priceDetails, priceSuccess]);

  useEffect(() => {
    if (noResSuccess && noResData) {
      const mappedOptions = noResData.map((option) => ({
        value: option.noRes,
        label: option.noRes,
      }));
      setNoResOptions(mappedOptions);
    }
  }, [noResData, noResSuccess]);

  useEffect(() => {
    if (noHrsSuccess && noHrsData) {
      const mappedOptions = noHrsData.map((option) => ({
        value: option.noHrs,
        label: option.noHrs,
      }));
      setNoHrsOptions(mappedOptions);
    }
  }, [noHrsData, noHrsSuccess]);

  const [updatePrice] = useUpdatePriceMutation();
  const [openCatList, setOpenCatList] = useState(false);
  const [catName, setCatName] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [noResOptions, setNoResOptions] = useState([]);
  const [noHrsOptions, setNoHrsOptions] = useState([]);



  useEffect(() => {
    if (
      priceSuccess &&
      Array.isArray(priceDetails) &&
      priceDetails.length > 0 &&
      categoriesSuccess &&
      noResSuccess &&
      noResData &&
      noHrsSuccess &&
      noHrsData
    ) {
      const firstPrice = priceDetails[0];
      const category = findCategoryWithSubcategories(categories, firstPrice.catId);

      reset({
        catId: firstPrice?.catId,
        noResId: firstPrice?.noResId,
        noHrsId: firstPrice?.noHrsId,
        off: firstPrice?.off,
        amount: firstPrice?.amount,
      });

      setCatName(category?.catName);
      setSelectedCat(firstPrice?.catId);
      setValue("catId", category?.catName || "");
      setValue("off", firstPrice?.off !== null ? Number(firstPrice?.off) : 0);
      setValue("amount", firstPrice?.amount !== null ? Number(firstPrice?.amount) : 0);
      setValue("noResId", firstPrice?.noResId || "");
      setValue("noHrsId", firstPrice?.noHrsId || "");
    }
  }, [
    priceDetails,
    priceSuccess,
    categories,
    categoriesSuccess,
    reset,
    setCatName,
    setSelectedCat,
    setValue,
    noResData,
    noResSuccess,
    noHrsData,
    noHrsSuccess,
  ]);

  const findCategoryWithSubcategories = (categoriesArray, targetCatId) => {
    const category = categoriesArray.find((cat) => cat.catId === targetCatId);
  
    if (category) {
      return category;
    }
  
    for (const cat of categoriesArray) {
      if (cat.subCategories) {
        const subcategory = findCategoryWithSubcategories(cat.subCategories, targetCatId);
        if (subcategory) {
          return subcategory;
        }
      }
    }
  
    return null;
  };
  
  const onSubmit = async (data) => {
    const formData = {
      ...data,
      priceTBLId: price.selectedPrice,
      catId: selectedCat,
      noResId: parseInt(data.noResId, 10),
      noHrsId: parseInt(data.noHrsId, 10),
    };

    try {
      await updatePrice(formData).unwrap();
      toast.success("Price successfully updated!", {
        position: "bottom-right",
      });
    } catch (err) {
      console.error("Failed to save the price", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }
    handleClose();
  };

  const onKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

 

  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography
            fontWeight={600}
            className={styles.dialogTitle}
            variant="h6"
          >
            Edit Price
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className={styles.formItem}>
          <label className={styles.formLabel}>Category:</label>
          {selectedCat === "" && <span className={styles.validation}> *</span>}
          <OutlinedInput
            {...register("catId")}
            className={styles.formInput}
            placeholder="Select Category"
            size="small"
            onClick={() => setOpenCatList(true)}
            autoComplete="off"
            fullWidth
            value={catName}
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> No Of Resource :</label>
          {errors.noResId && errors.noResId.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
           <Controller
            name="noResId"
            control={control}
            defaultValue={priceData?.noResId || ""}
            render={({ field }) => (
              <Select
                className={styles.formInput}
                size="small"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                fullWidth
              >
                {noResOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> No Of Hours :</label>
          {errors.noHrsId && errors.noHrsId.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
           <Controller
            name="noHrsId"
            control={control}
            defaultValue={priceData?.noHrsId || ""}
            render={({ field }) => (
              <Select
                className={styles.formInput}
                size="small"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                fullWidth
              >
                {noHrsOptions.map((option) => (
                  <MenuItem style={{display:'flex' ,height:'1.4rem'}} key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Offer :</label>
          {errors.off && errors.off.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("off")}
            className={styles.formInput}
            placeholder="Enter Offer"
            size="small"
            autoComplete="off"
            onKeyDown={onKeyDown}
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Amount :</label>
          {errors.amount && errors.amount.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("amount")}
            className={styles.formInput}
            placeholder="Enter Amount"
            size="small"
            autoComplete="off"
            onKeyDown={onKeyDown}
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
      <CategoryList
        open={openCatList}
        onClose={() => setOpenCatList(false)}
        setSelectedCat={setSelectedCat}
        selectedCat={selectedCat}
        setCatName={setCatName}
        onlySubCategories ={true}
      />
    </div>
  );
};

export default EditPrice;
