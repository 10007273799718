import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeChargeModal } from "../../Store/Slices/chargeSlice"
import AddCharge from "./AddCharge";
import EditCharge from "./EditCharge";
import DeleteCharge from "./DeleteCharge";

const ChargeDialog = () => {
    const dispatch = useDispatch();
    const { modalStatus } = useSelector((state) => state.charge);

    let content;
    let deleteView = false;

    const roles = sessionStorage.getItem("roles");

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            dispatch(closeChargeModal());
        }
    };
    switch (modalStatus) {
        case "add":
            content = roles?.split(",").includes("PRIV_ES_ADD_CHARGE") ? <AddCharge handleClose={handleClose} /> : null;
            break;
        case "edit":
            content = roles?.split(",").includes("PRIV_ES_EDIT_CHARGE") ? <EditCharge handleClose={handleClose} /> : null;
            break;
        case "delete":
            deleteView = true;
            content = roles?.split(",").includes("PRIV_ES_DELETE_CHARGE") ? <DeleteCharge handleClose={handleClose} /> : null;
            break;
        default:
            content = null;
    }

    return (
        <Dialog
            fullWidth={true}
            open={["add", "edit", "delete"].includes(modalStatus)}
            onClose={handleClose}
            aria-labelledby="coupon-dialog-title"
            aria-describedby="coupon-dialog-description"
            
        >
            <DialogContent>{content}</DialogContent>
        </Dialog>
    );
};

export default ChargeDialog;
