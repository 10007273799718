import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Button, IconButton, Typography, OutlinedInput } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import styles from "./Slot.module.css";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";
import {
  useGetSlotByIdQuery,
  useUpdateSlotMutation,
} from "../../Store/Services/SlotService";
import { useSelector } from "react-redux";

const schema = yup
  .object({
    slot: yup.string().required(""),
  })
  .required();

const EditSlot = ({ handleClose }) => {
  const slot = useSelector((state) => state.slot);

  const {
    data: slotDetails,
    isSuccess: slotSuccess,
    isLoading: slotLoading,
  } = useGetSlotByIdQuery(slot.selectedSlot);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (slotSuccess && Array.isArray(slotDetails)) {
      const firstSlot = slotDetails[0];

      reset({
        slot: firstSlot?.slot,
      });
    }
  }, [slotDetails, slotSuccess, reset]);

  const [updateSlot] = useUpdateSlotMutation();

  const onSubmit = async (data) => {
    const dataSet = {
      ...data,
      slotId: slot.selectedSlot,
    };

    try {
      const respond = await updateSlot(dataSet).unwrap();
      toast.success("Slot successfully updated!", { position: "bottom-right" });
    } catch (err) {
      console.error("Failed to update slot: ", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }

    handleClose();
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Slot Creation
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.formItem}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}> Slot :</label>
              {errors.slot && errors.slot.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput
                {...register("slot")}
                className={styles.formInput}
                placeholder="Enter Slot"
                size="small"
                fullWidth
              />
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditSlot;
