import { Button, FormHelperText, Grid, InputAdornment, TextField, Typography, styled } from '@mui/material'
import React from 'react'
import Styles from "./ContactForm.module.css";
import image from "../../Assets/house.png";
import { Send } from '@mui/icons-material';
import { FileUploader } from './FileUploader';
import { useState } from 'react';
import { useAddIssueMutation } from '../../Store/Services/IssueService';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

const FormField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#9747FF',
      fontSize: '1.25rem'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#9747FF',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#9747FF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#9747FF',
      },
      '&:hover fieldset': {
        borderColor: '#9747FF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9747FF',
      },
    },
    '& .MuiInputLabel-root': {
        color: '#9747FF'
    }
});

const schema = yup.object({
  name: yup.string().required("Please enter your name."),
  email: yup.string().required("Please enter a valid email address."),
  message: yup.string().required("Please enter your message.")
});

const ContactForm = () => {

  const [attachment, setAttachment] = useState("")
  const [fileName, setFileName] = useState('')

  const { handleSubmit, reset, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [addIssue] = useAddIssueMutation();

  const submit = async (data) => {
    console.log('data', data);
    const dataSet = {
      ...data,
      attachment: attachment? attachment : null
    }
    try {
      await addIssue(data).unwrap();
      toast.success("Your message send successfully !", { position: "bottom-right" });
      reset();
      setAttachment("")
      setFileName('')
      } catch (err) {
      console.error(err);
      toast.error("Error , Something went wrong", { position: "bottom-right" });
    }
    // fetch('https://issue-management-proxy.server.fouroots.com/n_ach/issue_api/v1/issues', {
    //   method: 'POST',
    //   body: JSON.stringify(data),
    //   headers: {
    //     'Content-type': 'application/json; charset=UTF-8',
    //   },
    // })
    //    .then((response) => response.json())
    //    .then((data) => {
    //       console.log('response' ,data);
    //    })
    //    .catch((err) => {
    //       console.log(err.message);
    //    });
  }

  return (
    <div className={Styles.outerContainer}>
      <Grid container>
        <Grid item xs={12} md={12} className={Styles.textContainer}>
          <div className={Styles.formContainer}>
            <Typography className={Styles.formHeading}>
              Let&apos;s discuss on something cool together
            </Typography>

            <form onSubmit={handleSubmit(submit)} autoComplete="off">
              <FormField
                {...register("name")}
                // onChange={e => setData({...data, name: e.target.value})}
                className={Styles.formInput}
                fullWidth label="Your name" variant="standard"
              />
              {errors.name && <FormHelperText error> {errors.name?.message} </FormHelperText> }
              <FormField
                {...register("email")}
                // onChange={e => setData({...data, emailemail: e.target.value})}
                className={Styles.formInput} fullWidth label="Your email" variant="standard" />
              {errors.email && <FormHelperText error> {errors.email?.message} </FormHelperText> }
              <FormField
                {...register("message")}
                // onChange={e => setData({...data, message: e.target.value})}
                className={Styles.formInput} fullWidth label="Your message" variant="standard" />
              {errors.message && <FormHelperText error> {errors.message?.message} </FormHelperText> }
              <FormField
                className={Styles.formInput} fullWidth label="Attachment" variant="standard" value={""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FileUploader handleFile={(e) => setAttachment(e)} fileName={fileName} setFileName={setFileName} />
                    </InputAdornment>
                  ),
                }}
              />
              <Button type='submit' variant='contained' className={Styles.button}><Send className={Styles.bottonIcon} /> Send Message</Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ContactForm;
