// Import necessary dependencies and components
import React from "react";
import { Dialog, DialogContent, MenuItem, MenuList } from "@mui/material";
import { useGetAllCatWithSubCatQuery } from "../../Store/Services/CategoryService";
import styles from "./CategoryList.module.css";

const CategoryList = ({
  open,
  onClose,
  setSelectedCat,
  selectedCat,
  setCatName,
  onlySubCategories,
}) => {
  const { data: categories, isSuccess } = useGetAllCatWithSubCatQuery();

  let catList;
  if (isSuccess) {
    catList = categories;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <MenuList>
          {catList?.map((cat) => (
            <SubList
              key={cat.catId}
              subCat={cat}
              level={0}
              setSelectedCat={setSelectedCat}
              selectedCat={selectedCat}
              onClose={() => onClose()}
              setCatName={setCatName}
              onlySubCategories={onlySubCategories}
            />
          ))}
        </MenuList>
      </DialogContent>
    </Dialog>
  );
};

const SubList = ({
  subCat,
  level,
  setSelectedCat,
  selectedCat,
  onClose,
  setCatName,
  onlySubCategories,
}) => {


  let dash = "";
  for (var i = 0; i < level; i++) {
    dash = dash + " - ";
  }

 const handleItemSelect = (catId, catName, hasSubcategories) => {

    if (
      !onlySubCategories ||
      (onlySubCategories && (hasSubcategories || level > 0))
    ) {
      setSelectedCat(catId);
      setCatName(catName);
      onClose(); 
    }
};


  const renderMenuItem = (cat) => (
    <MenuItem
      className={selectedCat === cat.catId ? styles.selected : "null"}
      onClick={() => handleItemSelect(cat.catId, cat.catDisplay)}
      key={cat.catId}
    >
      {`${dash} ${cat.catDisplay}`}
    </MenuItem>
  );

  return (
    <>
      {renderMenuItem(subCat)}

      {subCat.subCategories &&
      Array.isArray(subCat.subCategories) &&
      subCat.subCategories.length !== 0
        ? subCat.subCategories.map((cat) => (
            <SubList
              key={cat.catId}
              subCat={cat}
              level={level + 1}
              setSelectedCat={setSelectedCat}
              selectedCat={selectedCat}
              onClose={() => onClose()}
              setCatName={setCatName}
              onlySubCategories={onlySubCategories}
            />
          ))
        : null}
    </>
  );
};

export default CategoryList;
