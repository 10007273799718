import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeSlotModal } from "../../Store/Slices/slotSlice";
import AddSlot from "./AddSlot";
import EditSlot from "./EditSlot";
import DeleteSlot from "./DeleteSlot";

const SlotDialog = () => {
  const dispatch = useDispatch();
  const slot = useSelector((state) => state.slot);

  let content;
  let deleteView = false;

  const roles = sessionStorage.getItem("roles");

  const handleClose = () => {
    dispatch(closeSlotModal(false));
  };

  if (slot.modalStatus === "add") {
    content = roles?.split(",").includes("PRIV_ES_ADD_SLOT") ? <AddSlot handleClose={() => handleClose()} /> : null;
  } else if (slot.modalStatus === "edit") {
    content = roles?.split(",").includes("PRIV_ES_EDIT_SLOT") ? <EditSlot handleClose={() => handleClose()} /> : null;
  } else if (slot.modalStatus === "delete") {
    deleteView = true;
    content = roles?.split(",").includes("PRIV_ES_DELETE_SLOT") ? <DeleteSlot /> : null;
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={deleteView ? "xs" : "sm"}
        open={
          slot.modalStatus === "add" ||
          slot.modalStatus === "edit" ||
          slot.modalStatus === "delete"
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </>
  );
};

export default SlotDialog;