import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeBrPrivModal } from "../../Store/Slices/brPrivSlice";
import AddBrPriv from "./AddBrPriv";
import DeleteBrPriv from "./DeleteBrPriv";
import EditBrPriv from "./EditBrPriv";
import { useEffect } from "react";
import { useState } from "react";

const BrPrivDialog = () => {
  const dispatch = useDispatch();
  const brPriv = useSelector((state) => state.brPriv);

  let content;
  let deleteView = false;

  const handleClose = () => {
    dispatch(closeBrPrivModal(false));
  };

  const roles = sessionStorage.getItem("roles");

  if (brPriv.modalStatus === "add") {
    content = roles?.split(",").includes("PRIV_ES_ADD_MAPPING") ?  <AddBrPriv handleClose={handleClose} /> : null;
  } else if (brPriv.modalStatus === "edit") {
    content = roles?.split(",").includes("PRIV_ES_EDIT_MAPPING") ? <EditBrPriv handleClose={handleClose} /> : null;
  } else if (brPriv.modalStatus === "delete") {
    content = roles?.split(",").includes("PRIV_ES_DELETE_MAPPING") ? <DeleteBrPriv /> : null;
  }

  return (
    <Dialog
      fullWidth={true}
      sx={{
        marginLeft: deleteView ? "25rem" : "18rem",
        marginRight: deleteView ? "25rem" : "18rem",
        maxWidth: deleteView ? "fit-content" : "none",
      }}
      open={
        brPriv.modalStatus === "add" ||
        brPriv.modalStatus === "edit" ||
        brPriv.modalStatus === "delete"
      }
    >
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default BrPrivDialog;
