// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.AddAddress_headingContainer__NH4u\\+ {
    display: flex;
    justify-content: space-between;
}

.AddAddress_closeButton__\\+wJO2 {
    padding: 0.2rem;
    border: 1px #9964df solid;
    color: #9964df;
}

.AddAddress_formItem__dIouJ {
    margin-top: 1rem;
}

.AddAddress_formLabel__aIrZT {
    font-weight: 600;
}

.AddAddress_formInput__op11N {
    margin-top: 0.3rem !important;
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 2px 2px 50px 2px rgb(180, 122, 255, 0.5);
}

.AddAddress_validation__kMTE5 {
    color: red;
}

.AddAddress_buttonContainer__8CQWg {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

.AddAddress_submitButton__Vkaub {
    border-radius: 1rem;
    width: 10rem;
}

.AddAddress_locationButton__eDR5j {
    border-radius: 1rem;
    width: 10rem;
    margin-left: 0.5rem;
}

.AddAddress_dialog__woYTz{
    border-radius: 10px;
    background: linear-gradient(91deg, rgb(151 71 255 / 10%) 23.22%, rgb(151 71 255 / 0%) 99.28%);
}`, "",{"version":3,"sources":["webpack://./src/Components/Address/AddAddress.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,wBAAwB;IACxB,gBAAgB;IAChB,oDAAoD;AACxD;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,6FAA6F;AACjG","sourcesContent":["\r\n.headingContainer {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.closeButton {\r\n    padding: 0.2rem;\r\n    border: 1px #9964df solid;\r\n    color: #9964df;\r\n}\r\n\r\n.formItem {\r\n    margin-top: 1rem;\r\n}\r\n\r\n.formLabel {\r\n    font-weight: 600;\r\n}\r\n\r\n.formInput {\r\n    margin-top: 0.3rem !important;\r\n    border-radius: 0.9375rem;\r\n    background: #FFF;\r\n    box-shadow: 2px 2px 50px 2px rgb(180, 122, 255, 0.5);\r\n}\r\n\r\n.validation {\r\n    color: red;\r\n}\r\n\r\n.buttonContainer {\r\n    margin-top: 2rem;\r\n    margin-bottom: 1rem;\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.submitButton {\r\n    border-radius: 1rem;\r\n    width: 10rem;\r\n}\r\n\r\n.locationButton {\r\n    border-radius: 1rem;\r\n    width: 10rem;\r\n    margin-left: 0.5rem;\r\n}\r\n\r\n.dialog{\r\n    border-radius: 10px;\r\n    background: linear-gradient(91deg, rgb(151 71 255 / 10%) 23.22%, rgb(151 71 255 / 0%) 99.28%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingContainer": `AddAddress_headingContainer__NH4u+`,
	"closeButton": `AddAddress_closeButton__+wJO2`,
	"formItem": `AddAddress_formItem__dIouJ`,
	"formLabel": `AddAddress_formLabel__aIrZT`,
	"formInput": `AddAddress_formInput__op11N`,
	"validation": `AddAddress_validation__kMTE5`,
	"buttonContainer": `AddAddress_buttonContainer__8CQWg`,
	"submitButton": `AddAddress_submitButton__Vkaub`,
	"locationButton": `AddAddress_locationButton__eDR5j`,
	"dialog": `AddAddress_dialog__woYTz`
};
export default ___CSS_LOADER_EXPORT___;
