

import { useRef } from 'react';         
import Styles from "./FileUploader.module.css"
import { IconButton, Typography } from '@mui/material';
import { AttachFile, Close } from '@mui/icons-material';
import { useState } from 'react';

export const FileUploader = ({handleFile, fileName, setFileName}) => {

    const hiddenFileInput = useRef(null); 

    const handleClick = event => {
        hiddenFileInput.current.click();   
    };

    const handleChange = event => {
        // event.preventDefault()
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            handleFile(reader.result);
        };

        reader.onerror = (error) => {
            console.log('Error: ', error);
        };

        setFileName(file.name)
    };

    const onCloseFile = () => {
        setFileName('')
    }

    return (
        <>
        <IconButton className={Styles.attachButton}
            onClick={handleClick}
        >
            <AttachFile className={Styles.attachButtonFile} />
        </IconButton>
        <Typography>{fileName || 'Add file...'}</Typography>
        {fileName && <IconButton className={Styles.closeButton} sx={{padding: 0}} onClick={() => onCloseFile()}>
            <Close className={Styles.close} sx={{width:'1.3rem'}} />
        </IconButton>}
        <input 
            type="file"
            onChange={(e)=>handleChange(e)}
            ref={hiddenFileInput}
            style={{display:'none'}}
        />
        </>
    );
};