import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDeleteCalMutation } from "../../Store/Services/CalService";
import { closeCalModal } from "../../Store/Slices/calSlice";
import DeletePopUp from "../Shared/DeletePopUp";

const DeleteCal = () => {
  const dispatch = useDispatch();
  const cal = useSelector((state) => state.cal);

  const [deleteCal] = useDeleteCalMutation();

  const onDeleteConfirm = async () => {
    const id = cal.selectedCal;
    try {
      const response = await deleteCal(id);
      const deletedCal = response.data; 
      toast.success("Cal successfully deleted!", {position:'bottom-right'});
    } catch (err) {
      console.error("Failed to delete cal: ", err);
      toast.error("Error, something went wrong!", {position:'bottom-right'});
    }
    dispatch(closeCalModal());
  };
  

  return (
    <DeletePopUp
      data="Date"
      close={() => dispatch(closeCalModal())}
      onDeleteConfirm={onDeleteConfirm}
    />
  );
};

export default DeleteCal;
