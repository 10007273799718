import { useSelector, useDispatch } from "react-redux";
import { useDeleteHrsMutation } from "../../Store/Services/NoOfHrsService";
import { closeHrsModal } from "../../Store/Slices/noHrsSlice";
import DeletePopUp from "../Shared/DeletePopUp";


const DeleteHours = ({handleClose}) => {
    const dispatch = useDispatch();
    const {selectedHrs} = useSelector((state) => state.hrs);

    const [deleteHrs] = useDeleteHrsMutation();

    const onDeleteConfirm = async () =>{
        try{
            await deleteHrs(selectedHrs).unwrap();
        }catch(err){
            console.error(err);
        }

        dispatch(closeHrsModal());
    };

    return (
        <DeletePopUp
            data="No Of Hours"
            close={() => dispatch(closeHrsModal())}
            onDeleteConfirm={onDeleteConfirm}
        />
    );
}


export default DeleteHours;