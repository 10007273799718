import { useSelector, useDispatch } from "react-redux";
import { useDeletePrivMutation } from "../../Store/Services/PrivilegeService";
import { closePrivModal } from "../../Store/Slices/privSlice";
import DeletePopUp from "../Shared/DeletePopUp";


const DeletePrivilege = ({handleClose}) => {
    const dispatch = useDispatch();
    const {selectedPriv} = useSelector((state) => state.priv);

    const [deletePrivilege] = useDeletePrivMutation();

    const onDeleteConfirm = async () => {
        try{
            await deletePrivilege(selectedPriv).unwrap();
        }catch(err){
            console.error("Failed to delete the privilege",err);
        }
        dispatch(closePrivModal());
    }


        return (
            <DeletePopUp
                data="Privilege"
                close={() => dispatch(closePrivModal())}
                onDeleteConfirm={onDeleteConfirm}
            />
        );
    
}

export default DeletePrivilege;