// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Slot_headingContainer__QylEn {
  display: flex;
  justify-content: space-between;
}

.Slot_closeButton__HFKp0 {
  padding: 0.2rem;
  border: 1px #47C2BB solid;
  color: #47C2BB;
}

.Slot_formItem__UBo7R {
  margin-top: 1rem;
}

.Slot_formLabel__YZkjW {
  font-weight: 600;
}

.Slot_formInput__iWacq {
  margin-top: 0.3rem;
}

.Slot_validation__IDvoC {
  color: red;
}

.Slot_buttonContainer__D83qk {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.Slot_submitButton__BvXIB {
  width: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Slot/Slot.module.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd","sourcesContent":["\r\n\r\n.headingContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.closeButton {\r\n  padding: 0.2rem;\r\n  border: 1px #47C2BB solid;\r\n  color: #47C2BB;\r\n}\r\n\r\n.formItem {\r\n  margin-top: 1rem;\r\n}\r\n\r\n.formLabel {\r\n  font-weight: 600;\r\n}\r\n\r\n.formInput {\r\n  margin-top: 0.3rem;\r\n}\r\n\r\n.validation {\r\n  color: red;\r\n}\r\n\r\n.buttonContainer {\r\n  margin-top: 2rem;\r\n  margin-bottom: 1rem;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.submitButton {\r\n  width: 10rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingContainer": `Slot_headingContainer__QylEn`,
	"closeButton": `Slot_closeButton__HFKp0`,
	"formItem": `Slot_formItem__UBo7R`,
	"formLabel": `Slot_formLabel__YZkjW`,
	"formInput": `Slot_formInput__iWacq`,
	"validation": `Slot_validation__IDvoC`,
	"buttonContainer": `Slot_buttonContainer__D83qk`,
	"submitButton": `Slot_submitButton__BvXIB`
};
export default ___CSS_LOADER_EXPORT___;
