import { useDispatch,useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closePrivModal } from "../../Store/Slices/privSlice"
import AddPrivilege from "./AddPrivilege";
import EditPrivilege from "./EditPrivilege";
import DeletePrivilege from "./DeletePrivilege";


const PrivilegeDialog = () => {
    const dispatch = useDispatch();
    const {modalStatus} = useSelector((state) => state.priv);

    let content;
    let deleteView = false;

    const roles = sessionStorage.getItem("roles");

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            dispatch(closePrivModal());
        }
    };

    switch (modalStatus) {
        case "add":
            content = roles?.split(",").includes("PRIV_ES_ADD_PRIVILEGE") ? <AddPrivilege handleClose={handleClose} /> : null;
            break;
        case "edit":
            content = roles?.split(",").includes("PRIV_ES_EDIT_PRIVILEGE") ? <EditPrivilege handleClose={handleClose} /> : null;
            break;
        case "delete":
            deleteView = true;
            content = roles?.split(",").includes("PRIV_ES_DELETE_PRIVILEGE") ? <DeletePrivilege handleClose={handleClose} /> : null;
            break;
        default:
            content = null;
    };

    return (
        <Dialog
            fullWidth={true}
            open={["add", "edit", "delete"].includes(modalStatus)}
            onClose={handleClose}
            aria-labelledby="coupon-dialog-title"
            aria-describedby="coupon-dialog-description"
            
        >
            <DialogContent>{content}</DialogContent>
        </Dialog>
    );
};

export default PrivilegeDialog;