import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { openAddCouponModal , closeCouponModal } from "../../Store/Slices/couponSlice";
import { useAddCouponMutation } from "../../Store/Services/CouponService";
import styles from "./AddCoupon.module.css";
import {toast} from 'react-toastify'

const schema = yup.object({
  couponCode: yup.string().required("Coupon code is required"),
  off: yup.string().required('Offer amount is required')
});



const AddCoupon = ({handleClose}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  
  const [addCoupon] = useAddCouponMutation();



  const submit = async (data) => {
    try {
      await addCoupon(data).unwrap();
      handleClose();
      toast.success("Coupon added successfully !" ,  {position:'bottom-right'});
    } catch (err) {
      console.error(err);
      toast.error("Error , Something went wrong" ,  {position:'bottom-right'});
    }
  };
  
  const onKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
            Add Coupon
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(submit)} autoComplete="off">
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Coupon Code :</label>
          {errors.couponCode && errors.couponCode.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("couponCode")}
            className={styles.formInput}
            placeholder="Enter Coupon Code"
            size="small"
            fullWidth
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formLabel}> Offer :</label>
          {errors.off && errors.off.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput {...register('off')}
            className={styles.formInput}
            placeholder="Enter Offer"
            size="small"
            autoComplete="off"
            onKeyDown={onKeyDown}
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCoupon;
