import { Box, Button, Container, Paper, Toolbar, Typography } from "@mui/material"
import { useState } from "react";
import { useDispatch } from "react-redux"
import SearchBar from "../Components/Shared/SearchBar";
import PriceDialog from "../Components/Price/PriceDialog";
import PriceList from "../Components/Price/PriceList";
import {useGetAllPriceQuery} from "../Store/Services/PriceService";
import { closePriceModal, openAddPriceModal } from "../Store/Slices/priceSlice";
import styles from "./Management.module.css"



const PriceManagement = () => {
    const dispatch = useDispatch();
    const [searchData , setSearchData] = useState([]);
    const {data , isSuccess , isLoading} = useGetAllPriceQuery();

   

    const handleClose = () => {
        dispatch(closePriceModal());
    }

    const roles =  sessionStorage.getItem("roles");

    return (
        roles?.split(",").includes("PRIV_ES_DISPLAY_PRICE_TBL") ? (
        <Paper elevation={3} className={styles.pageContainer} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/thumbnail_Management.png)`}}>
            <Box>
                <Toolbar className={styles.subNavBar}>
                    <Typography className={styles.topic}>Price Table Management</Typography>
                    {roles?.split(",").includes("PRIV_ES_ADD_PRICE_TBL") ?  (<Button variant="contained" color="primary" className={styles.buttonItem} onClick={() => dispatch(openAddPriceModal())}>Add Price</Button>) : null}
                </Toolbar>
            </Box>
            <Container id={styles.container}>
                <SearchBar
                    data={isSuccess || isLoading ? data : []}
                    setSearchData={setSearchData}
                    text="Search Categories"
                    search="price"

                />
            </Container>

            <Container>
               {isSuccess && <PriceList data={Array.isArray(searchData) && searchData.length > 0 ? searchData : data}  />}
            </Container>

            <PriceDialog handleClose={handleClose} />
        </Paper> ) : null
    )
};


export default PriceManagement;