import React from 'react';
import Styles from "./vision.module.css";
import { Typography } from '@mui/material';

const Vision = () => {
  return (
    <div className={Styles.visionContainer}>
      <div className={Styles.visionBox}>
        <div className={Styles.topic}>
          <div className={Styles.topicUnderline}></div>
          <Typography className={Styles.subTopic}>Our Vision</Typography>
        </div>
        <Typography className={Styles.description}>
        At DoneRight, we uphold the utmost standards of cleanliness while prioritizing discretion and privacy. With our innovative cleaning techniques and dedication to confidentiality, we ensure your space not only shines with cleanliness but also maintains its integrity. Experience the transformative power of our cleaning solutions. Simplify your life, enhance your surroundings, and join our satisfied clients in enjoying pristine spaces with DoneRight.
        </Typography>
      </div>
    </div>
  );
};

export default Vision;