import { apiSlice } from '../Slices/apiSlice';

export const CalService = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllCals: builder.query({
            query: () => "/cals",
            providesTags: ['cal']
        }),

        getCalById: builder.query({
            query: (calId) => `/cals/${calId}`,
            providesTags: ['cal']
        }),

        createCal: builder.mutation({
            query: (details) => ({
                url: '/cals',
                method: 'POST',
                body: details
            }),
            invalidatesTags: ['cal']
        }),

        updateCal: builder.mutation({
            query: (details) => ({
                url: `/cals/${details.calId}`,
                method: 'PUT',
                body: details
            }),
            invalidatesTags: ['cal']
        }),

        deleteCal: builder.mutation({
            query: (id) => ({
                url: `/cals/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['cal']
        }),

    })
});

export const {
    useGetAllCalsQuery,
    useGetCalByIdQuery,
    useCreateCalMutation,
    useUpdateCalMutation,
    useDeleteCalMutation,
} = CalService;