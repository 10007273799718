import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import {
  useUpdateStateMutation,
  useGetStateByIdQuery,
} from "../../Store/Services/StateService";
import { useSelector } from "react-redux";
import styles from "./AddState.module.css";
import CloseIcon from "@mui/icons-material/Close";
 
const schema = yup.object().shape({
  state: yup.string().required(),
});
 
const EditState = ({ handleClose }) => {
  const state = useSelector((state) => state.state);
 
  let stateData = useMemo(() => {
    return { id: "" };
  }, []);
 
  const {
    data: stateDetails,
    isSuccess: stateSuccess,
    isLoading: stateLoading,
} = useGetStateByIdQuery(state.selectedState);
 
 
  if (stateSuccess) {
    stateData = stateDetails?.stateList?.[0];
  }
 
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
 
  useEffect(() => {
    if (stateSuccess && Array.isArray(stateDetails) && stateDetails.length > 0) {
      const firstState = stateDetails[0];
      reset({
        state: firstState?.state,
      });
    }
  }, [stateDetails, stateSuccess, reset]);
 
 
  const [updateState] = useUpdateStateMutation();
 
  const submit = async (data) => { 
    const dataSet = {
      ...data,
      stateId: state.selectedState
    };
 
    try {
      const res = await updateState(dataSet).unwrap();
      toast.success("State successfully updated!", {position:'bottom-right'});
    } catch (err) {
      toast.error("Error, something went wrong!", {position:'bottom-right'});
    }
 
    handleClose();
  };
 
  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
            Edit State
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
 
      <form onSubmit={handleSubmit(submit)}>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> State :</label>
          {errors.state && errors.state.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("state")}
            className={styles.formInput}
            placeholder="Enter State"
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
 
export default EditState;