import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDeleteNoOfResMutation } from "../../Store/Services/NoOfResourceService";
import { closeNoOfResourceModal } from "../../Store/Slices/noOfResourceSlice";
import DeletePopUp from "../Shared/DeletePopUp";


const DeleteNoOfResource = () => {
    const dispatch = useDispatch();
    const selectedRes = useSelector((state) => state.res);


    const [deleteNoOfRes] = useDeleteNoOfResMutation();

    const onDeleteConfirm = async () => {
        const id = selectedRes.selectedNoOfRes;
        try {
          const response = await deleteNoOfRes(id);
          const deletedNoOfRes = response.data; 
          toast.success("No of Resources successfully deleted!", {position:'bottom-right'});
        } catch (err) {
          console.error("Failed to delete No of Resources: ", err);
          toast.error("Error, something went wrong!", {position:'bottom-right'});
        }
        dispatch(closeNoOfResourceModal());
      };

    return (
        <DeletePopUp
            data="No Of NoOfRes"
            close={() => dispatch(closeNoOfResourceModal())}
            onDeleteConfirm={onDeleteConfirm}
        />
    );
};

export default DeleteNoOfResource;