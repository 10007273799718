import { apiSlice } from "../Slices/apiSlice";

export const ResCalSlotService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getAllResCalSlot: builder.query({
      query: () => "/res-cal-slot",
      providesTags: ["resCal"],
    }),

    getResCalSlotById: builder.query({
      query: (resCalSlotId) => `/res-cal-slot/${resCalSlotId}`,
      providesTags: ["resource"],
    }),

    createResCalSlot: builder.mutation({
      query: (Details) => ({
        url: `/res-cal-slot`,
        method: "POST",
        body: Details,
      }),
      invalidatesTags: ["resource"],
    }),

    CreateMultiResCalSlot: builder.mutation({
      query: (Details) => ({
        url: `/res-cal/multiple-cal-slot`,
        method: "PUT",
        body: Details,
      }),
      invalidatesTags: ["resource"],
    }),

    updateResCalSlot: builder.mutation({
      query: (details) => ({
        url: `res-cal-slot/${details.resCalSlotId}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["resource"],
    }),

    deleteResCalSlot: builder.mutation({
      query: (resCalSlotId) => ({
        url: `/res-cal-slot/${resCalSlotId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["resource"],
    }),  
  }),
});

export const {
  useGetAllResCalSlotQuery,
  useGetResCalSlotByIdQuery,
  useCreateResCalSlotMutation,
  useCreateMultiResCalSlotMutation,
  useUpdateResCalSlotMutation,
  useDeleteResCalSlotMutation,
} = ResCalSlotService;
