import React, { useState } from "react";
import styles from "./Management.module.css";
import {
  Box,
  Button,
  Paper,
  Toolbar,
  Typography,
  Container,
} from "@mui/material";
import CalendarView from "../Components/Calendar/CalendarView";
import CalDialog from "../Components/Calendar/CalDialog";
import { openAddCalModal } from "../Store/Slices/calSlice";
import { useDispatch } from "react-redux";

const CalendarManagement = () => {
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState([]);
  const [formattedDate, setFormattedDate] = useState(null);
  const [unavailableDates, setUnavailableDates] = useState([]);

  const handleDateChange = (newSelectedDates, formattedDate) => {
    setSelectedDates(newSelectedDates);
    setFormattedDate(formattedDate);

  };

  const roles = sessionStorage.getItem("roles");

  return (
    roles?.split(",").includes("PRIV_ES_DISPLAY_CALENDAR") ? (
    <Paper elevation={3} className={styles.pageContainer} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/thumbnail_Management.png)`}}>
      <Box>
        <Toolbar className={styles.subNavBar}>
          <Typography className={styles.topic}>Calendar Management</Typography>
          {roles?.split(",").includes("PRIV_ES_ADD_CALENDAR") ?  (<Button
            variant="contained"
            color="primary"
            className={styles.buttonItem}
            onClick={() => dispatch(openAddCalModal())}
          >
            Add Dates
          </Button>) : null}
        </Toolbar>
        <CalendarView
          onDateChange={(newSelectedDates, formattedDate) =>
            handleDateChange(newSelectedDates, formattedDate)
          }
          initialSelectedDates={selectedDates}
          unavailableDates={unavailableDates}
        />
      </Box>
      <Container id={styles.container}></Container>

      <CalDialog selectedDates={selectedDates} formattedDate={formattedDate} />
    </Paper> ) : null
  );
};

export default CalendarManagement;
