// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Request_dialogContent__7dNd6 {
  background-color: #f8f9fa;
  padding: 20px;
  max-width: 800px;
}

.Request_subheadingContainer__v8ani {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: #47c2bc98; 
  color: #000000;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  margin-top: 10px;

}

.Request_headingContainer__94Oto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.Request_modalTitle__Xl9Fs {
  font-weight: 600;
}

.Request_title__PcHbN {
  font-weight: 600;
}

.Request_titleLabel__3wS3s {
  margin-top: -20px;
}
.Request_closeButton__Gl3Gx {
  padding: 5px;
  color: #47c2bc;
  margin-left: 300px;
}

.Request_btnse__0lTue{
  left: 30rem;
}

.Request_btnsv__t-jLf{
  left: 27rem;
}

.Request_formContainer__RuJHV {
  padding-left: 30px;
  padding-right: 30px;
}

.Request_listItem__rWQ1p {
  margin-bottom: 15px;
}

.Request_commentTitle__W-ccK {
  font-weight: 600;
}

.Request_errorText__FKkig {
  color: #dc3545;
}

.Request_submitButton__tIWyM {
  margin-top: 20px;
  width: 10rem;
}

.Request_formInput__XK8di {
  margin-top: 0.3rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Requests/Request.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;;AAElB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".dialogContent {\r\n  background-color: #f8f9fa;\r\n  padding: 20px;\r\n  max-width: 800px;\r\n}\r\n\r\n.subheadingContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 10px;\r\n  background-color: #47c2bc98; \r\n  color: #000000;\r\n  padding-top: 6px;\r\n  padding-bottom: 6px;\r\n  padding-left: 8px;\r\n  margin-top: 10px;\r\n\r\n}\r\n\r\n.headingContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.modalTitle {\r\n  font-weight: 600;\r\n}\r\n\r\n.title {\r\n  font-weight: 600;\r\n}\r\n\r\n.titleLabel {\r\n  margin-top: -20px;\r\n}\r\n.closeButton {\r\n  padding: 5px;\r\n  color: #47c2bc;\r\n  margin-left: 300px;\r\n}\r\n\r\n.btnse{\r\n  left: 30rem;\r\n}\r\n\r\n.btnsv{\r\n  left: 27rem;\r\n}\r\n\r\n.formContainer {\r\n  padding-left: 30px;\r\n  padding-right: 30px;\r\n}\r\n\r\n.listItem {\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.commentTitle {\r\n  font-weight: 600;\r\n}\r\n\r\n.errorText {\r\n  color: #dc3545;\r\n}\r\n\r\n.submitButton {\r\n  margin-top: 20px;\r\n  width: 10rem;\r\n}\r\n\r\n.formInput {\r\n  margin-top: 0.3rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContent": `Request_dialogContent__7dNd6`,
	"subheadingContainer": `Request_subheadingContainer__v8ani`,
	"headingContainer": `Request_headingContainer__94Oto`,
	"modalTitle": `Request_modalTitle__Xl9Fs`,
	"title": `Request_title__PcHbN`,
	"titleLabel": `Request_titleLabel__3wS3s`,
	"closeButton": `Request_closeButton__Gl3Gx`,
	"btnse": `Request_btnse__0lTue`,
	"btnsv": `Request_btnsv__t-jLf`,
	"formContainer": `Request_formContainer__RuJHV`,
	"listItem": `Request_listItem__rWQ1p`,
	"commentTitle": `Request_commentTitle__W-ccK`,
	"errorText": `Request_errorText__FKkig`,
	"submitButton": `Request_submitButton__tIWyM`,
	"formInput": `Request_formInput__XK8di`
};
export default ___CSS_LOADER_EXPORT___;
