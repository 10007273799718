import { Box, Button, Container, Paper, Toolbar, Typography } from "@mui/material"
import { useState } from "react";
import { useDispatch } from "react-redux"
import SearchBar from "../Components/Shared/SearchBar";
import HoursDialog from "../Components/NoHours/HoursDialog";
import HoursList from "../Components/NoHours/HoursList";
import { useGetAllNoHrsQuery } from "../Store/Services/NoOfHrsService";
import { closeHrsModal, openAddHrsModal } from "../Store/Slices/noHrsSlice";
import styles from "./Management.module.css";


const NoOfHrsManagement = () => {
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState([])
    const { data, isSuccess, isLoading } = useGetAllNoHrsQuery();


    const handleClose = () => {
        dispatch(closeHrsModal());
    }

    const roles = sessionStorage.getItem("roles");

    return (
        roles?.split(",").includes("PRIV_ES_DISPLAY_NO_HRS") ? (
        <Paper elevation={3} className={styles.pageContainer} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/thumbnail_Management.png)`}}>
            <Box>
                <Toolbar className={styles.subNavBar}>
                    <Typography className={styles.topic}>No Of Hours Management</Typography>
                    {roles?.split(",").includes("PRIV_ES_ADD_NO_HRS") ?  (<Button variant="contained" color="primary" className={styles.buttonItem} onClick={() => dispatch(openAddHrsModal())}>Add No Of Hours</Button>) : null}
                </Toolbar>
            </Box>
            <Container id={styles.container}>
                <SearchBar
                    data={isSuccess || isLoading ? data : []}
                    setSearchData={setSearchData}
                    text="Search Number of Hours"
                    search="hrs"
                />
            </Container>

            <Container>
               {isSuccess && <HoursList data={Array.isArray(searchData) && searchData.length > 0 ? searchData : data}/>}
            </Container>

            <HoursDialog handleClose={handleClose} />
        </Paper> ) : null
    )
};

export default NoOfHrsManagement;