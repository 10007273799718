import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeHrsModal } from "../../Store/Slices/noHrsSlice"
import AddHours from "./AddHours";
import EditHours from "./EditHours";
import DeleteHours from "./DeleteHours";


const HoursDialog = () => {
    const dispatch = useDispatch();
    const {modalStatus} = useSelector((state) => state.hrs);

    let content;
    let deleteView = false;

    const roles = sessionStorage.getItem("roles");

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            dispatch(closeHrsModal());
        }
    };

    switch (modalStatus) {
        case "add":
            content = roles?.split(",").includes("PRIV_ES_ADD_NO_HRS") ? <AddHours handleClose={handleClose} /> : null;
            break;
        case "edit":
            content = roles?.split(",").includes("PRIV_ES_EDIT_NO_HRS") ? <EditHours handleClose={handleClose} /> : null;
            break;
        case "delete":
            deleteView = true;
            content =  roles?.split(",").includes("PRIV_ES_DELETE_NO_HRS") ? <DeleteHours handleClose={handleClose} /> : null;
            break;
        default:
            content = null;
    };

    return (
        <Dialog
            fullWidth={true}
            open={["add", "edit", "delete"].includes(modalStatus)}
            onClose={handleClose}
            aria-labelledby="coupon-dialog-title"
            aria-describedby="coupon-dialog-description"
            
        >
            <DialogContent>{content}</DialogContent>
        </Dialog>
    );
}

export default HoursDialog;