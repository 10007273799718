import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Button, IconButton, Typography, TextField, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useUpdateAreaMutation, useGetAreaByIdQuery } from '../../Store/Services/AreaService';
import { useGetAllStatesQuery } from '../../Store/Services/StateService';
import styles from './AddArea.module.css';
import { useSelector } from 'react-redux';
import { skipToken } from "@reduxjs/toolkit/dist/query";


const schema = yup.object().shape({
  state: yup.object().required("State is required"),
  desc: yup.string().required("Area is required"),
})

const EditArea = ({ handleClose }) => {
  const { control, handleSubmit, formState: { errors }, register ,  setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const area = useSelector((state) => state.area.selectedArea);
  const { data: states, isLoading: statesLoading } = useGetAllStatesQuery();
  const { data: areaDetails, isSuccess: areaSuccess } = useGetAreaByIdQuery(area ? area : skipToken);
  const [updateArea] = useUpdateAreaMutation();
  const [selectedState, setSelectedState] = useState(null);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (areaSuccess && areaDetails) {
      const firstArea = areaDetails[0];
      const initialState = states.find(state => state.stateId === firstArea.stateId);
      setSelectedState(initialState);
      setDescription(firstArea?.desc);
      setValue('state', initialState);
      setValue('desc', firstArea?.desc);
    }
  }, [areaDetails, areaSuccess, setValue, states]);

  const submit = async (data) => {
    try {
      await updateArea({
        areaId: area,
        stateId: data.state?.stateId || selectedState?.stateId, 
        desc: data.desc || description
      }).unwrap();
      toast.success('Area updated successfully!', { position: 'bottom-right' });
      handleClose();
    } catch (err) {
      console.error(err);
      toast.error('Error, something went wrong', { position: 'bottom-right' });
    }
  };

  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer} style={{marginBottom:'2rem'}}>
        <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
          Edit Area
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <form onSubmit={handleSubmit(submit)} autoComplete="off">
        <div className={styles.formItem}>
        <label className={styles.formLabel} > State :</label>
        {errors.state && <span className={styles.validation}> *</span>}
          <Controller
            name="state"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
              {...register("state")}
                {...field}
                options={states || []}
                getOptionLabel={(option) => option.state || ''} 
                loading={statesLoading}
                value={selectedState}
                onChange={(_, value) => {
                  setSelectedState(value);
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    className={styles.formInput}
                    fullWidth
                  />
                )}
              />
            )}
          />
        </div>

        <div className={styles.formItem}>
        <label className={styles.formLabel}> Area :</label>
        {errors.desc && <span className={styles.validation}> *</span>}
          <TextField
            {...register("desc")}
            defaultValue={description}
            size="small"
            className={styles.formInput}
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button type="submit" className={styles.submitButton} variant="contained">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditArea;

