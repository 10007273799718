const commentListData = [
  {
    commentId: 1,
    comment: "Sample comment 1",
    validation: "Submited",
  },
  {
    commentId: 2,
    comment: "Sample comment 2",
    validation: "Approved",
  },
  {
    commentId: 3,
    comment: "Sample comment 3",
    validation: "Rejected",
  },
  {
    commentId: 4,
    comment: "Sample comment 4",
    validation: "Submited",
  },
  {
    commentId: 5,
    comment: "Sample comment 5",
    validation: "Approved",
  },
  {
    commentId: 6,
    comment: "Sample comment 6",
    validation: "Rejected",
  },
];

export default commentListData;
