import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Styles from "./intro.module.css";
import { isMobile } from 'react-device-detect';

const Intro = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact'); 
  };

  const image = `${process.env.REACT_APP_IMAGE_URL}DoneRight/PAGES/about-us.jpg`
  const imageMobile = `${process.env.REACT_APP_IMAGE_URL}DoneRight/PAGES/about-us-mobile.jpg`

  return (
    <div className={Styles.outerContainer}>
      <img className={Styles.mainImage} src={isMobile?imageMobile:image} alt="about us" loading='lazy' />
      <div className={Styles.textContainer}>
        <Typography className={Styles.topic}>About Us</Typography>
        <Typography className={Styles.description}>Crafted with precision and passion, we've assembled a unique blend of cleaning mastery, customer care, and efficiency to fulfill your aspirations for spotless spaces. Welcome to our world of pristine perfection.</Typography>
        <Button variant='contained' color='primary' onClick={handleClick}>Let's Talk</Button>
      </div>
    </div>
  );
};

export default Intro;
