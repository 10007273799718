import {apiSlice} from '../Slices/apiSlice';


export const CouponService = apiSlice.injectEndpoints({
    endpoints : builder => ({
        getAllCoupons : builder.query({
            query : () => "/coupons",
            providesTags : ['coupon']
        }),

        getCouponById : builder.query({
            query : (couponId) => `/coupons/${couponId}`,
            providesTags : ['coupon']
        }),

        addCoupon : builder.mutation({
            query : (couponDetails) => ({
                url : '/coupons',
                method:'POST',
                body:couponDetails
            }),
            invalidatesTags:['coupon']
        }),

        updateCoupon : builder.mutation({
            query:(couponDetails) => ({
                url : `/coupons/${couponDetails.couponId}`,
                method:'PUT',
                body:couponDetails
            }),
            invalidatesTags:['coupon']
        }),

        deleteCoupon :  builder.mutation({
            query : (couponId) => ({
                url:`/coupons/${couponId}`,
                method:'DELETE',
            }),
            invalidatesTags:['coupon']
        })

       
    })
});


export const {
    useGetAllCouponsQuery,
    useGetCouponByIdQuery,
    useAddCouponMutation,
    useUpdateCouponMutation,
    useDeleteCouponMutation,
} = CouponService;