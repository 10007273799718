import React, { useState, useEffect } from "react";
import "./CalendarView.css";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  openDeleteCalModal,
  openEditCalModal,
} from "../../Store/Slices/calSlice";
import { useGetAllCalsQuery } from "../../Store/Services/CalService";
import { useDispatch } from "react-redux";
// import { Delete } from "@mui/icons-material";

const CalendarView = ({ onDateChange, initialSelectedDates }) => {
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState(
    initialSelectedDates || []
  );
  const [selectedDateForEdit, setSelectedDateForEdit] = useState(null);

  const { data: allCalsData, error: allCalsError } = useGetAllCalsQuery();

  useEffect(() => {
    if (allCalsError) {
      console.error("Error fetching availability data:", allCalsError);
    }
    if (allCalsData) {
      const unavailableDates = allCalsData
        .filter((cal) => cal.availability === 0)
        .map((cal) => cal.day);
  
      setSelectedDates([]);
    }
  }, [allCalsData, allCalsError]);

  const handleDateClick = (date, availability) => {
    const formattedDate = new Date(
      currentYear,
      currentMonth,
      date
    ).toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });


    const newSelectedDates = selectedDates.includes(formattedDate)
      ? selectedDates.filter((d) => d !== formattedDate)
      : [...selectedDates, formattedDate];

    setSelectedDates(newSelectedDates);

    onDateChange && onDateChange(newSelectedDates, formattedDate);
  };

  const handleEditClick = (formattedDate) => {
    setSelectedDateForEdit(formattedDate);
    dispatch(openEditCalModal(formattedDate));
  };

  const generateCalendar = (year, month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const allCalsDataAvailable = allCalsData || [];

    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const unavailableDates = allCalsDataAvailable
      .filter((cal) => cal.availability === 0)
      .map((cal) => cal.day);

    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const isCurrentDate =
        currentYear === year && currentMonth === month && currentDay === i;
      const formattedDate = new Date(year, month, i).toLocaleDateString(
        "en-CA",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
      );

      const calData =
      allCalsDataAvailable.find((cal) => cal.day === formattedDate) || {};
    const isSelectedDate = selectedDates.includes(formattedDate);
    const isUnavailableDate = unavailableDates.includes(formattedDate);
    const isDateInData = !!calData.day;

    const roles = sessionStorage.getItem("roles");

    days.push(
      <div
        key={i}
        className={`calendar-day 
          ${isSelectedDate ? "selectedDates" : ""} 
          ${isCurrentDate ? "current-date" : ""}
          ${isUnavailableDate ? "unavailable" : ""}
          ${isDateInData && calData.availability === 1 ? "available" : ""}`}
        onClick={() => handleDateClick(i)}
      >
        {i}

        {isDateInData && isSelectedDate && roles?.split(",").includes("PRIV_ES_EDIT_CALENDAR") ? (
          <IconButton
            className={`edit-icon small-edit-icon`}
            onClick={() => handleEditClick(formattedDate)}
          >
            <EditIcon />
          </IconButton>
        ) : null}
      </div>
      );
    }

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.unshift(
        <div key={`placeholder-${i}`} className="calendar-day placeholder" />
      );
    }

    return days;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    return { currentYear, currentMonth };
  };

  const [currentYear, setCurrentYear] = useState(getCurrentDate().currentYear);
  const [currentMonth, setCurrentMonth] = useState(
    getCurrentDate().currentMonth
  );

  const navigateMonth = (offset) => {
    setCurrentMonth((prevMonth) => (prevMonth + offset + 12) % 12);
  };

  const navigateYear = (offset) => {
    setCurrentYear((prevYear) => prevYear + offset);
  };

  const getMonthName = (monthIndex) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthIndex];
  };

  return (
    <div className="calendar-wrapper">
      <div className="custom-calendar">
        <div className="calendar-header">
          {`${getMonthName(currentMonth)} ${currentYear}`}
        </div>
  
        <div className="days-row">
          <div className="day">Sun</div>
          <div className="day">Mon</div>
          <div className="day">Tue</div>
          <div className="day">Wed</div>
          <div className="day">Thu</div>
          <div className="day">Fri</div>
          <div className="day">Sat</div>
        </div>
  
        <div className="calendar-days">
          {generateCalendar(currentYear, currentMonth)}
        </div>
  
        <div className="calendar-navigation-container">
          <IconButton
            className="navigation-icon"
            onClick={() => navigateYear(-1)}
          >
            &lt;&lt;
          </IconButton>
          <IconButton
            className="navigation-icon"
            onClick={() => navigateMonth(-1)}
          >
            &lt;
          </IconButton>
  
          <IconButton
            className="navigation-icon"
            onClick={() => navigateMonth(1)}
          >
            &gt;
          </IconButton>
          <IconButton className="navigation-icon" onClick={() => navigateYear(1)}>
            &gt;&gt;
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default CalendarView;
