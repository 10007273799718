// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.FileUploader_attachButton__u709m {
    padding-left: 0;
}

.FileUploader_attachButtonFile__qRrIc, .FileUploader_close__zyWgf, .FileUploader_closeButton__y90-I {
    color: #9747FF;
}`, "",{"version":3,"sources":["webpack://./src/Components/ContactUs/FileUploader.module.css"],"names":[],"mappings":";;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["\r\n\r\n.attachButton {\r\n    padding-left: 0;\r\n}\r\n\r\n.attachButtonFile, .close, .closeButton {\r\n    color: #9747FF;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attachButton": `FileUploader_attachButton__u709m`,
	"attachButtonFile": `FileUploader_attachButtonFile__qRrIc`,
	"close": `FileUploader_close__zyWgf`,
	"closeButton": `FileUploader_closeButton__y90-I`
};
export default ___CSS_LOADER_EXPORT___;
