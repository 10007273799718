import { Box, Button, Container, Paper, Toolbar, Typography } from "@mui/material"
import { useState } from "react";
import { useDispatch } from "react-redux"
import SearchBar from "../Components/Shared/SearchBar";
import CouponDialog from "../Components/Coupon/CouponDialog";
import CouponList from "../Components/Coupon/CouponList";
import { useGetAllCouponsQuery } from "../Store/Services/CouponService";
import { closeCouponModal, openAddCouponModal } from "../Store/Slices/couponSlice";

import styles from "./Management.module.css"

const CouponManagement = () => {
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState([])
    const { data, isSuccess, isLoading } = useGetAllCouponsQuery();


    const handleClose = () => {
        dispatch(closeCouponModal());
    }

    const roles = sessionStorage.getItem("roles");

    return (
        roles?.split(",").includes("PRIV_ES_DISPLAY_COUPONS") ? (
        <Paper elevation={3} className={styles.pageContainer} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/thumbnail_Management.png)`}}>
            <Box>
                <Toolbar className={styles.subNavBar}>
                    <Typography className={styles.topic}>Coupon Management</Typography>
                    {roles?.split(",").includes("PRIV_ES_ADD_COUPON") ?  (<Button variant="contained" color="primary" className={styles.buttonItem} onClick={() => dispatch(openAddCouponModal())}>Add Coupon</Button>) : null}
                </Toolbar>
            </Box>
            <Container id={styles.container}>
                <SearchBar
                    data={isSuccess || isLoading ? data : []}
                    setSearchData={setSearchData}
                    text="Search Coupons"
                    search="coupon"
                />
            </Container>

            <Container>
               {isSuccess && <CouponList data={Array.isArray(searchData) && searchData.length > 0 ? searchData : data}/>}
            </Container>

            <CouponDialog handleClose={handleClose} />
        </Paper> ) : null
    )
}

export default CouponManagement
