import {
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { useEffect, useState } from "react";

import styles from "./ImageUploader.module.css";
import {
  useDeleteImageMutation,
  useUploadCategoryImageMutation,
  useUploadImagesMutation,
  useUploadResImageMutation,
} from "../../Store/Services/ImageService";

const ImageUploader = (props) => {
  const { images, setImages, from, id } = props;

  useEffect(() => {
    setImages(images);
    // console.log("Updated images state:", images);
  }, [images, setImages]);

  const [uploadImage] = useUploadImagesMutation();
  const [uploadCatImage] = useUploadCategoryImageMutation();
  const [uploadResImage] = useUploadResImageMutation();
  const [deleteImage] = useDeleteImageMutation();

  const onImageUpload = async (e) => {
    if ((from === "res" || from === "editRes") && images.length === 1) {
      return;
    }
    const data = new FormData();

    for (let i = 0; i < e.target.files.length; i++) {
      const imagedata = e.target.files[i];
      data.append("images", imagedata);
    }

    if (
      (from === "editCat" || from === "editRes") &&
      e.target.files.length > 0
    ) {
      data.append("folder", `${id}`);
    }

    // console.log("xxxxxxxxxxxxxxxxxxxx", id);

    // console.log("from", from);
    const headers = {};

    try {
      let result;

      if ((from === "cat" || from === "editCat") && e.target.files.length > 0) {
        result = await uploadCatImage(data, { headers }).unwrap();
        // console.log("cat result", result);

        if (Array.isArray(result) && result.length > 0) {
          // Flatten the result array
          const flattenedResult = result.flat();
          setImages((prevImages) => [...prevImages, ...flattenedResult]);
          // console.log("Updated images state:", [...images, ...flattenedResult]);
        }

        
      } else if (
        (from === "res" || from === "editRes") &&
        e.target.files.length === 1
      ) {
        result = await uploadResImage(data, { headers }).unwrap();
        // console.log("res result", result);

        if (Array.isArray(result) && result.length > 0) {
          setImages((prevImages) => [...prevImages, ...result.flat()]);
        }
      } else {
        console.warn("No files selected or invalid context");
      }
    } catch (err) {
      console.error(`${from} image uploader error`, err);
    }
  };

  // console.log(images);

  const handleDeleteImage = async (e) => {
    try {
      const result = await deleteImage(e).unwrap();
      // console.log("result", result);
      if (result.length === 0) {
        const s = images.filter((item) => item !== e);
        setImages(s);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <ImageList className={styles.imgList} gap={5} cols={4} rowHeight={110}>
        {images?.length !== 0 &&
          images?.map((img, index) => (
            <div key={index} className={styles.itemContainer}>
              <ImageListItem>
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}${img}`}
                  alt=""
                  className={styles.image}
                  loading='lazy'
                />
                {from === "view" ? null : (
                  <ImageListItemBar
                    className={styles.imageBar}
                    position="top"
                    actionIcon={
                      <IconButton
                        className={styles.closeIcon}
                        onClick={() => handleDeleteImage(img)}
                      >
                        <HighlightOff />
                      </IconButton>
                    }
                    actionPosition="right"
                  />
                )}
              </ImageListItem>
            </div>
          ))}
      </ImageList>
      <div>
        <label htmlFor="contained-button-file">
          <div className={styles.buttonContainer}>
            {from === "view" ? null : (
              <form encType="multipart/form-data">
                <Button
                  color="secondary"
                  variant="contained"
                  component="span"
                  disabled={from === "res" && images.length === 1}
                >
                  Upload
                  <input
                    onChange={onImageUpload}
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    hidden
                    multiple={!(from === "cat" || from === "editCat")}
                    disabled={
                      (from === "cat" || from === "editCat") &&
                      images.length > 2
                    }
                  />
                </Button>
              </form>
            )}
          </div>
        </label>
      </div>
    </div>
  );
};

export default ImageUploader;
