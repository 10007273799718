import {createSlice} from "@reduxjs/toolkit";


export const chargeSlice = createSlice({
    name : "charge",
    initialState : {
        modalStatus : null,
        selectedCharge : null
    },

    reducers:{
        openAddChargeModal : (state) => {
            state.modalStatus = "add";
        },
        openEditChargeModal:(state,action) => {
            state.modalStatus = "edit";
            state.selectedCharge = action.payload;
        },
        openDeleteChargeModal : (state,action) => {
            state.modalStatus = "delete";
            state.selectedCharge = action.payload;
        },
        closeChargeModal : (state) => {
            state.modalStatus = false;
            state.selectedCharge = null;
        }
    }
});


export const {
    openAddChargeModal,
    openEditChargeModal,
    openDeleteChargeModal,
    closeChargeModal
} = chargeSlice.actions;

export default chargeSlice.reducer;