import {apiSlice} from '../Slices/apiSlice';


export const StateService = apiSlice.injectEndpoints({
    endpoints : builder => ({
        getAllStates : builder.query({
            query : () => "/states",
            providesTags : ['states']
        }),

        getStateById : builder.query({
            query : (stateId) => `/states/${stateId}`,
            providesTags : ['states']
        }),

        addState : builder.mutation({
            query : (stateDetails) => ({
                url : '/states',
                method:'POST',
                body:stateDetails
            }),
            invalidatesTags:['states']
        }),

        updateState : builder.mutation({
            query:(stateDetails) => ({
                url : `/states/${stateDetails.stateId}`,
                method:'PUT',
                body:stateDetails
            }),
            invalidatesTags:['states']
        }),

        deleteState :  builder.mutation({
            query : (stateId) => ({
                url:`/states/${stateId}`,
                method:'DELETE',
            }),
            invalidatesTags:['states']
        })

       
    })
});


export const {
    useGetAllStatesQuery,
    useGetStateByIdQuery,
    useAddStateMutation,
    useUpdateStateMutation,
    useDeleteStateMutation,
} = StateService;