import {
  Box,
  Button,
  Container,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import styles from "./Management.module.css";
import TabsView from "../Components/Comments/TabsView";

const CommentsManagement = () => {

  return (
    <Paper elevation={3} className={styles.pageContainer} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/thumbnail_Management.png)`}}>
      <Box>
        <Toolbar className={styles.subNavBar}>
          <Typography className={styles.topic}>Comments Management</Typography>
        </Toolbar>
      </Box>
      <Container>
        <TabsView />
      </Container>

    </Paper>
  );
};

export default CommentsManagement;
