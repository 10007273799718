import {createSlice} from "@reduxjs/toolkit";


export const privSlice = createSlice({
    name : "priv",
    initialState : {
        modalStatus : null,
        selectedPriv : null
    },

    reducers:{
        openAddPrivModal : (state) => {
            state.modalStatus = "add";
        },
        openEditPrivModal:(state,action) => {
            state.modalStatus = "edit";
            state.selectedPriv = action.payload;
        },
        openDeletePrivModal : (state,action) => {
            state.modalStatus = "delete";
            state.selectedPriv = action.payload;
        },
        closePrivModal : (state) => {
            state.modalStatus = false;
            state.selectedPriv = null;
        }
    }
});


export const {
    openAddPrivModal,
    openEditPrivModal,
    openDeletePrivModal,
    closePrivModal
} = privSlice.actions;

export default privSlice.reducer;