// CalDialog.js

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeCalModal } from "../../Store/Slices/calSlice";
import AddCal from "./AddCal";
import EditCal from "./EditCal";
import DeleteCal from "./DeleteCal";
import { useEffect } from "react";

const CalDialog = ({ selectedDates, formattedDate }) => {
  const dispatch = useDispatch();
  const cal = useSelector((state) => state.cal);

  const [selectedDateForEdit, setSelectedDateForEdit] = useState(null);

  const handleClose = () => {
    dispatch(closeCalModal(false));
  };

  const roles = sessionStorage.getItem("roles");

  useEffect(() => {
    setSelectedDateForEdit(cal.selectedDateForEdit);
  }, [cal.selectedDateForEdit]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={
        cal.modalStatus === "add" ||
        cal.modalStatus === "edit" ||
        cal.modalStatus === "delete"
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {cal.modalStatus === "add" && roles?.split(",").includes("PRIV_ES_ADD_CALENDAR") ? (
          <AddCal
            handleClose={() => handleClose()}
            selectedDates={selectedDates}
          />
        ) : null}
        {cal.modalStatus === "edit" && roles?.split(",").includes("PRIV_ES_EDIT_CALENDAR") ? (
          <EditCal handleClose={handleClose} selectedDate={formattedDate} />
        ) : null}
        {cal.modalStatus === "delete" && roles?.split(",").includes("PRIV_ES_DELETE_CALENDAR") ? (
          <DeleteCal handleClose={handleClose} selectedDate={formattedDate} />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default CalDialog;
