// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUpForm_formItem__ltyv4{
    margin-bottom: 20px;
    
}

.SignUpForm_formText__C-fqq{
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 2px 2px 50px 2px rgb(180, 122, 255, 0.5);
    margin-top: 10px;
}

.SignUpForm_formLabel__I-ccx{
    margin-top: 50px;
    margin-bottom: 8px;
    padding-top: 10rem !important;
    font-weight: 600 !important;
}


.SignUpForm_form__zblP0{
    display:flex;
    flex-direction: column;
}

.SignUpForm_btn__1IBjt{
    flex: 1 1;
}

.SignUpForm_btn__1IBjt{
    flex: 1 1 auto;
    justify-content: center;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.SignUpForm_button__U5fJg{
    border-radius: 1rem;
    padding: 0.5rem 2.5rem;

}


@media (min-width:768px){
    .SignUpForm_btn__1IBjt{
        width : 100%;
        margin-bottom: 8px;
        flex-direction: row;
        
    }

    .SignUpForm_form__zblP0{
        width : 100%;
    }

    .SignUpForm_form__zblP0 .SignUpForm_MuiBox-root__AltAf{
        flex-direction: column;
    }
}

`, "",{"version":3,"sources":["webpack://./src/Components/SignUp/SignUpForm.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,oDAAoD;IACpD,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,6BAA6B;IAC7B,2BAA2B;AAC/B;;;AAGA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,aAAa;IACb,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;;AAE1B;;;AAGA;IACI;QACI,YAAY;QACZ,kBAAkB;QAClB,mBAAmB;;IAEvB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".formItem{\r\n    margin-bottom: 20px;\r\n    \r\n}\r\n\r\n.formText{\r\n    border-radius: 0.9375rem;\r\n    background: #FFF;\r\n    box-shadow: 2px 2px 50px 2px rgb(180, 122, 255, 0.5);\r\n    margin-top: 10px;\r\n}\r\n\r\n.formLabel{\r\n    margin-top: 50px;\r\n    margin-bottom: 8px;\r\n    padding-top: 10rem !important;\r\n    font-weight: 600 !important;\r\n}\r\n\r\n\r\n.form{\r\n    display:flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.btn{\r\n    flex: 1;\r\n}\r\n\r\n.btn{\r\n    flex: 1 1 auto;\r\n    justify-content: center;\r\n    display: flex;\r\n    gap: 10px;\r\n    flex-direction: column;\r\n}\r\n\r\n.button{\r\n    border-radius: 1rem;\r\n    padding: 0.5rem 2.5rem;\r\n\r\n}\r\n\r\n\r\n@media (min-width:768px){\r\n    .btn{\r\n        width : 100%;\r\n        margin-bottom: 8px;\r\n        flex-direction: row;\r\n        \r\n    }\r\n\r\n    .form{\r\n        width : 100%;\r\n    }\r\n\r\n    .form .MuiBox-root{\r\n        flex-direction: column;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": `SignUpForm_formItem__ltyv4`,
	"formText": `SignUpForm_formText__C-fqq`,
	"formLabel": `SignUpForm_formLabel__I-ccx`,
	"form": `SignUpForm_form__zblP0`,
	"btn": `SignUpForm_btn__1IBjt`,
	"button": `SignUpForm_button__U5fJg`,
	"MuiBox-root": `SignUpForm_MuiBox-root__AltAf`
};
export default ___CSS_LOADER_EXPORT___;
