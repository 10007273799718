import React from "react";
import Styles from "./containerTwo.module.css";
import { Typography } from "@mui/material";

const ContainerTwo = () => {
  return (
    <div>
      <div className={Styles.redBoxTop}>
        <Typography className={Styles.title}>We Embrace Innovation.</Typography>
        <Typography className={Styles.description}>
          At DoneRight, we stay ahead of the curve by embracing the latest in
          cleaning technologies and methods. Innovation drives us to constantly
          refine our processes and improve our services. By integrating
          cutting-edge solutions, we deliver efficient, high-quality results
          that exceed our clients' expectations.
        </Typography>
      </div>
      <div className={Styles.imageContainer}>
        <img src={`${process.env.REACT_APP_IMAGE_URL}DoneRight/PAGES/redMan.png`} alt="Image" className={Styles.image} loading='lazy' />
      </div>
      <div className={Styles.redBoxBottom}>
        <Typography className={Styles.title}>We Foster Community.</Typography>
        <Typography className={Styles.description}>
          Community is at the heart of what we do at DoneRight. We believe in
          building strong relationships not just with our clients but also
          within our team. By fostering a sense of community, we create a
          supportive environment where everyone can thrive and contribute to our
          shared success. Together, we achieve more.
        </Typography>
      </div>
    </div>
  );
};

export default ContainerTwo;
