import React , {useState} from 'react';
import { useDispatch } from 'react-redux';
import { openDeleteCouponModal, openEditCouponModal } from '../../Store/Slices/couponSlice';
import { PaginationDetails } from '../../Utils/Utils';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Pagination , Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './CouponList.module.css';
import usePagination from '../../Hooks/usePagination';




const CouponList = ({ data }) => {
    const dispatch = useDispatch();
    const { page, count, handleChange, _DATA } = PaginationDetails(data);


    const handleEdit = (couponId) => {
        dispatch(openEditCouponModal(couponId));
    };

    const handleDelete = (couponId) => {
        dispatch(openDeleteCouponModal(couponId));
    };

    const currentData = _DATA?.currentData() || data;

    const roles = sessionStorage.getItem("roles");

    return (
        <>
            <TableContainer sx={{ height: "43rem" }} >
                <Table  sx={{borderCollapse:'separate' , borderSpacing:'0px 4px' , height:'max-content'}} stickyHeader>
                    <TableHead  >
                        <TableRow >
                            <TableCell  width={50} >Coupon Id</TableCell>
                            <TableCell  width={50}>Coupon Code</TableCell>
                            <TableCell  width={50}>Amount</TableCell>
                            <TableCell align="center" width={"30%"}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {currentData.map((coupon) => (
                            <TableRow  sx={{ height: "30px" }} key={coupon.couponId}>
                                <TableCell  component='th' scope='row' sx={{ borderBottom: "none", fontWeight: 'bold', width: 100, height: 'auto !important' }}>#{coupon.couponId}</TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 100, height: 'auto !important' }}>{coupon.couponCode}</TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 100, height: 'auto !important' }}>{coupon.off}</TableCell>
                                <TableCell align="center" sx={{ borderBottom: "none", width: '15%', height: 'auto !important' }}>
                                    {roles?.split(",").includes("PRIV_ES_EDIT_COUPON") ?  (<IconButton onClick={() => handleEdit(coupon.couponId)}>
                                        <EditIcon />
                                    </IconButton>) : null}
                                    {roles?.split(",").includes("PRIV_ES_DELETE_COUPON") ?   (<IconButton onClick={() => handleDelete(coupon.couponId)}>
                                        <DeleteIcon />
                                    </IconButton>) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            
            </TableContainer>
            <Box my={2} display="flex" justifyContent="center">
                <Pagination
                    count={count}
                    page={page}
                    onChange={handleChange}
                    variant='outlined'
                    color="primary"
                    size='large'
                />
            </Box>
                
        
          
        </>
    );
};

export default CouponList;
