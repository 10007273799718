import { apiSlice } from "../Slices/apiSlice";

export const BrService = apiSlice.injectEndpoints({
    endpoints : builder => ({
        getAllBrs : builder.query({
            query : () => "/sec-br",
            providesTags:['br']
        }),
        getBrById : builder.query({
            query : (brId) => `/sec-br/${brId}`,
            providesTags : ['br']
        }),

        addBr : builder.mutation({
            query : (brDetails) => ({
                url : '/sec-br',
                method:'POST',
                body:brDetails
            }),
            invalidatesTags:['br']
        }),
        
        updateBr : builder.mutation({
            query : (brDetails) => ({
                url : `/sec-br/${brDetails.brId}`,
                method:'PUT',
                body:brDetails
            }),
            invalidatesTags:['br']
        }),

        deleteBr : builder.mutation({
            query : (brId) => ({
                url : `/sec-br/${brId}`,
                method:'DELETE',
            }),
            invalidatesTags:['br']
        })
    })
});


export const {
    useGetAllBrsQuery,
    useGetBrByIdQuery,
    useAddBrMutation,
    useUpdateBrMutation,
    useDeleteBrMutation,
} = BrService