import React , {useState} from 'react';
import { useDispatch } from 'react-redux';
import { openDeleteFreqModal, openEditFreqModal } from '../../Store/Slices/frequencySlice';
import { PaginationDetails } from '../../Utils/Utils';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Pagination , Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import usePagination from '../../Hooks/usePagination';




const FreqTable = ({ data }) => {
    const dispatch = useDispatch();
    const { page, count, handleChange, _DATA } = PaginationDetails(data);


    const handleEdit = (freqId) => {
        dispatch(openEditFreqModal(freqId));
    };

    const handleDelete = (freqId) => {
        dispatch(openDeleteFreqModal(freqId));
    };

    const currentData = _DATA?.currentData() || data;

    const roles = sessionStorage.getItem("roles");

    return (
        <>
            <TableContainer sx={{ height: "43rem" }}>
                <Table  sx={{borderCollapse:'separate' , borderSpacing:'0px 4px' , height:'max-content'}} stickyHeader>
                    <TableHead  >
                        <TableRow >
                            <TableCell width={50}>Frequency Id</TableCell>
                            <TableCell width={50}> Frequency Name </TableCell>
                            <TableCell width={50}> Frequency Description </TableCell>
                            <TableCell width={50}> Frequency Offer </TableCell>
                            <TableCell align="center" width={"30%"}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {currentData.map((freq) => (
                            <TableRow sx={{ height: "30px" }} key={freq.freqId}>
                                <TableCell  component='th' scope='row' sx={{ borderBottom: "none", fontWeight: 'bold', width: 100, height: 'auto !important' }}>#{freq.freqId}</TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 150, height: 'auto !important' }}>{freq.freqName}</TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 200, height: 'auto !important' }}>{freq.freqDesc}</TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 150, height: 'auto !important' }}>{freq.freqOff}</TableCell>
                                <TableCell align="center" sx={{ borderBottom: "none", width: '15%', height: 'auto !important' }}>
                                    {roles?.split(",").includes("PRIV_ES_EDIT_FREQUENCY") ?  (<IconButton onClick={() => handleEdit(freq.freqId)}>
                                        <EditIcon />
                                    </IconButton>) : null}
                                    {roles?.split(",").includes("PRIV_ES_DELETE_FREQUENCY") ?  (<IconButton onClick={() => handleDelete(freq.freqId)}>
                                        <DeleteIcon />
                                    </IconButton>) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            
            </TableContainer>
            <Box my={2} display="flex" justifyContent="center">
                <Pagination
                    count={count}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                    size='large'
                    variant = 'outlined'
                />
            </Box>
                
        
          
        </>
    );
};

export default FreqTable;
