import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useUpdateMappingMutation,
  useGetMappingByIdQuery,
} from "../../Store/Services/brPrivService";
import { useGetAllPrivsQuery } from "../../Store/Services/PrivilegeService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./BrPriv.module.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const schema = yup
  .object({
    brId: yup.string().notOneOf(["init"]).required(""),
  })
  .required();

const EditBrPriv = ({ handleClose }) => {
  const selectedBrPrivId = useSelector((state) => state.brPriv.selectedBrPriv);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    data: privilegeData,
    isSuccess: privSuccess,
    isLoading: privLoading,
  } = useGetAllPrivsQuery();

  const [updateBrPriv] = useUpdateMappingMutation();

  const {
    data: mappingDetails,
    isSuccess,
    isLoading,
  } = useGetMappingByIdQuery(selectedBrPrivId ? selectedBrPrivId : skipToken); 


  const [brPriv, setBrPriv] = useState([]);
  const [deletedPriv, setDeletedPriv] = useState([]);
  const [newBrPriv, setNewBrPriv] = useState([]);
  const [selectedBrPriv, setSelectedBrPriv] = useState([]);

  useEffect(() => {
    if (isSuccess && mappingDetails && mappingDetails.secPrivDTOS?.length > 0) {
      const selectedBrPrivs = mappingDetails.secPrivDTOS.map((priv) => ({
        privId: priv.privId,
        privName: priv.privName,
        brPriveId: priv.brPriveId,
      }));
      reset({
        brId: mappingDetails.brId,
        brPriv: selectedBrPrivs,
      });
      setSelectedBrPriv(selectedBrPrivs);
    }
  }, [mappingDetails, isSuccess, reset]);

  const onSubmit = async (data) => {
    const newPrivsIds = newBrPriv.map((priv) => priv.privId);
    // Map deleted privileges to include brPriveId
    const deletedPrivsIds = deletedPriv.map((priv) => priv.brPriveId); // Change this line


    const dataSet = {
      ...data,
      brId: selectedBrPrivId,
      newPrivIds: newPrivsIds,
      deletedIds: deletedPrivsIds,
    };

    try {
      const respond = await updateBrPriv(dataSet).unwrap();
      toast.success("Mapping successfully updated!", {
        position: "bottom-right",
      });
      handleClose();
    } catch (err) {
      console.error("Failed to save the BrPriv: ", err);
      toast.error("Erorr, something went wrong!", { position: "bottom-right" });
    }
  };

  return (
    <div>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Edit Mapping
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItem}>
          <label className={styles.formLabel}>Privileges:</label>

          <Controller
            name="brPriv"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                className={styles.formInput}
                {...field}
                options={privilegeData || []}
                multiple
                filterSelectedOptions
                getOptionLabel={(option) => option.privName}
                isOptionEqualToValue={(option, value) =>
                  option.privId === value.privId
                }
                value={selectedBrPriv || []}
                onChange={(_, newPriv, reason, detail) => {
                  if (reason === "removeOption") {
                    // Remove the deleted role from selectedBrPriv
                    setSelectedBrPriv((prev) =>
                      prev.filter(
                        (priv) => priv.privId !== detail.option.privId
                      )
                    );
                    // Add the deleted role to deletedBr
                    setDeletedPriv((prev) => [...prev, detail.option]);
                  } else if (reason === "selectOption") {
                    setNewBrPriv((prev) => [...prev, detail.option]);
                    setSelectedBrPriv((prev) => [...prev, detail.option]);
                  }
                  setBrPriv(
                    newPriv.map((priv) => ({
                      privId: priv.privId,
                      privName: priv.privName,
                      brDeleteId: priv.brDeleteId,
                    }))
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            )}
          />
        </div>

        {/* Submit Button */}
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditBrPriv;
