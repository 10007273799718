import React from "react";
import { Delete } from "@mui/icons-material";
import {
  Button,
  IconButton,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useFormContext, useFieldArray } from "react-hook-form";
import AttributeValueTable from "./valuesTable";
import { useGetAllAttTypesQuery } from "../../Store/Services/CategoryService";

const AttributesTable = (props) => {
  const { from, deletedAttributes, setDeletedAttributes, deletedValues, setDeletedValues, defaultAttributes } = props;

  const { register, control, setValue, formState: { errors } } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attributes",
  });

  const { data: attributeTypes, isLoading, isError } = useGetAllAttTypesQuery();

  const deleteAttribute = (item, index) => {
    if (from === "edit") {
      setDeletedAttributes([...deletedAttributes, item.catAttributeId]);
    }
    remove(index);
  };

  return (
    <>
      <Table style={{ borderBottom: "none" }}>
        {fields.length !== 0 ? (
          <TableHead>
            <TableRow >
              <TableCell align="center" width={100}>
                Index
              </TableCell>
              <TableCell align="center" width={"30%"}>
                Name
              </TableCell>
              <TableCell align="center" width={100}>
                Type
              </TableCell>
              <TableCell align="center" width={50}>
                Mandatory
              </TableCell>
              <TableCell align="center" width={"20%"}>
                <Button
                  type="button"
                  variant="contained"
                  aria-label="Add Attribute"
                  onClick={() =>
                    append({
                      attributeIndex: "",
                      attributeName: "",
                      pickerType: "",
                      mandatory: false,
                      attributeValues: [],
                    })
                  }
                >
                  Add Attribute
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
        ) : (
          <div
            style={{
              borderBottom: "none",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              type="button"
              variant="contained"
              aria-label="Add Attribute"
              onClick={() =>
                append({
                  attributeIndex: "",
                  attributeName: "",
                  pickerType: "",
                  mandatory: false,
                  attributeValues: [],
                })
              }
            >
              Add Attribute
            </Button>
          </div>
        )}
        <TableBody>
          {fields.map((attribute, index) => (
            <React.Fragment key={attribute.id}>
              <TableRow>
                <TableCell style={{ borderBottom: "none" }} align="center">
                  <OutlinedInput
                    {...register(`attributes.${index}.attributeIndex`)}
                    placeholder="Index"
                    defaultValue={attribute.attributeIndex}
                    size="small"
                  />
                </TableCell>
                <TableCell style={{ borderBottom: "none", width: "30%" }} align="center">
                  {errors.attributes?.[index]?.attributeName && (
                    <span style={{ color: "red" }}> * </span>
                  )}
                  <OutlinedInput
                    {...register(`attributes.${index}.attributeName`)}
                    placeholder="Attribute Name"
                    defaultValue={attribute.attributeName}
                    size="small"
                  />
                </TableCell>
                <TableCell
                  style={{ borderBottom: "none", maxWidth: "5rem" }}
                  align="center"
                >
                  {errors.attributes?.[index]?.pickerType && (
                    <span style={{ color: "red" }}> * </span>
                  )}
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : isError ? (
                    <span style={{ color: "red" }}>Error loading types</span>
                  ) : (
                    <Select
                      {...register(`attributes.${index}.pickerType`)}
                      style={{ marginTop: "0.3rem" }}
                      onChange={(e) =>
                        setValue(
                          `attributes.${index}.pickerType`,
                          e.target.value
                        )
                      }
                      defaultValue={attribute.pickerType || "-1"}
                      size="small"
                      fullWidth
                    >
                      <MenuItem value="-1" style={{ display: "none" }}>
                        Select Type
                      </MenuItem>
                      {attributeTypes.map((type) => (
                        <MenuItem
                          key={type.attributeTypeId}
                          value={type.attributeTypeId}
                        >
                          {type.attributeTypeName}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </TableCell>
                <TableCell style={{ borderBottom: "none", width: 50 }} align="center">
                  <Checkbox
                    {...register(`attributes.${index}.mandatory`)}
                    defaultChecked={attribute.mandatory}
                  />
                </TableCell>
                <TableCell style={{ borderBottom: "none" }} align="center">
                  <IconButton
                    type="button"
                    aria-label="Delete Attribute"
                    onClick={() => deleteAttribute(attribute, index)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align="right" style={{ borderBottom: "none" }}>
                  <AttributeValueTable 
                    attributeIndex={index} 
                    from={from}
                    deletedVal={deletedValues}
                    setDeletedVal={setDeletedValues}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
        {(fields.length === 0) ? (<hr />) : null}
      </Table>
    </>
  );
};

export default AttributesTable;
