import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeStateModal } from "../../Store/Slices/stateSlice"
import AddState from "./AddState";
import EditState from "./EditState";
import DeleteState from "./DeleteState";

const CouponDialog = () => {
    const dispatch = useDispatch();
    const { modalStatus } = useSelector((state) => state.state);

    let content;
    let deleteView = false;

    const roles = sessionStorage.getItem("roles");

    const handleClose = () => {
        dispatch(closeStateModal());
    };

    switch (modalStatus) {
        case "add":
            content = roles?.split(",").includes("PRIV_ES_ADD_STATE") ? <AddState handleClose={handleClose} /> : null;
            break;
        case "edit":
            content = roles?.split(",").includes("PRIV_ES_EDIT_STATE") ? <EditState handleClose={handleClose} /> : null;
            break;
        case "delete":
            deleteView = true;
            content = roles?.split(",").includes("PRIV_ES_DELETE_STATE") ? <DeleteState handleClose={handleClose} /> : null;
            break;
        default:
            content = null;
    }

    return (
        <Dialog
            fullWidth={true}
            // maxWidth={deleteView ? "xs" : "md"}
            open={["add", "edit", "delete"].includes(modalStatus)}
            onClose={handleClose}
            aria-labelledby="coupon-dialog-title"
            aria-describedby="coupon-dialog-description"
            // sx={{
            //     marginLeft: deleteView ? '25rem' : '10rem', 
            //     marginRight: deleteView ? '25rem' : '10rem', 
            //     maxWidth: deleteView ? 'fit-content' : 'none'
            //   }}
            
        >
            <DialogContent>{content}</DialogContent>
        </Dialog>
    );
};

export default CouponDialog;
