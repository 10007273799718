import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import {
  useUpdateBrMutation,
  useGetBrByIdQuery,
} from "../../Store/Services/BrService";
import {  useSelector } from "react-redux";
import styles from "./Br.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { skipToken } from "@reduxjs/toolkit/dist/query";


const schema = yup.object().shape({
    brName : yup.string().required(""),
});


const EditBr = ({handleClose}) => {
    const br = useSelector((state) => state.br);


    let brData = useMemo(() => {
        return {id : ''};
    },[]);

    // const {data :brDetails , isSuccess:brSuccess,isLoading:brLoading} = useGetBrByIdQuery(br.selectedBr);
    const {data :brDetails , isSuccess:brSuccess,isLoading:brLoading} = useGetBrByIdQuery(br.selectedBr ? br.selectedBr : skipToken);
    if(brSuccess){
        brData = brDetails?.brList?.[0];
    }

    const {register , handleSubmit , reset,formState:{errors}} = useForm({resolver:yupResolver(schema)});

    useEffect(() => {
        if(brSuccess && Array.isArray(brDetails) && brDetails.length > 0){
            const firstBr =  brDetails[0];
            reset({
                brName : firstBr?.brName,
            });
        }
    },[brDetails , brSuccess,reset]);

    const [updateBr] =  useUpdateBrMutation();

    const submit = async (data) => {
        const formData = {
            ...data,
            brId : br.selectedBr
        };

        try{
            await updateBr(formData).unwrap();
            toast.success("Role successfully updated",{position:'bottom-right'});
        }catch(err){
            console.error("Failed to save role",err);
            toast.error("Error , something went wrong ",{position:'bottom-right'});
        }
        handleClose();
    }

    return (
        <div className={styles.addCouponContainer}>
          <div className={styles.headingContainer}>
            <div className={styles.dialogHeader}>
              <Typography
                fontWeight={600}
                className={styles.dialogTitle}
                variant="h6"
              >
                Edit Role
              </Typography>
            </div>
            <div className={styles.close}>
              <IconButton className={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
    
          <form onSubmit={handleSubmit(submit)} autoComplete="off">
            <div className={styles.formItem}>
              <label className={styles.formLabel}> Role :</label>
              {errors.brName && errors.brName.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput
                {...register("brName")}
                className={styles.formInput}
                placeholder="Enter Role"
                size="small"
                fullWidth
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                className={styles.submitButton}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      );
};


export default EditBr;

