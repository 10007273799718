import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDeleteStateMutation } from "../../Store/Services/StateService";
import { closeStateModal } from "../../Store/Slices/stateSlice";
import DeletePopUp from "../Shared/DeletePopUp";

const DeleteState = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);

  const [deleteState] = useDeleteStateMutation();

  const onDeleteConfirm = async () => {
    const id = state.selectedState;
    try {
      const response = await deleteState(id);
      const deletedState = response.data;
      toast.success("State successfully deleted!", {
        position: "bottom-right",
      });
    } catch (err) {
      console.error("Failed to delete state: ", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }
    dispatch(closeStateModal());
  };

  return (
    <DeletePopUp
      data="State"
      close={() => dispatch(closeStateModal())}
      onDeleteConfirm={onDeleteConfirm}
    />
  );
};
export default DeleteState;
