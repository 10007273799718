import { createSlice } from "@reduxjs/toolkit";

export const stateSlice = createSlice({
    name : "state",
    initialState:{
        modalStatus : null,
        selectedState:null
    },

    reducers : {
        openAddStateModal: (state) => {
            state.modalStatus = "add";
          },
          openEditStateModal: (state, action) => {
            state.modalStatus = "edit";
            state.selectedState = action.payload;
          },
          openDeleteStateModal: (state, action) => {
            state.modalStatus = "delete";
            state.selectedState = action.payload;
          },
          closeStateModal: (state) => {
            state.modalStatus = false;
            state.selectedState = null;
          }
    }
});

export const {
    openAddStateModal,
    openEditStateModal,
    openDeleteStateModal,
    closeStateModal
} = stateSlice.actions;

export default stateSlice.reducer;
