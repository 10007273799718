import { createSlice } from "@reduxjs/toolkit";


export const noHrsSlice = createSlice({
    name : 'hrs',
    initialState:{
        modalStatus:null,
        selectedHrs : null
    },
    reducers:{
        openAddHrsModal : (state) => {
            state.modalStatus = "add";
        },
        openEditHrsModal : (state,action) => {
            state.modalStatus = "edit";
            state.selectedHrs = action.payload;
        },
        openDeleteHrsModal : (state , action) => {
            state.modalStatus = "delete";
            state.selectedHrs = action.payload;
        },
        closeHrsModal : (state) => {
            state.modalStatus = false;
            state.selectedHrs = null;
        }
    }
});

export const {
   openAddHrsModal,
   openEditHrsModal,
   openDeleteHrsModal,
   closeHrsModal,
} = noHrsSlice.actions;

export default noHrsSlice.reducer;