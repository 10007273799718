import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closePriceModal } from "../../Store/Slices/priceSlice";
import AddPrice from "./AddPrice";
import EditPrice from "./EditPrice";
import DeletePrice from "./DeletePrice";



const PriceDialog = () => {
    const dispatch = useDispatch();
    const {modalStatus} = useSelector((state) => state.price);
  
    let content;
    let deleteView = false;

    const roles = sessionStorage.getItem("roles");
  
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            dispatch(closePriceModal());
        }
    };
  
    switch (modalStatus) {
        case "add":
            content = roles?.split(",").includes("PRIV_ES_ADD_PRICE_TBL") ? <AddPrice handleClose={handleClose} /> : null;
            break;
        case "edit":
            content = roles?.split(",").includes("PRIV_ES_EDIT_PRICE_TBL") ? <EditPrice handleClose={handleClose} /> : null;
            break;
        case "delete":
            deleteView = true;
            content = roles?.split(",").includes("PRIV_ES_DELETE_PRICE_TBL") ? <DeletePrice handleClose={handleClose} /> : null;
            break;
        default:
            content = null;
    }
  
    return (
        <Dialog
            fullWidth={true}
            open={["add", "edit", "delete"].includes(modalStatus)}
            onClose={handleClose}
            aria-labelledby="coupon-dialog-title"
            aria-describedby="coupon-dialog-description"
            
        >
            <DialogContent>{content}</DialogContent>
        </Dialog>
    );
  };
  
  export default PriceDialog;


