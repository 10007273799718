import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDeleteCouponMutation } from "../../Store/Services/CouponService";
import { closeCouponModal } from "../../Store/Slices/couponSlice";
import DeletePopUp from "../Shared/DeletePopUp";

const DeleteCoupon = ({handleClose}) => {
    const dispatch = useDispatch();
    const coupon = useSelector((state) => state.coupon);
  
    const [deleteCoupon] = useDeleteCouponMutation();
  
    const onDeleteConfirm = async () => {
      const id = coupon.selectedCoupon;
      try {
        const response = await deleteCoupon(id);
        const deletedCoupon = response.data; 
        toast.success("Coupon successfully deleted!", {position:'bottom-right'});
      } catch (err) {
        console.error("Failed to delete coupon: ", err);
        toast.error("Error, something went wrong!", {position:'bottom-right'});
      }
      dispatch(closeCouponModal());
    };
  
    return (
      <DeletePopUp
        data="Coupon"
        close={() => dispatch(closeCouponModal())}
        onDeleteConfirm={onDeleteConfirm}
      />
    );
  };
  

export default DeleteCoupon;
