// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ourStory_outerContainer__zoOq6 {
    margin-left: 4%;
    margin-right: 4%;
}

.ourStory_topic__3nXxU {
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.ourStory_description__08jXW {
    width: 100%;
    font-size: 22px;
    text-align: justify;
    margin-top: 4%;
    margin-left: 8%;
}

/* .imageGrid img {
    max-width: 100%; 
    height: auto; 
    margin-left: 4%;
    margin-top: 4%;
} */

@media (max-width: 770px) {
    .ourStory_description__08jXW {
        /* display: none; */
        margin: 0 0.5rem;
        font-size: 1rem;
    }

    .ourStory_topic__3nXxU {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .ourStory_subTopic__kuy9y {
        font-size: 1.5rem;
    }

    .ourStory_image__I3re-{
        height: 14rem;
        /* width: 16rem; */
    }

    .ourStory_description__08jXW{
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/aboutUs/ourStory.module.css"],"names":[],"mappings":";;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,eAAe;AACnB;;AAEA;;;;;GAKG;;AAEH;IACI;QACI,mBAAmB;QACnB,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,mBAAmB;IACvB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,aAAa;QACb,kBAAkB;IACtB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":["\r\n\r\n.outerContainer {\r\n    margin-left: 4%;\r\n    margin-right: 4%;\r\n}\r\n\r\n.topic {\r\n    font-size: 3rem;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    text-align: center;\r\n    margin: 0;\r\n}\r\n\r\n.description {\r\n    width: 100%;\r\n    font-size: 22px;\r\n    text-align: justify;\r\n    margin-top: 4%;\r\n    margin-left: 8%;\r\n}\r\n\r\n/* .imageGrid img {\r\n    max-width: 100%; \r\n    height: auto; \r\n    margin-left: 4%;\r\n    margin-top: 4%;\r\n} */\r\n\r\n@media (max-width: 770px) {\r\n    .description {\r\n        /* display: none; */\r\n        margin: 0 0.5rem;\r\n        font-size: 1rem;\r\n    }\r\n\r\n    .topic {\r\n        font-size: 2rem;\r\n        margin-bottom: 1rem;\r\n    }\r\n\r\n    .subTopic {\r\n        font-size: 1.5rem;\r\n    }\r\n\r\n    .image{\r\n        height: 14rem;\r\n        /* width: 16rem; */\r\n    }\r\n\r\n    .description{\r\n        width: 100%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `ourStory_outerContainer__zoOq6`,
	"topic": `ourStory_topic__3nXxU`,
	"description": `ourStory_description__08jXW`,
	"subTopic": `ourStory_subTopic__kuy9y`,
	"image": `ourStory_image__I3re-`
};
export default ___CSS_LOADER_EXPORT___;
