// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Frequency_headingContainer__WMeTg {
    display: flex;
    justify-content: space-between;
  }
  
  .Frequency_closeButton__IsB3c {
    padding: 0.2rem;
    border: 1px #47C2BB solid;
    color: #47C2BB;
  }
  
  .Frequency_formItem__a78HZ {
    margin-top: 1rem;
  }
  
  .Frequency_formLabel__bZimO {
    font-weight: 600;
  }
  
  .Frequency_formInput__8Lze4 {
    margin-top: 0.7rem;
    
  }
  
  .Frequency_validation__wkmlm {
    color: red;
  }
  
  .Frequency_buttonContainer__NJGKi {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .Frequency_submitButton__myGMF {
    width: 10rem;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Frequency/Frequency.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,eAAe;IACf,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;;EAEpB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,YAAY;EACd","sourcesContent":[".headingContainer {\r\n    display: flex;\r\n    justify-content: space-between;\r\n  }\r\n  \r\n  .closeButton {\r\n    padding: 0.2rem;\r\n    border: 1px #47C2BB solid;\r\n    color: #47C2BB;\r\n  }\r\n  \r\n  .formItem {\r\n    margin-top: 1rem;\r\n  }\r\n  \r\n  .formLabel {\r\n    font-weight: 600;\r\n  }\r\n  \r\n  .formInput {\r\n    margin-top: 0.7rem;\r\n    \r\n  }\r\n  \r\n  .validation {\r\n    color: red;\r\n  }\r\n  \r\n  .buttonContainer {\r\n    margin-top: 2rem;\r\n    margin-bottom: 1rem;\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .submitButton {\r\n    width: 10rem;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingContainer": `Frequency_headingContainer__WMeTg`,
	"closeButton": `Frequency_closeButton__IsB3c`,
	"formItem": `Frequency_formItem__a78HZ`,
	"formLabel": `Frequency_formLabel__bZimO`,
	"formInput": `Frequency_formInput__8Lze4`,
	"validation": `Frequency_validation__wkmlm`,
	"buttonContainer": `Frequency_buttonContainer__NJGKi`,
	"submitButton": `Frequency_submitButton__myGMF`
};
export default ___CSS_LOADER_EXPORT___;
