import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Button, IconButton, Typography , OutlinedInput } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useCreateSlotMutation } from "../../Store/Services/SlotService";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { openAddSlotModal } from "../../Store/Slices/slotSlice";
import styles from "./Slot.module.css";
import TimerangePicker from "@wojtekmaj/react-timerange-picker";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";

const schema = yup
  .object({
    slot: yup.string().required(""),
  })
  .required();

const AddSlot = ({ handleClose }) => {
  const [selectedTime, setSelectedTime] = useState(["00:00", "00:00"]);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [CreateSlot] = useCreateSlotMutation();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    // const startTime = selectedTime[0];
    // const endTime = selectedTime[1];

    // const formatTime = (time) => {
    // //   const [hours, minutes] = time.split(":");
    // //   const period = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    // //   const formattedHours = (((parseInt(hours, 10) + 11) % 12) + 1)
    // //     .toString()
    // //     .padStart(2, "0");

    // //   return `${formattedHours}.${minutes} ${period}`;
    // // };

    // // const slotTime = `${formatTime(startTime)} - ${formatTime(endTime)}`;

    // // const dataSet = {
    // //   slot: slotTime,
    // // };

    try {
      const respond = await CreateSlot(data).unwrap();
      dispatch(openAddSlotModal(false));
      toast.success("Slot successfully created!", { position: "bottom-right" });
    } catch (err) {
      console.error("Failed to save slot: ", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }

    handleClose();
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Slot Creation
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.formItem}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}> Slot :</label>
              {errors.slot && errors.slot.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput
                {...register("slot")}
                className={styles.formInput}
                placeholder="Enter Slot"
                size="small"
                fullWidth
              />
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddSlot;
