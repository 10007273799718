import React , {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";


const Carousel = () => {
    const [images] = useState([
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-02.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-2.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-03.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23--4.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-5.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-06.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-07.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-09.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-10.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-k1.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-k2.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-k3.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-k4.jpg`,
        `${process.env.REACT_APP_IMAGE_URL}E_SERVICE/banner/23-k5.jpg`
      ]);


    const settings = {
        infinite: true,
        speed: 1000, 
        autoplaySpeed:3500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    }

    return (
        <div  style={{
            textAlign: "center",
            marginLeft:'0.5rem'
          }}>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`banner${index + 3}`}
                  style={{ width: "99rem", height: isMobile ? "15rem" : "30rem", objectFit: "cover" }}
                  loading='lazy'
                />
              </div>
            ))}
          </Slider>
        </div>
      );
};


export default Carousel