import React, { useState } from 'react';
import {
  Button,
  Container,
  TextField,
  Box,
  IconButton,
  InputAdornment,
  FormHelperText,
  Grid
  
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {object , string ,ref} from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '../../Store/Services/AuthService';
import styles from './SignUpForm.module.css'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import AddAddress from '../Address/AddAddress';



const schema = object({
    firstName : string().trim().required('First Name is required'),
    // lastName : string().trim().required('Last Name is required'),
    // userName : string().trim().required('User Name is required'),
    email : string().trim().email("Please provide a valid email").required('Email is required'),
    // telephone : string(),
    mobile : string().required('Mobile is required'),
    password : string().required('Password is required').min(8,"Password should be atleast 8 characters long!"),
    confirmPassword : string().oneOf([ref('password') , null], 'Passwords dont match'),
}).required();


const SignUpForm = () => {
    const navigate = useNavigate();
    const [open , setOpen] = useState(false);
    const location = useLocation();

    const {register , handleSubmit , formState:{errors} } = useForm({resolver : yupResolver(schema)});
    const [showPassword , setShowPassword] = useState(false);
    const [createUser , {isLoading:isSignUpLoading}] = useRegisterMutation();

    const handleClickShowPassword = () =>{
        setShowPassword(!showPassword);
    }

    const onSubmit = async (data) => {
        const dataSet = {
            ...data,
            userName: data.email,
            lastName: "",
            telephone: ""
        }
        try{
            const newUser = await createUser(dataSet).unwrap();
            toast.success("You can login now with your provided credentials")
            if(location.search.includes("redirect")){
                navigate(`/login/${location.search.split("/").pop()}`)
            } else {
                navigate("/login");
            }
        }catch(err){
            console.error('Error' , err);
        }
    }


    const openDialog = () => {
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
    }


    return(
        
        <Container>
            <form  onSubmit={handleSubmit(onSubmit)} className={styles.form} autoComplete='off'>
                <div className={styles.formItem}>
                    <TextField label="Name" className={styles.formText} multiline rows={1} {...register('firstName')} size='small' error={!!errors.firstName}  fullWidth />
                    {errors.firstName && <FormHelperText error> {errors.firstName?.message} </FormHelperText>  }
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <div className={styles.formItem}>
                            <TextField label="Email" className={styles.formText} multiline rows={1} {...register('email')} size='small' error={!!errors.email} fullWidth />
                            {errors.email && <FormHelperText error> {errors.email?.message} </FormHelperText> }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={styles.formItem}>
                            <TextField label="Mobile" className={styles.formText} multiline rows={1} {...register('mobile')} error={!!errors.mobile} size='small' fullWidth />
                            {errors.mobile && <FormHelperText error> {errors.mobile?.message} </FormHelperText> }
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <div className={styles.formItem}>
                            <TextField label="Password" className={styles.formText}   {...register('password')} size='small'  type={showPassword ? "text" : "password"} error={!!errors.password} fullWidth  InputProps={{
                                endAdornment:(
                                    <InputAdornment position='end'>
                                        <IconButton onClick={handleClickShowPassword} edge='end'>
                                            {showPassword ?  <VisibilityOff /> : <Visibility /> }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                            {errors.password &&  <FormHelperText error> {errors.password?.message} </FormHelperText> }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={styles.formItem}>
                            <TextField label="Confirm Password" className={styles.formText}   {...register('confirmPassword')} size='small'  type={showPassword ? "text" : "password"} error={!!errors.confirmPassword} fullWidth  InputProps={{
                                endAdornment:(
                                    <InputAdornment position='end'>
                                        <IconButton onClick={handleClickShowPassword} edge='end'>
                                            {showPassword ?  <VisibilityOff /> : <Visibility /> }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                            {errors.confirmPassword &&  <FormHelperText error> {errors.confirmPassword?.message} </FormHelperText> }
                        </div>
                    </Grid>
                </Grid>
                <Box className={styles.btn}>
                    <Button className={styles.button} type='submit'  variant='contained' fullWidth  disabled={isSignUpLoading} > Register </Button>
                    <Button className={styles.button} variant='contained'  onClick={openDialog} fullWidth>  Add Address </Button>
                </Box>
            </form>
            {!!open && <AddAddress open={openDialog} handleClose={closeDialog} />}
        </Container>
    )

};


export default SignUpForm;