import React from "react";
import Styles from "./containerOne.module.css";
import { Typography } from "@mui/material";

const ContainerOne = () => {
  return (
    <div>
      <div className={Styles.imageContainer}>
        <img src={`${process.env.REACT_APP_IMAGE_URL}DoneRight/PAGES/building.png`} alt="Image" className={Styles.image} loading='lazy' />
      </div>
      <div className={Styles.redBoxTop}>
        <Typography className={Styles.title}>We Value Integrity.</Typography>
        <Typography className={Styles.description}>
          At DoneRight, integrity is the cornerstone of our business. We believe
          in honest communication and transparency with our clients. Our
          commitment to ethical practices ensures that we build trust and foster
          long-lasting relationships. You can count on us to deliver what we
          promise, every time.
        </Typography>
      </div>
      <div className={Styles.redBoxBottom}>
        <Typography className={Styles.title}>We Prioritize Safety.</Typography>
        <Typography className={Styles.description}>
          Safety is a top priority at DoneRight. We ensure that our team is
          trained in the latest safety protocols and uses eco-friendly cleaning
          products that are safe for you and the environment. By maintaining a
          rigorous standard of safety, we protect our clients, our employees,
          and the spaces we clean.
        </Typography>
      </div>
    </div>
  );
};

export default ContainerOne;
