import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDeletePriceMutation } from "../../Store/Services/PriceService";
import { closePriceModal } from "../../Store/Slices/priceSlice";
import DeletePopUp from "../Shared/DeletePopUp";


const DeletePrice = ({handleClose}) => {
    const dispatch = useDispatch();
    const {selectedPrice} = useSelector((state) => state.price);

    const [deletePrice] = useDeletePriceMutation();

    const onDeleteConfirm = async () => {
        try{
            await deletePrice(selectedPrice).unwrap();

        }catch(err){
            console.error('Failed to delete the price' , err);
        }
        dispatch(closePriceModal());
    }


    return(
        <DeletePopUp
             data="Price"
             close={() => dispatch(closePriceModal())}
             onDeleteConfirm={onDeleteConfirm}
        />
    )
};

export default DeletePrice;