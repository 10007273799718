import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeResourceModal } from "../../Store/Slices/resourceSlice";
import AddResource from "./AddResource";
import EditResource from "./EditResource";
import DeleteResource from "./DeleteResource";
import AvailabilityResource from "./AvailabilityResource";

const ResourceDialog = () => {
  const dispatch = useDispatch();
  const resource = useSelector((state) => state.resource);

  const handleClose = () => {
    dispatch(closeResourceModal(false));
  };

  const roles = sessionStorage.getItem("roles");

  let content;

  switch (resource.modalStatus) {
    case "add":
      content = roles?.split(",").includes("PRIV_ES_ADD_RESOURCE") ? <AddResource handleClose={handleClose} /> : null;
      break;
    case "edit":
      content =  roles?.split(",").includes("PRIV_ES_EDIT_RESOURCE") ? <EditResource handleClose={handleClose} /> : null;
      break;
    case "delete":
      content = roles?.split(",").includes("PRIV_ES_DELETE_RESOURCE") ? <DeleteResource /> : null;
      break;
    case "availability":
      content = <AvailabilityResource handleClose={handleClose} idResCal={resource.idResCal} />;
      break;
    default:
      content = null;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={resource.modalStatus === "delete" ? "xs" : "md"}
      open={["add", "edit", "delete", "availability"].includes(resource.modalStatus)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default ResourceDialog;
