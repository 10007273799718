import { Delete } from "@mui/icons-material";
import {
  Button,
  IconButton,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useFormContext, useFieldArray } from "react-hook-form";

const AttributeValueTable = (props) => {
  const { attributeIndex, from, deletedVal, setDeletedVal } = props;

  const { register, control, formState: { errors } } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `attributes.${attributeIndex}.attributeValues`,
  });

  const deleteValue = (item, index) => {
    if (from === "edit") {
      setDeletedVal([...deletedVal, item.variantValId]);
    }
    remove(index);
  };

  return (
    <>
      <Table style={{ borderBottom: "none" }}>
        <TableRow>
          <TableCell
            colSpan={5}
            align="right"
            style={
              fields.length !== 0 ? { padding: 0, borderBottom: "none" } : null
            }
          >
            <Button
              type="button"
              variant="contained"
              onClick={() => append({ value: "" })}
            >
              Add Value
            </Button>
          </TableCell>
        </TableRow>

        {fields.map((item, index) => {
          return (
            <TableRow key={item.id}>
              <TableCell
                style={
                  fields.length !== index + 1 ? { borderBottom: "none" } : null
                }
                align="right"
              >
                {errors.attributes?.[attributeIndex]?.attributeValues?.[index]
                  ?.value && <span style={{ color: "red" }}> * </span>}
                <OutlinedInput
                  {...register(
                    `attributes.${attributeIndex}.attributeValues.${index}.value`,
                    { required: true }
                  )}
                  placeholder="Attribute Value"
                  defaultValue={item.value}
                  size="small"
                />
              </TableCell>
              <TableCell
                style={
                  fields.length !== index + 1 ? { borderBottom: "none" } : null
                }
                align="right"
              >
                <IconButton type="button" onClick={() => deleteValue(item, index)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

export default AttributeValueTable;
