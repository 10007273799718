import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { openAddNoOfResourceModal , closeNoOfResourceModal } from "../../Store/Slices/noOfResourceSlice";
import { useAddNoOfResMutation } from "../../Store/Services/NoOfResourceService";
import styles from "./NoOfResource.module.css";
import {toast} from 'react-toastify'



const schema = yup.object({
    noRes : yup.string().required("No Of Resource is required")
});

const AddNoOfResource = ({handleClose}) => {
    const {register , handleSubmit , formState:{errors}} = useForm({resolver:yupResolver(schema)});

    const [addNoOfRes] = useAddNoOfResMutation();

    const submit = async (data) => {
        try{
            await addNoOfRes(data).unwrap();
            handleClose();
            toast.success("No Of Resource added successfully !" ,  {position:'bottom-right'});
        }catch(err){
            console.error(err);
            toast.error("Error , Something went wrong" ,  {position:'bottom-right'});

        }
    };

    const onKeyDown = (event) => {
      if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
        event.preventDefault();
      }
    };


    return (
        <div className={styles.addCouponContainer}>
          <div className={styles.headingContainer}>
            <div className={styles.dialogHeader}>
              <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
                Add No Of Resource
              </Typography>
            </div>
            <div className={styles.close}>
              <IconButton className={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
    
          <form onSubmit={handleSubmit(submit)} autoComplete="off">
            <div className={styles.formItem}>
              <label className={styles.formLabel}> No Of Resource :</label>
              {errors.noRes && errors.noRes.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput {...register('noRes')}
                className={styles.formInput}
                placeholder="Enter Number of Resource"
                size="small"
                autoComplete="off"
                onKeyDown={onKeyDown}
                fullWidth
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                className={styles.submitButton}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      );

};


export default AddNoOfResource;