import { apiSlice } from "../Slices/apiSlice";


export const NoOfHrsService = apiSlice.injectEndpoints({
    endpoints:builder => ({
        getAllNoHrs : builder.query({
            query:() => "/no-hrs",
            providesTags:['no-hrs']
        }),

        getHrsById : builder.query({
            query : (noHrsId) => `/no-hrs/${noHrsId}`,
            providesTags:['no-hrs']
        }),

        addHrs:builder.mutation({
            query:(noHrsDetails) => ({
                url:'/no-hrs',
                method:'POST',
                body:noHrsDetails
            }),
            invalidatesTags:['no-hrs']
        }),

        updateHrs : builder.mutation({
            query : (noHrsDetails) => ({
                url : `/no-hrs/${noHrsDetails.noHrsId}`,
                method:'PUT',
                body:noHrsDetails
            }),
            invalidatesTags:['no-hrs']
        }),

        deleteHrs : builder.mutation({
            query:(noHrsId) => ({
                url : `/no-hrs/${noHrsId}`,
                method:'DELETE'
            }),
            invalidatesTags:['no-hrs']
        })

    })
});

export const {
    useGetAllNoHrsQuery,
    useGetHrsByIdQuery,
    useAddHrsMutation,
    useUpdateHrsMutation,
    useDeleteHrsMutation
} = NoOfHrsService;