import React from "react";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from "./Comment.module.css";
import { useGetRequestByIdQuery } from "../../Store/Services/RequestService";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const CommentDetailsDialog = ({ onClose, reqId }) => {
  const {
    data: requestData,
    isLoading,
    isError,
  } = useGetRequestByIdQuery(reqId);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        {isLoading ? (
          <Typography variant="body1" color="textSecondary">
            Loading...
          </Typography>
        ) : isError ? (
          <Typography variant="body1" color="error">
            No available data
          </Typography>
        ) : (
          requestData &&
          requestData.length > 0 && (
            <div>
              <div className={styles.headingContainer}>
                <Typography
                  className={styles.modalTitle}
                  fontWeight={600}
                  variant={"h5"}
                >
                  {requestData[0].catName}
                </Typography>
                <IconButton className={styles.closeButton} onClick={onClose}>
                  <Close />
                </IconButton>
              </div>
              <div className={styles.headingContainer}>
                {requestData[0].freqName && (
                  <label>({requestData[0].freqName})</label>
                )}
              </div>
              <div className={styles.headingContainer}>
                {requestData[0].location && (
                  <label className={styles.titleLabel}>
                    {requestData[0].location}
                  </label>
                )}
              </div>
              <div className={styles.headingContainer}>
                {(requestData[0].addressLine1 ||
                  requestData[0].addressLine2 ||
                  requestData[0].addressLine3) && (
                  <label className={styles.titleLabel}>
                    {`${requestData[0].addressLine1} ${requestData[0].addressLine2} ${requestData[0].addressLine3}`}
                  </label>
                )}
              </div>
              <Typography
                className={styles.subheadingContainer}
                fontWeight={600}
                variant={"body1"}
              >
                Remarks:
              </Typography>

              {requestData[0].resComment || requestData[0].validComment ? (
                <>
                  <div className={styles.listItem}>
                    {requestData[0].resComment && (
                      <>
                        <label className={styles.commentTitle}>
                          Resource Comment
                        </label>
                        <div className={styles.commentItem}>
                          {requestData[0].resComment}
                        </div>
                      </>
                    )}
                  </div>
                  <div className={styles.listItem}>
                    {requestData[0].validComment && (
                      <>
                        <label className={styles.commentTitle}>
                          Validation Comment:
                        </label>
                        <div className={styles.commentItem}>
                          {requestData[0].validComment}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className={styles.listItem}>
                  <div className={styles.commentItem}>No remarks available</div>
                </div>
              )}
              <Typography
                className={styles.subheadingContainer}
                fontWeight={600}
                variant={"body1"}
              >
                Payment Details:
              </Typography>
              <div className={styles.listItem}>
                {requestData[0].paymentMethod && (
                  <label className={styles.formLabel}>
                    Payment Method: {requestData[0].paymentMethod}
                  </label>
                )}
              </div>
              <Typography
                className={styles.subheadingContainer}
                fontWeight={600}
                variant={"body1"}
              >
                Contact Details:
              </Typography>
              <div className={styles.listItem}>
                {requestData[0].phone && (
                  <label className={styles.formLabel}>
                    Phone: {requestData[0].phone}
                  </label>
                )}
              </div>
              <div className={styles.listItem}>
                {requestData[0].mobile && (
                  <label className={styles.formLabel}>
                    Mobile: {requestData[0].mobile}
                  </label>
                )}
              </div>
              <div className={styles.listItem}>
                {requestData[0].email && (
                  <label className={styles.formLabel}>
                    Email: {requestData[0].email}
                  </label>
                )}
              </div>
            </div>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CommentDetailsDialog;
