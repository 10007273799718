import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDeleteAreaMutation } from "../../Store/Services/AreaService";
import { closeAreaModal } from "../../Store/Slices/areaSlice";
import DeletePopUp from "../Shared/DeletePopUp";

const DeleteArea = () => {
  const dispatch = useDispatch();
  const area = useSelector((area) => area.area);

  const [deleteArea] = useDeleteAreaMutation();

  const onDeleteConfirm = async () => {
    const id = area.selectedArea;
    try {
      const response = await deleteArea(id);
      const deletedArea = response.data;
      toast.success("Area successfully deleted!", {
        position: "bottom-right",
      });
    } catch (err) {
      console.error("Failed to delete area: ", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }
    dispatch(closeAreaModal());
  };

  return (
    <DeletePopUp
      data="Area"
      close={() => dispatch(closeAreaModal())}
      onDeleteConfirm={onDeleteConfirm}
    />
  );
};

export default DeleteArea;
