import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeAreaModal } from "../../Store/Slices/areaSlice"
import AddArea from "./AddArea";
import EditArea from "./EditArea";
import DeleteArea from "./DeleteArea";

const AreaDialog = () => {
    const dispatch = useDispatch();
    const  area = useSelector((state) => state.area);

    let content;
    let deleteView = false;

    const roles = sessionStorage.getItem("roles");

    const handleClose = (event, reason) => {
      if (reason !== 'backdropClick') {
          dispatch(closeAreaModal());
      }
  };

    if (area.modalStatus === "add") {
        content = roles?.split(",").includes("PRIV_ES_ADD_AREA") ? <AddArea handleClose={() => handleClose()} /> : null;
      } else if (area.modalStatus === "edit") {
        content = roles?.split(",").includes("PRIV_ES_EDIT_AREA") ? <EditArea handleClose={() => handleClose()} /> : null;
      } else if (area.modalStatus === "delete") {
        deleteView = true
        content = roles?.split(",").includes("PRIV_ES_DELETE_AREA") ? <DeleteArea/> : null;
      }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={deleteView ? "xs" : "md"}
            open={area.modalStatus === "add" || area.modalStatus === "edit" || area.modalStatus === "delete"}
            onClose={handleClose}
            aria-labelledby="coupon-dialog-title"
            aria-describedby="coupon-dialog-description"
         
        >
            <DialogContent>{content}</DialogContent>
        </Dialog>
    );
};

export default AreaDialog;
