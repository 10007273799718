// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CouponList_row__QzTYV{
    background: linear-gradient(270deg, rgba(103, 217, 196, 0.37) 15.62%, rgba(217, 217, 217, 0.03) 100%);
    height:30px;
}

.CouponList_headings__mbiSr{
    border: 5px solid #9964df;
    background: #FFF;
}

.CouponList_container__VX8Xa{
    max-height:350px;
    min-height: 350px;
}

`, "",{"version":3,"sources":["webpack://./src/Components/State/CouponList.module.css"],"names":[],"mappings":"AAAA;IACI,qGAAqG;IACrG,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".row{\r\n    background: linear-gradient(270deg, rgba(103, 217, 196, 0.37) 15.62%, rgba(217, 217, 217, 0.03) 100%);\r\n    height:30px;\r\n}\r\n\r\n.headings{\r\n    border: 5px solid #9964df;\r\n    background: #FFF;\r\n}\r\n\r\n.container{\r\n    max-height:350px;\r\n    min-height: 350px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `CouponList_row__QzTYV`,
	"headings": `CouponList_headings__mbiSr`,
	"container": `CouponList_container__VX8Xa`
};
export default ___CSS_LOADER_EXPORT___;
