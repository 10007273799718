import { Close } from "@mui/icons-material";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import {
  useUpdateCouponMutation,
  useGetCouponByIdQuery,
} from "../../Store/Services/CouponService";
import { useDispatch, useSelector } from "react-redux";
import {
  openEditCouponModal,
  closeCouponModal,
} from "../../Store/Slices/couponSlice";
import styles from "./AddCoupon.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const schema = yup.object().shape({
  couponCode: yup.string().required(),
  off: yup.string().required(),
});

const EditCoupon = ({ handleClose }) => {
  const coupon = useSelector((state) => state.coupon);

  let couponData = useMemo(() => {
    return { id: "" };
  }, []);

  const {
    data: couponDetails,
    isSuccess: couponSuccess,
    isLoading: couponLoading,
  } = useGetCouponByIdQuery(coupon.selectedCoupon ?? skipToken);

  if (couponSuccess) {
    couponData = couponDetails?.couponList?.[0];
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (
      couponSuccess &&
      Array.isArray(couponDetails) &&
      couponDetails.length > 0
    ) {
      const firstCoupon = couponDetails[0];
      reset({
        couponCode: firstCoupon?.couponCode,
        off: firstCoupon?.off,
      });
    }
  }, [couponDetails, couponSuccess, reset]);

  const [updateCoupon] = useUpdateCouponMutation();

  const submit = async (data) => {
    const dataSet = {
      ...data,
      couponId: coupon.selectedCoupon,
    };

    try {
      const respond = await updateCoupon(dataSet).unwrap();
      toast.success("Coupon successfully updated!", {
        position: "bottom-right",
      });
    } catch (err) {
      console.error("Failed to save Coupon: ", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }

    handleClose();
  };

  const onKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography
            fontWeight={600}
            className={styles.dialogTitle}
            variant="h6"
          >
            Edit Coupon
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(submit)}>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Coupon Code:</label>
          {errors.couponCode && errors.couponCode.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("couponCode")}
            className={styles.formInput}
            placeholder="Enter Coupon Code"
            size="small"
            fullWidth
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formLabel}> Offer :</label>
          {errors.off && errors.off.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("off")}
            className={styles.formInput}
            placeholder="Enter Offer"
            size="small"
            onKeyDown={onKeyDown}
            autoComplete="off"
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditCoupon;
