import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDeleteSlotMutation } from "../../Store/Services/SlotService";
import { closeSlotModal } from "../../Store/Slices/slotSlice";
import DeletePopUp from "../Shared/DeletePopUp";

const DeleteSlot = () => {
  const dispatch = useDispatch();
  const slot = useSelector((slot) => slot.slot);

  const [deleteSlot] = useDeleteSlotMutation();

  const onDeleteConfirm = async () => {
    const id = slot.selectedSlot;
    try {
      const response = await deleteSlot(id);
      const deletedSlot = response.data;
      toast.success("Slot successfully deleted!", {
        position: "bottom-right",
      });
    } catch (err) {
      console.error("Failed to delete slot: ", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }
    dispatch(closeSlotModal());
  };

  return (
    <DeletePopUp
      data="Slot"
      close={() => dispatch(closeSlotModal())}
      onDeleteConfirm={onDeleteConfirm}
    />
  );
};

export default DeleteSlot;