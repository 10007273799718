import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import SubmitedList from "./SubmitedList";
import ApprovedList from "./ApprovedList";
import RejectedList from "./RejectedList";
import commentListData from "./commentListData";

const TabsView = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", paddingTop: "2rem" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ "& .MuiTabs-flexContainer": { justifyContent: "space-between" } }}
      >
        <Tab label="SUBMITTED" sx={{ width: "33.33%" }} />
        <Tab label="APPROVED" sx={{ width: "33.33%" }} />
        <Tab label="REJECTED" sx={{ width: "33.33%" }} />
      </Tabs>

      <Box my={2}>
        {value === 0 && <SubmitedList data={commentListData} />}
        {value === 1 && <ApprovedList data={commentListData} />}
        {value === 2 && <RejectedList data={commentListData} />}
      </Box>
    </Box>
  );
};

export default TabsView;
