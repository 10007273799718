import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {useDeleteFrequencyMutation } from "../../Store/Services/FrequencyService";
import { closeFreqModal } from "../../Store/Slices/frequencySlice";
import DeletePopUp from "../Shared/DeletePopUp";


const DeleteFrequency = () => {

    const dispatch = useDispatch();
    const freq = useSelector((state) => state.freq);

    const [deleteFreq] = useDeleteFrequencyMutation();

    const onDeleteConfirm = async () => {
        const id = freq.selectedState;
        try {
          const response = await deleteFreq(id);
          const deletedFreq = response.data;
          toast.success("Frequency successfully deleted!", {
            position: "bottom-right",
          });
        } catch (err) {
          console.error("Failed to delete Frequency: ", err);
          toast.error("Error, something went wrong!", { position: "bottom-right" });
        }
        dispatch(closeFreqModal());
      };

    return (
        <DeletePopUp
            data="Frequency"
            close={() => dispatch(closeFreqModal())}
            onDeleteConfirm={onDeleteConfirm}
        />
    );
}

export default DeleteFrequency;