import { Close } from "@mui/icons-material";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import {
  useUpdateFrequencyMutation,
  useGetFrequencyByIdQuery,
} from "../../Store/Services/FrequencyService";
import { useDispatch, useSelector } from "react-redux";
import {
  openEditFreqModal,
  closeFreqModal,
} from "../../Store/Slices/frequencySlice";
import styles from "./Frequency.module.css";
import CloseIcon from "@mui/icons-material/Close";

const schema = yup.object({
  freqName: yup.string().required(""),
  freqDesc: yup.string().required(""),
  freqOff: yup.string().required(),
});

const EditFrequency = ({ handleClose }) => {
  const freq = useSelector((state) => state.freq);

  let freqData = useMemo(() => {
    return { id: "" };
  }, []);

  const {
    data: freqDetails,
    isSuccess: freqSuccess,
    isLoading: freqLoading,
  } = useGetFrequencyByIdQuery(freq.selectedFreq);

  if (freqSuccess) {
    freqData = freqDetails?.freqList?.[0];
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  useEffect(() => {
    if (freqSuccess && Array.isArray(freqDetails) && freqDetails.length > 0) {
      const firstFreq = freqDetails[0];
      reset({
        freqName: firstFreq?.freqName,
        freqDesc:firstFreq?.freqDesc,
        freqOff:firstFreq?.freqOff,
      });
    }
  }, [freqDetails, freqSuccess, reset]);


   
  const [updateFreq] = useUpdateFrequencyMutation();

  const submit = async (data) => {
 
    const dataSet = {
      ...data,
      freqId: freq.selectedFreq
    };
 
    try {
      const res = await updateFreq(dataSet).unwrap();
      toast.success("Frequency successfully updated!", {position:'bottom-right'});
    } catch (err) {
      console.error("Failed to save frequency: ", err);
      toast.error("Error, something went wrong!", {position:'bottom-right'});
    }
 
    handleClose();
  };

  const onKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };


  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography
            fontWeight={600}
            className={styles.dialogTitle}
            variant="h6"
          >
            Add Frequency
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(submit)} autoComplete="off">
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Frequency Name :</label>
          {errors.freqName && errors.freqName.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("freqName")}
            className={styles.formInput}
            placeholder="Enter Frequency Name"
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Frequency Description :</label>
          {errors.freqDesc && errors.freqDesc.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("freqDesc")}
            className={styles.formInput}
            placeholder="Enter Frequency Name"
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Frequency Amount :</label>
          {errors.freqOff && errors.freqOff.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("freqOff")}
            className={styles.formInput}
            placeholder="Enter Frequency Amount"
            onKeyDown={onKeyDown}
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );

};

export default EditFrequency;
