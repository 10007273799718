import { apiSlice } from '../Slices/apiSlice';

export const BrPrivService = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllMappings: builder.query({
            query: () => "/br-priv",
            providesTags: ['brPriv']
        }),

        getMappingById: builder.query({
            query: (brId) => `/br-priv/${brId}`,
            providesTags: ['brPriv']
        }),

        createMapping: builder.mutation({
            query: (details) => ({
                url: '/br-priv',
                method: 'POST',
                body: details
            }),
            invalidatesTags: ['brPriv']
        }),

        updateMapping: builder.mutation({
            query: (details) => ({
                url: `/br-priv/${details.brId}`,
                method: 'PUT',
                body: details
            }),
            invalidatesTags: ['brPriv']
        }),

        deleteMapping: builder.mutation({
          query: (brId) => ({
                url: `/br-priv/${brId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["brPriv"],
        }),

    })
});

export const {
    useGetAllMappingsQuery,
    useGetMappingByIdQuery,
    useCreateMappingMutation,
    useUpdateMappingMutation,
    useDeleteMappingMutation,
} = BrPrivService;