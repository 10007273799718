import { createSlice } from '@reduxjs/toolkit';

export const brPrivSlice = createSlice({
    name: "brPriv",
    initialState: {
        modalStatus: false,
        selectedBrPriv: null
    },
    reducers: {
        openAddBrPrivModal: (state) => {
            state.modalStatus = "add";
        },
        openEditBrPrivModal: (state, action) => {
            state.modalStatus = "edit";
            state.selectedBrPriv = action.payload;
        },
        openDeleteBrPrivModal: (state, action) => {
            state.modalStatus = "delete";
            state.selectedBrPriv = action.payload;
        },
        closeBrPrivModal: (state) => {
            state.modalStatus = false;
            state.selectedBrPriv = null;
        }
    }
});

export const {
    openAddBrPrivModal,
    openEditBrPrivModal,
    openDeleteBrPrivModal,
    closeBrPrivModal
} = brPrivSlice.actions;

export default brPrivSlice.reducer;