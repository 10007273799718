import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUpdateCalMutation } from "../../Store/Services/CalService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useGetAllCalsQuery } from "../../Store/Services/CalService";
import { useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";
import styles from "./AddCal.module.css";

const schema = yup
  .object({
    day: yup.string().required(""),
    availability: yup.string().required(""),
  })
  .required();

const EditCal = ({ handleClose, selectedDate }) => {
  const [calId, setCalId] = useState(null);

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [updateCal] = useUpdateCalMutation();
  const { data: allCalsData } = useGetAllCalsQuery();

  useEffect(() => {
    const selectedCal = allCalsData.find((cal) => cal.day === selectedDate);

    const fetchedCalId = selectedCal?.calId;
    setCalId(fetchedCalId);

    reset({
      day: selectedDate,
      availability: selectedCal?.availability.toString() || "",
    });
    setValue("availability", selectedCal?.availability.toString() || "");
  }, [selectedDate, allCalsData, reset, setValue]);

  const onSubmit = async (data) => {
    try {
      const formattedPayload = {
        days: [data.day],
        availability: parseInt(data.availability),
      };
      toast.success("Dates successfully updated!", {
        position: "bottom-right",
      });

      if (calId) {
        const response = await updateCal({
          ...formattedPayload,
          calId,
        }).unwrap();
      } else {
        console.error("calId is undefined");
      }
    } catch (err) {
      console.error("Failed to save cal: ", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }
    handleClose();
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Edit Date: {selectedDate}
        </Typography>
        <div className={styles.iconContainer}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItem}>
          {errors.availability && errors.availability.type === "required" && (
            <span className={styles.validation}> *</span>
          )}

          <RadioGroup
            {...register("availability", { setValueAs: (value) => value })}
            value={watch("availability", "")}
            onChange={(e) => setValue("availability", e.target.value)}
            row
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Available"
              {...register("availability")}
            />

            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="Not Available"
              {...register("availability")}
            />
          </RadioGroup>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditCal;
