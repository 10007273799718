import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, IconButton, Typography, OutlinedInput } from "@mui/material";
import { Close, Edit, Visibility } from "@mui/icons-material";
import styles from "./Request.module.css";
import { useGetRequestByIdQuery } from "../../Store/Services/RequestService";
import EditFields from "./EditRequestDetails";
import { useGetAllCatWithSubCatQuery } from "../../Store/Services/CategoryService";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm  } from "react-hook-form";


const RequestDetailsDialog = ({ onClose, reqId }) => {

  console.log("Request Id" , reqId)

  const [editMode, setEditMode] = useState(false);
  const [formData,setFormData] = useState(null);
  const [catName , setCatName] = useState("");
  const [selectedCat, setSelectedCat] = useState("");


  const {
    data: requestData,
    isLoading,
    isSuccess:requestSuccess,
    isError,
  } = useGetRequestByIdQuery(reqId);


  const { data: categories, isSuccess: categoriesSuccess } =
  useGetAllCatWithSubCatQuery();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(),
  });

  console.log("Reqs" , requestData)

  const toggleMode = () => {
    setEditMode((prevEditMode) => !prevEditMode);
  };

  useEffect(() => {
    if(requestSuccess &&Array.isArray(requestData)  && requestData.length > 0){
      const {catName,catId,resourceId,date,noRes,noHrs,freqName,freqId,addressLine1,addressLine2,addressLine3,email,remarks,stateId,areaId , timeSlot , dateId , timeSlotId} = requestData[0];
      setFormData({catName,catId,resourceId,dateId,date,noRes,noHrs,freqName,freqId,addressLine1,addressLine2,addressLine3,email,remarks,stateId,areaId,timeSlot , timeSlotId});
      setSelectedCat(catId);
      setCatName(catName)
      console.log("Datess" , dateId)
      // setValue("catName", catName || "");
    
    }
  
  },[requestData , requestSuccess]);

  return (
    <Dialog maxWidth="md" open={true} onClose={onClose} fullWidth>
      <DialogContent>
        {isLoading ? (
          <Typography variant="body1" color="textSecondary">
            Loading...
          </Typography>
        ) : isError ? (
          <Typography variant="body1" color="error">
            Error fetching request details
          </Typography>
        ) : (
          requestData &&
          requestData.length > 0 && (
            <div>
              <div className={styles.headingContainer}>
                {editMode ? (  <Typography
                  className={styles.modalTitle}
                  fontWeight={600}
                  variant={"h5"}
                >
                  Edit Request
                </Typography>) :
                (<Typography
                  className={styles.modalTitle}
                  fontWeight={600}
                  variant={"h5"}
                >
                  {requestData[0].catName}
                </Typography>) }
                <div>
                  {editMode ? (
                    <IconButton className={styles.btnsv}  onClick={toggleMode}>
                      <Visibility />
                    </IconButton>
                  ) : (
                    <IconButton className={styles.btnse} onClick={toggleMode}>
                      <Edit />
                    </IconButton>
                  )}
                </div>
                <IconButton className={styles.closeButton} onClick={onClose}>
                  <Close />
                </IconButton>
              </div>
              
              {editMode ? (
                <EditFields requestData={formData} setCatName={setCatName} selectedCat={selectedCat} setSelectedCat={setSelectedCat} catName={catName} reqId={reqId}  />
              ) : (
                <>
                  <div className={styles.headingContainer}>
                    {requestData[0].freqName && (
                      <label>({requestData[0].freqName})</label>
                    )}
                  </div>
                  <div className={styles.headingContainer}>
                    {requestData[0].location && (
                      <label className={styles.titleLabel}>
                        {requestData[0].location}
                      </label>
                    )}
                  </div>
                  <div className={styles.headingContainer}>
                    {(requestData[0].addressLine1 ||
                      requestData[0].addressLine2 ||
                      requestData[0].addressLine3) && (
                      <label className={styles.titleLabel}>
                        {`${requestData[0].addressLine1} ${requestData[0].addressLine2} ${requestData[0].addressLine3}`}
                      </label>
                    )}
                  </div>
                  <Typography
                    className={styles.subheadingContainer}
                    fontWeight={600}
                    variant={"body1"}
                  >
                    Remarks:
                  </Typography>

                  {requestData[0].resComment || requestData[0].validComment ? (
                    <>
                      <div className={styles.listItem}>
                        {requestData[0].resComment && (
                          <>
                            <label className={styles.commentTitle}>
                              Resource Comment
                            </label>
                            <div className={styles.commentItem}>
                              {requestData[0].resComment}
                            </div>
                          </>
                        )}
                      </div>
                      <div className={styles.listItem}>
                        {requestData[0].validComment && (
                          <>
                            <label className={styles.commentTitle}>
                              Validation Comment:
                            </label>
                            <div className={styles.commentItem}>
                              {requestData[0].validComment}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className={styles.listItem}>
                      <div className={styles.commentItem}>No remarks available</div>
                    </div>
                  )}
                  <Typography
                    className={styles.subheadingContainer}
                    fontWeight={600}
                    variant={"body1"}
                  >
                    Payment Details:
                  </Typography>
                  <div className={styles.listItem}>
                    {requestData[0].paymentMethod && (
                      <label className={styles.formLabel}>
                        Payment Method: {requestData[0].paymentMethod}
                      </label>
                    )}
                  </div>
                  <Typography
                    className={styles.subheadingContainer}
                    fontWeight={600}
                    variant={"body1"}
                  >
                    Contact Details:
                  </Typography>
                  <div className={styles.listItem}>
                    {requestData[0].phone && (
                      <label className={styles.formLabel}>
                        Phone: {requestData[0].phone}
                      </label>
                    )}
                  </div>
                  <div className={styles.listItem}>
                    {requestData[0].mobile && (
                      <label className={styles.formLabel}>
                        Mobile: {requestData[0].mobile}
                      </label>
                    )}
                  </div>
                  <div className={styles.listItem}>
                    {requestData[0].email && (
                      <label className={styles.formLabel}>
                        Email: {requestData[0].email}
                      </label>
                    )}
                  </div>
                </>
              )}
            </div>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};


// const EditFields = ({requestData}) => {
//   return(
//     <div>
//       <label> Category Name : {requestData[0]?.catName} </label>
//       {/* <OutlinedInput size="small" className={styles.formInput} fullWidth>
        
//       </OutlinedInput> */}

//     </div>
//   )
// }


export default RequestDetailsDialog;

