import React, { useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useGetRequestsFilterByTimeQuery } from "../Store/Services/RequestService";
import styles from "./Profile.module.css";
import { useNavigate } from "react-router-dom";

const BookingsPage = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetRequestsFilterByTimeQuery();
  const [value, setValue] = useState("1");
  const [selectedReqId, setSelectedReqId] = useState(null);

  const handleTabsChange = async (event, newValue) => {
    setValue(newValue);
  };

  const handleCardClick = (reqId, type) => {
    setSelectedReqId(reqId);
    navigate(`/profile/requests/${reqId}`, { state: { type } });
  };

  return (
    <div className={styles.container}>
      <Paper className={styles.paper}>
        <div className={styles.addAddressContainer}>
          <Typography fontWeight={600} variant={"h6"}>
            Bookings
          </Typography>
        </div>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              variant="fullWidth"
              onChange={handleTabsChange}
              aria-label="order tabs"
            >
              <Tab label="Upcoming" value="1" />
              <Tab label="Past" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" className={styles.tabPanel}>
            {isLoading ? (
              <Backdrop open={isLoading} invisible>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <div>
                {data && data.upcoming && data.upcoming.length > 0 ? (
                  data.upcoming.map((req) => (
                    <div
                      key={req.reqId}
                      className={styles.bookingContainer}
                      onClick={() => handleCardClick(req.reqId, "UPCOMING")}
                    >
                      <div>
                        <Typography className={styles.subTitle}>
                          {req.catName}
                        </Typography>
                        <Typography className={styles.details}>
                          {req.date} {req.timeSlot} ({req.freqName})
                        </Typography>
                      </div>
                      <Typography className={styles.status}>
                        {req.reqStatus}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <Typography variant="body2" className={styles.noRequest}>
                    No upcoming appointments available
                  </Typography>
                )}
              </div>
            )}
          </TabPanel>
          <TabPanel value="2" className={styles.tabPanel}>
            {isLoading ? (
              <Backdrop open={isLoading} invisible>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <div>
                {data && data.past && data.past.length > 0 ? (
                  data.past.map((req) => (
                    <div
                      key={req.reqId}
                      className={styles.bookingContainer}
                      onClick={() => handleCardClick(req.reqId, "PAST")}
                    >
                      <div>
                        <Typography className={styles.subTitle}>
                          {req.catName}
                        </Typography>
                        <Typography className={styles.details}>
                          {req.date} {req.timeSlot} ({req.freqName})
                        </Typography>
                      </div>
                      <Typography className={styles.status}>
                        {req.reqStatus}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <Typography variant="body2" className={styles.noRequest}>
                    No past appointments available
                  </Typography>
                )}
              </div>
            )}
          </TabPanel>
        </TabContext>
      </Paper>
    </div>
  );
};

export default BookingsPage;
