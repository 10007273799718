import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useCreateFrequencyMutation } from "../../Store/Services/FrequencyService";
import styles from "./Frequency.module.css";
import { toast } from "react-toastify";

const schema = yup.object({
  freqName: yup.string().required(""),
  freqDesc: yup.string().required(""),
  freqOff: yup.string().required(),
});

const AddFrequency = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [addFreq] = useCreateFrequencyMutation();

  const submit = async (data) => {
    try {
      await addFreq(data).unwrap();
      handleClose();
      toast.success("Frequency added successfully !", {
        position: "bottom-right",
      });
    } catch (err) {
      console.error(err);
      toast.error("Error , Something went wrong", { position: "bottom-right" });
    }
  };

  const onKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography
            fontWeight={600}
            className={styles.dialogTitle}
            variant="h6"
          >
            Add Frequency
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(submit)} autoComplete="off">
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Frequency Name :</label>
          {errors.freqName && errors.freqName.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("freqName")}
            className={styles.formInput}
            placeholder="Enter Frequency Name"
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Frequency Description :</label>
          {errors.freqDesc && errors.freqDesc.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("freqDesc")}
            className={styles.formInput}
            placeholder="Enter Frequency Name"
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Frequency Amount :</label>
          {errors.freqOff && errors.freqOff.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("freqOff")}
            className={styles.formInput}
            placeholder="Enter Frequency Amount"
            onKeyDown={onKeyDown}
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};


export default AddFrequency;
