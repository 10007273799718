import { Close } from "@mui/icons-material";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import {
  useUpdateNoOfResMutation
} from "../../Store/Services/NoOfResourceService";
import  {useGetNoOfResByIdQuery} from '../../Store/Services/NoOfResourceService'
import { useDispatch, useSelector } from "react-redux";
import {
  openEditNoOfResource,
  closeNoOfResource,
} from "../../Store/Slices/noOfResourceSlice";
// import styles from "./AddCoupon.module.css";
import styles from './NoOfResource.module.css'
import CloseIcon from "@mui/icons-material/Close";


const schema = yup.object().shape({
    noRes : yup.string().required()
});


const EditNoOfResource = ({handleClose}) => {
    const res = useSelector((state) => state.res);


    let resData = useMemo(() => {
        return {id : ''};
    },[]);

    const {data:noOfResDetails , isSuccess:noOfResSuccess , isLoading:noOfResLoading} = useGetNoOfResByIdQuery(res.selectedRes);

     
  if (noOfResSuccess) {
    resData = noOfResDetails?.noOfResList?.[0];
  }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      });

      useEffect(() => {
        if (noOfResSuccess && Array.isArray(noOfResDetails) && noOfResDetails.length > 0) {
          const firstNoOfRes = noOfResDetails[0];
          reset({
            noRes: firstNoOfRes?.noRes
          });
        }
      }, [noOfResDetails, noOfResSuccess, reset]);

      const [updateNoOfRes] = useUpdateNoOfResMutation();

      const submit = async(data) => {
        const formData = {
            ...data,
            noResId: res.selectedRes
        };

        try{
            await updateNoOfRes(formData).unwrap();
            toast.success("No Of Resource successfully updated!", {position:'bottom-right'});
        }catch(err){
            console.error(err);
            toast.error("Error, something went wrong!", {position:'bottom-right'})
        }

        handleClose();
      };

      const onKeyDown = (event) => {
        if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
          event.preventDefault();
        }
      };

      return (
        <div className={styles.addCouponContainer}>
          <div className={styles.headingContainer}>
            <div className={styles.dialogHeader}>
              <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
                Edit Number Of Resource
              </Typography>
            </div>
            <div className={styles.close}>
              <IconButton className={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
     
          <form onSubmit={handleSubmit(submit)}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}> No Of Resource :</label>
              {errors.noRes && errors.noRes.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput {...register("noRes")}
                className={styles.formInput}
                size="small"
                onKeyDown={onKeyDown}
                autoComplete="off"
                fullWidth
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                className={styles.submitButton}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      );
};

export default EditNoOfResource;