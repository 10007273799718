// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SignUpPage_parentDiv__FvtXR {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size:100% 100%;
}

.SignUpPage_paper__DNw\\+x{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 2px 2px 25px 2px rgba(0, 0, 0, 0.25);;
    width: 80%;
    margin: 5rem;
    overflow: auto; 
}

.SignUpPage_image__susk2 {
    width: 100%;
    display: grid;
    object-fit: contain;
}

.SignUpPage_logo__ZsQkE {
    height: 6rem;
    object-fit: contain;
}

.SignUpPage_signUp__bQZl\\+{
    width:100%;
    text-align: center;
    margin-bottom: 2rem;
}

.SignUpPage_container__vzYZ\\+{
    display:flex;
    justify-content: center;
    align-items: center;
    width:100vw;
    margin-bottom: 2rem;
    
}

.SignUpPage_formContainer__uf4-G{
    margin: 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width:768px){
    .SignUpPage_paper__DNw\\+x{
        flex-direction: column;
    }
    
    .SignUpPage_formContainer__uf4-G{
        padding-right: 0;
    }
}

.SignUpPage_formItem__FZeEe{
    margin-top: 1rem;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/SignUpPage.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gDAAgD;IAChD,UAAU;IACV,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;;AAEvB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI,gBAAgB;EAClB","sourcesContent":["\r\n.parentDiv {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-size:100% 100%;\r\n}\r\n\r\n.paper{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    border-radius: 10px;\r\n    box-shadow: 2px 2px 25px 2px rgba(0, 0, 0, 0.25);;\r\n    width: 80%;\r\n    margin: 5rem;\r\n    overflow: auto; \r\n}\r\n\r\n.image {\r\n    width: 100%;\r\n    display: grid;\r\n    object-fit: contain;\r\n}\r\n\r\n.logo {\r\n    height: 6rem;\r\n    object-fit: contain;\r\n}\r\n\r\n.signUp{\r\n    width:100%;\r\n    text-align: center;\r\n    margin-bottom: 2rem;\r\n}\r\n\r\n.container{\r\n    display:flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width:100vw;\r\n    margin-bottom: 2rem;\r\n    \r\n}\r\n\r\n.formContainer{\r\n    margin: 2.5rem 1rem;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n}\r\n\r\n@media (max-width:768px){\r\n    .paper{\r\n        flex-direction: column;\r\n    }\r\n    \r\n    .formContainer{\r\n        padding-right: 0;\r\n    }\r\n}\r\n\r\n.formItem{\r\n    margin-top: 1rem;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentDiv": `SignUpPage_parentDiv__FvtXR`,
	"paper": `SignUpPage_paper__DNw+x`,
	"image": `SignUpPage_image__susk2`,
	"logo": `SignUpPage_logo__ZsQkE`,
	"signUp": `SignUpPage_signUp__bQZl+`,
	"container": `SignUpPage_container__vzYZ+`,
	"formContainer": `SignUpPage_formContainer__uf4-G`,
	"formItem": `SignUpPage_formItem__FZeEe`
};
export default ___CSS_LOADER_EXPORT___;
