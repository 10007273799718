import {
  Box,
  Button,
  Container,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SearchBar from "../Components/Shared/SearchBar";
import NoOfResDialog from "../Components/NoOfResource/NoOfResDialog";
import NoOfResList from "../Components/NoOfResource/NoOfResList";
import { useGetAllNoResQuery } from "../Store/Services/NoOfResourceService";
import {
  closeNoOfResourceModal,
  openAddNoOfResourceModal,
} from "../Store/Slices/noOfResourceSlice";

import styles from "./Management.module.css";

const NoOfResourceManagement = () => {
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState([]);
  const { data, isSuccess, isLoading } = useGetAllNoResQuery();

  const handleClose = () => {
    dispatch(closeNoOfResourceModal());
  };

  const roles = sessionStorage.getItem("roles");

  return roles?.split(",").includes("PRIV_ES_DISPLAY_NO_RES") ? (
    <Paper
      elevation={3}
      className={styles.pageContainer}
      style={{
        backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/thumbnail_Management.png)`,
      }}
    >
      <Box>
        <Toolbar className={styles.subNavBar}>
          <Typography className={styles.topic}>
            No Of Resource Management
          </Typography>
          {roles?.split(",").includes("PRIV_ES_ADD_NO_RES") ? (
            <Button
              variant="contained"
              color="primary"
              className={styles.buttonItem}
              onClick={() => dispatch(openAddNoOfResourceModal())}
            >
              Add No Of Resource
            </Button>
          ) : null}
        </Toolbar>
      </Box>
      <Container id={styles.container}>
        <SearchBar
          data={isSuccess || isLoading ? data : []}
          setSearchData={setSearchData}
          text="Search Number of Resources"
          search="res"
        />
      </Container>

      <Container>
        {isSuccess && (
          <NoOfResList
            data={
              Array.isArray(searchData) && searchData.length > 0
                ? searchData
                : data
            }
          />
        )}
      </Container>

      <NoOfResDialog handleClose={handleClose} />
    </Paper>
  ) : null;
};

export default NoOfResourceManagement;
