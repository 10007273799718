import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Pagination,
  Typography,
} from "@mui/material";
import { PaginationDetails } from "../../Utils/UtilsReq";
import CommentDetailsDialog from "./CommentDetailsDialog";
import CommentApproveDialog from "./CommentApproveDialog";
import CommentRejectDialog from "./CommentRejectDialog";

import styles from "./CommentList.module.css";

const SubmitedList = ({ data }) => {
  const submittedComments = Array.isArray(data)
    ? data.filter((comment) => comment.validation === "Submited")
    : [];

  const dataSet = Array.isArray(data) ? data : [];
  const { page, count, handleChange, _DATA } = PaginationDetails(dataSet);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

  const handleClick = (commentId) => {
    console.log("Clicked on commentId:", commentId);
    setSelectedCommentId(commentId);
    setIsDialogOpen(true);
  };

  const handleApproveClick = (commentId) => {
    setSelectedCommentId(commentId);
    setIsApproveDialogOpen(true);
  };

  const handleRejectClick = (commentId) => {
    setSelectedCommentId(commentId);
    setIsRejectDialogOpen(true);
  };

  return (
    <>
      <div className={styles.container}>
        {submittedComments.map((comment) => (
          <Card key={comment.commentId} className={styles.card}>
            <CardContent>
              <div className={styles.buttonContainer}>
                <div className={styles.verifyButtonContainer}>
                  <Typography
                    variant="h6"
                    className={styles.text1}
                    onClick={() => handleClick(comment.commentId)}
                  >
                    {comment.comment}
                  </Typography>
                </div>
                <div className={styles.rightButtonContainer}>
                  <Button
                    variant="contained"
                    className={styles.completeButton}
                    onClick={() => handleApproveClick(comment.commentId)}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.completeButton}
                    onClick={() => handleRejectClick(comment.commentId)}
                  >
                    Reject
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      <Box my={2} display="flex" justifyContent="center">
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          color="primary"
          onChange={handleChange}
          className={styles.pagination}
        />
        {isDialogOpen && (
          <CommentDetailsDialog
            onClose={() => setIsDialogOpen(false)}
            commentId={selectedCommentId}
          />
        )}
        {isApproveDialogOpen && (
          <CommentApproveDialog
            onClose={() => setIsApproveDialogOpen(false)}
            commentId={selectedCommentId}
          />
        )}
        {isRejectDialogOpen && (
          <CommentRejectDialog
            onClose={() => setIsRejectDialogOpen(false)}
            commentId={selectedCommentId}
          />
        )}
      </Box>
    </>
  );
};

export default SubmitedList;
