import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm  } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  Button,
  OutlinedInput,
  Typography,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  useGetChargeByIdQuery,
  useUpdateChargeMutation,
} from "../../Store/Services/ChargeService";
import { useGetAllCatWithSubCatQuery } from "../../Store/Services/CategoryService";
import CategoryList from "../Shared/CategoryList";
import styles from "./Charge.module.css";
import { skipToken } from "@reduxjs/toolkit/dist/query";


const schema = yup.object({
  chargeDesc: yup.string().required("Charge Description is required"),
  amount: yup.string().test({
    test: function(value) {
      const percentageFieldValue = this.parent.percentage;
      return value || percentageFieldValue;
    },
    message: 'Either Amount or Percentage must have a value',
  }),
  percentage: yup.string().test({
    test: function(value) {
      const amountFieldValue = this.parent.amount;
      return value || amountFieldValue;
    },
    message: 'Either Amount or Percentage must have a value',
  }),
}).required();

const EditCharge = ({ handleClose }) => {
  const [openCatList, setOpenCatList] = useState(false);
  const [catName, setCatName] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [disabledFields , setDisabledFields] = useState({
    amount:false,
    percentage:false,
  })
  const { data: categories, isSuccess: categoriesSuccess } =
    useGetAllCatWithSubCatQuery();

  const charge = useSelector((state) => state.charge);

  const {
    data: chargeDetails,
    isSuccess: chargeSuccess,
    isLoading: chargeLoading,
  } = useGetChargeByIdQuery(charge.selectedCharge ?? skipToken);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAmountChange = (event) => {
    const amountValue = event.target.value;
    setDisabledFields({amount:false,percentage:amountValue.length>1});
  }

  const handlePercentageChange = (event) => {
    const percentageValue = event.target.value;
    setDisabledFields({amount:percentageValue.length > 1,percentage:false})
  }


  useEffect(() => {
    if (chargeSuccess && Array.isArray(chargeDetails) && chargeDetails.length > 0 && categoriesSuccess) {
      const firstCharge = chargeDetails[0];
      const category = findCategoryWithSubcategories(
        categories,
        firstCharge.catId
      );

      setDisabledFields({
        amount : firstCharge?.amount === 0,
        percentage : firstCharge?.percentage === 0,
      })

      reset({
        chargeDesc: firstCharge?.chargeDesc,
        catId: firstCharge?.catId,
        amount: firstCharge?.amount === 0 ? '' : firstCharge?.amount,
        percentage: firstCharge?.percentage === 0 ? '' : firstCharge?.percentage,
        
      });

      setCatName(
        category?.parentCat
          ? category.parentCat.catNam
          : category?.catName || "" 
      );
      setSelectedCat(firstCharge?.catId);
      setValue("catId", category?.catName || "");
    
     
    }
  }, [chargeDetails, chargeSuccess, categories, categoriesSuccess, reset, setCatName, setSelectedCat , setValue]);

  const findCategoryWithSubcategories = (categoriesArray, targetCatId) => {
    for (const cat of categoriesArray) {
      if (cat.catId === targetCatId) {
        return cat;
      }
      if (cat.subCategories && cat.subCategories.length > 0) {
        const subcategory = findCategoryWithSubcategories(cat.subCategories, targetCatId);
        if (subcategory) {
          return subcategory;
        }
      }
    }
    return null;
  };
  
  const [updateCharge] = useUpdateChargeMutation();

  const onSubmit = async (data) => {

    if(disabledFields.amount){
      data.amount = 0;
    }

    if(disabledFields.percentage){
      data.percentage = 0;
    }

    const formData ={
        ...data,
        chargeId : charge.selectedCharge,
        catId : selectedCat || 0,
    };

    try{
        await updateCharge(formData).unwrap();
        toast.success("Charge successfully updated!", {position:'bottom-right'});
    }catch(err){
        console.error("Failed to save the charge" , err);
        toast.error("Error, something went wrong!", {position:'bottom-right'});
    }

    handleClose();
  };

  const onKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const clearCatgeory = () => {
    setSelectedCat(undefined);
    setCatName('');
  };

  const handleBackSpace = (event) => {
    if(event.key === 'Backspace' && selectedCat){
      clearCatgeory();
    }
  }

 

  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography
            fontWeight={600}
            className={styles.dialogTitle}
            variant="h6"
          >
            Edit Charge
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <Close/>
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Charge Description :</label>
          {errors.chargeDesc && errors.chargeDesc.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("chargeDesc")}
            className={styles.formInput}
            placeholder="Enter Charge Description"
            size="small"
            fullWidth
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formLabel}>Category:</label>
          {selectedCat === "" && <span className={styles.validation}> *</span>}
         <OutlinedInput
            {...register('catId')}
            className={styles.formInput}
            placeholder="Select Category"
            size="small"
            onClick={() => setOpenCatList(true)}
            onKeyDown={handleBackSpace}
            autoComplete="off"
            fullWidth
            value={catName}
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Amount :</label>
          {errors.amount && errors.amount.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput {...register('amount')}
            className={styles.formInput}
            placeholder="Enter Amount"
            size="small"
            autoComplete="off"
            onKeyDown={onKeyDown}
            disabled={disabledFields.amount}
            onChange={handleAmountChange}
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Percentage :</label>
          {errors.percentage && errors.percentage.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput {...register('percentage')}
            className={styles.formInput}
            placeholder="Enter Percentage"
            size="small"
            autoComplete="off"
            onKeyDown={onKeyDown}
            disabled={disabledFields.percentage}
            onChange={handlePercentageChange}
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>

      <CategoryList
        open={openCatList}
        onClose={() => setOpenCatList(false)}
        setSelectedCat={setSelectedCat}
        selectedCat={selectedCat}
        setCatName={setCatName}
        onlySubCategories={true}
      />
    

    </div>
    
  );
};


export default EditCharge;