import { useSelector, useDispatch } from "react-redux";
import { useDeleteChargeMutation } from "../../Store/Services/ChargeService";
import { closeChargeModal } from "../../Store/Slices/chargeSlice";
import DeletePopUp from "../Shared/DeletePopUp"
import { toast } from "react-toastify";



const DeleteCharge = () => {
    const dispatch = useDispatch();
    const charge = useSelector((charge) => charge.charge);
  
    const [deleteCharge] = useDeleteChargeMutation();
  
    const onDeleteConfirm = async () => {
      const id = charge.selectedCharge;
      try {
        const response = await deleteCharge(id);
        const deletedCharge = response.data;
        toast.success("Charge successfully deleted!", {
          position: "bottom-right",
        });
      } catch (err) {
        console.error("Failed to delete charge: ", err);
        toast.error("Error, something went wrong!", { position: "bottom-right" });
      }
      dispatch(closeChargeModal());
    };
  
    return (
      <DeletePopUp
        data="Charge"
        close={() => dispatch(closeChargeModal())}
        onDeleteConfirm={onDeleteConfirm}
      />
    );
};

export default DeleteCharge;