import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  Button,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  FormControlLabel
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useCreateCalMutation } from "../../Store/Services/CalService";
import { useForm } from "react-hook-form";

import styles from "./AddCal.module.css";

const schema = yup
  .object({
    availability: yup.string().required(""),
  })
  .required();

const AddCal = ({ handleClose, selectedDates }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [CreateCal] = useCreateCalMutation();

  useEffect(() => {
    setValue("availability", ""); 
  }, [setValue]);

  const onSubmit = async (data) => {
    let availabilityValue;
    if (data.availability === "available") {
      availabilityValue = 1;
    } else {
      availabilityValue = 0;
    }

    const dataSet = {
      days: selectedDates,
      availability: availabilityValue,
    };

    try {
      const respond = await CreateCal(dataSet).unwrap();
      toast.success("Dates successfully added!", { position: "bottom-right" });
    } catch (err) {
      console.error("Failed to save cal: ", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }

    handleClose();
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Add Dates
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItem}>
          {errors.availability && errors.availability.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <RadioGroup
            {...register("availability")}
            className={styles.formInput}
            row
          >
            <FormControlLabel
              value="available"
              control={<Radio color="primary" />}
              label="Available"
              {...register("availability")}
            />
            <FormControlLabel
              value="notAvailable"
              control={<Radio color="primary" />}
              label="Not Available"
              {...register("availability")}
            />
          </RadioGroup>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddCal;
