import React from "react";
import {
  Paper,
  Typography,
  IconButton,
  OutlinedInput,
  Button,
  FormControlLabel,
  Rating,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import styles from "./Bookings.module.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import RequestDetails from "../Components/Shared/RequestDetails";

const BookingDetails = () => {
  const { reqId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    navigate("/profile/requests");
  };

  let pageTitle = "";
  if (location.state.type === "UPCOMING") {
    pageTitle = "Upcoming Bookings";
  } else if (location.state.type === "PAST") {
    pageTitle = "Past Bookings";
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <IconButton onClick={handleGoBack} className={styles.backButton}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          className={styles.pageTitle}
          fontWeight={600}
          variant={"h5"}
        >
          {pageTitle}
        </Typography>
      </div>
      <Paper className={styles.paper}>
        <RequestDetails />
        {/* <div className={styles.requestDetailsContainer}>
          <form>
            <Typography
              className={styles.subTitle}
              fontWeight={600}
              variant={"body1"}
            >
              Reviews
            </Typography>

            <div style={{ marginTop: "1rem", display: "inline-flex" }}>
              <OutlinedInput hidden style={{ display: "none" }} />
              <label style={{ fontWeight: 600, paddingRight: "2rem" }}>
                Ratings:
              </label>
              <FormControlLabel
                control={
                  <>
                    <input type="number" hidden readOnly />
                    <Rating size="large" precision={1} />
                  </>
                }
              />
            </div>
            <div style={{ marginTop: "1rem" }}>
              <label className={styles.commentTitle}>Your Review:</label>
              <div className={styles.listItem}>
                <OutlinedInput
                  className={styles.formInput}
                  multiline
                  rows={3}
                  size="small"
                  fullWidth
                />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                className={styles.submitButton}
                variant="contained"
              >
                Add Review
              </Button>
            </div>
          </form>{" "}
        </div> */}
      </Paper>
    </div>
  );
};

export default BookingDetails;
