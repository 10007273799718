import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import SearchBar from "../Shared/SearchBar";
import {
  useGetAllRequestsStatusQuery,
  useGetRequestsByStatusQuery,
} from "../../Store/Services/RequestService";
import RequestList from "./RequestList";

const TabsView = () => {
  const [value, setValue] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const { data: requestStatus } = useGetAllRequestsStatusQuery();
  const {
    data: requestByStatus,
    isSuccess,
    isLoading,
  } = useGetRequestsByStatusQuery(value + 1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (reqId) => {
    setIsDialogOpen(true);
  };

  const reqStatus = requestStatus?.[value]?.reqStatus;

  return (
    <Box sx={{ width: "100%", typography: "body1", paddingTop: "2rem" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ marginLeft: '-25px' }}
      >
        {requestStatus &&
          requestStatus.map((status, index) => (
            <Tab key={status.reqStatusId} label={status.reqStatus} />
          ))}
      </Tabs>

      {requestByStatus ?
        <>
          <Box my={2}>
            <SearchBar
              data={requestByStatus || []}
              setSearchData={setSearchData}
              text="Search Requests"
              search="request"
            />
          </Box>
          <RequestList data={searchData} status={reqStatus} />
        </>
      :
        <div style={{padding: '2rem'}}>
          No requests available.
        </div>
      }
    </Box>
  );
};

export default TabsView;