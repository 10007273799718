import { useSelector, useDispatch } from "react-redux";
import { useDeleteBrMutation } from "../../Store/Services/BrService";
import { closeBrModal } from "../../Store/Slices/brSlice";
import DeletePopUp from "../Shared/DeletePopUp";


const DeleteBr = ({handleClose}) => {
    const dispatch = useDispatch();
    const {selectedBr} = useSelector((state) => state.br);

    const [deleteBr] = useDeleteBrMutation();

    const onDeleteConfirm = async () => {
        try{
            await deleteBr(selectedBr).unwrap();
        }catch(err){
            console.error('Failed to delete the Role',err);
        }
        dispatch(closeBrModal());
    };

    return(
        <DeletePopUp
               data="Role"
               close={() => dispatch(closeBrModal())}
               onDeleteConfirm={onDeleteConfirm}
        />
    )
};

export default DeleteBr;