import {
    Box,
    ButtonGroup,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import { Delete, Edit } from "@mui/icons-material";
  import { useDispatch } from "react-redux";
  import { PaginationDetails } from "../../Utils/Utils";
  import {
    openEditSlotModal,
    openDeleteSlotModal,
  } from "../../Store/Slices/slotSlice";
  
  const SlotTable = ( data ) => {
    const dispatch = useDispatch();
    
    const dataSet = Array.isArray(data.data) ? data.data : [];
    const { page, count, handleChange, _DATA } = PaginationDetails(dataSet);

    const roles = sessionStorage.getItem("roles");
  
    return (
      <>
        <TableContainer sx={{ height: "43rem" }}>
          <Table
            stickyHeader
            aria-label="simple table"
            sx={{ height: "max-content" }}
          >
            <TableHead>
              <TableRow>
                <TableCell width={50}>Slot Id</TableCell>
                <TableCell width={50}>Slot</TableCell>
                <TableCell align="center" width={"30%"}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_DATA.currentData().map((slot) => (
                <TableRow sx={{ height: "30px" }} key={slot.slotId}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      fontWeight: "bold",
                      width: 50,
                      height: "auto !important",
                    }}
                  >
                    #{slot.slotId}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      width: 100,
                      height: "auto !important",
                    }}
                  >
                    {slot.slot}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                      width: "15%",
                      height: "auto !important",
                    }}
                  >
                    <ButtonGroup variant="text" aria-label="text button group">
                      {roles?.split(",").includes("PRIV_ES_EDIT_SLOT") ? (<IconButton
                        onClick={() => {
                          dispatch(openEditSlotModal(slot.slotId));
                        }}
                      >
                        <Edit />
                      </IconButton>) : null}
                      {roles?.split(",").includes("PRIV_ES_DELETE_SLOT") ? (<IconButton
                        onClick={() => {
                          dispatch(openDeleteSlotModal(slot.slotId));
                        }}
                      >
                        <Delete />
                      </IconButton>) : null}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box my={2} display="flex" justifyContent="center">
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            color="primary"
            onChange={handleChange}
          />
        </Box>
      </>
    );
  };
  
  export default SlotTable;
  