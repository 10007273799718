import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useAddPrivMutation } from "../../Store/Services/PrivilegeService";
import styles from "./Privilege.module.css";
import {toast} from 'react-toastify';


const schema = yup.object({
    privName : yup.string().required(""),
});

const AddPrivilege = ({handleClose}) => {
    const {register,handleSubmit,formState:{errors}} = useForm({resolver:yupResolver(schema)});
    const [addPrivilege] = useAddPrivMutation();

    const submit = async (data) => {
        try{
            await addPrivilege(data).unwrap();
            toast.success("Privilege added successfully !",{position:'bottom-right'});
            handleClose();
        }catch(err){
            console.error(err);
            toast.error("Error, Something went wrong",{position:'bottom-right'});
        }
    };

    return (
        <div className={styles.addCouponContainer}>
          <div className={styles.headingContainer}>
            <div className={styles.dialogHeader}>
              <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
                Add Privilege
              </Typography>
            </div>
            <div className={styles.close}>
              <IconButton className={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
    
          <form onSubmit={handleSubmit(submit)} autoComplete="off">
            <div className={styles.formItem}>
              <label className={styles.formLabel}> Privilege Name :</label>
              {errors.privName && errors.privName.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput
                {...register("privName")}
                className={styles.formInput}
                placeholder="Enter Privilege Name"
                size="small"
                fullWidth
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                className={styles.submitButton}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      );
};


export default AddPrivilege;