import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import {
  useUpdatePrivMutation,
  useGetPrivByIdQuery,
} from "../../Store/Services/PrivilegeService";
import {  useSelector } from "react-redux";
import styles from "./Privilege.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Edit } from "@mui/icons-material";


const schema = yup.object().shape({
    privName : yup.string().required(""),
});

const EditPrivilege = ({handleClose}) => {
    const priv = useSelector((state) => state.priv);

    let privilegeData = useMemo(() => {
        return {id:''}
    },[]);

    const {data:privilegeDetails,isSuccess:privilegeSuccess,isLoading:privilegeLoading} = useGetPrivByIdQuery(priv.selectedPriv);

    if(privilegeSuccess){
        privilegeData = privilegeDetails?.privilegeList?.[0];
    }

    const {register , handleSubmit,reset,formState:{errors}} = useForm({resolver:yupResolver(schema)});

    useEffect(() => {
        if(privilegeSuccess && Array.isArray(privilegeDetails) && privilegeDetails.length > 0){
            const firstPrivilege = privilegeDetails[0];
            reset({
                privName : firstPrivilege?.privName,
            });
        }
    },[privilegeDetails,privilegeSuccess,reset]);

    const [updatePrivilege] = useUpdatePrivMutation();

    const submit = async(data) => {
        const formData = {
            ...data,
            privId : priv.selectedPriv
        };
        try{
            await updatePrivilege(formData).unwrap();
            toast.success("Privilege updated successfully!", {position:'bottom-right'});
        }catch(err){
            console.error("Failed to save privilege",err);
            toast.error("Error,something went wrong !",{position:'bottom-right'});
        }
        handleClose();
    }

    return (
        <div className={styles.addCouponContainer}>
          <div className={styles.headingContainer}>
            <div className={styles.dialogHeader}>
              <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
                Edit Privilege
              </Typography>
            </div>
            <div className={styles.close}>
              <IconButton className={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
    
          <form onSubmit={handleSubmit(submit)} autoComplete="off">
            <div className={styles.formItem}>
              <label className={styles.formLabel}> Privilege Name :</label>
              {errors.privName && errors.privName.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput
                {...register("privName")}
                className={styles.formInput}
                placeholder="Enter Privilege Name"
                size="small"
                fullWidth
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                className={styles.submitButton}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      );
}


export default EditPrivilege;