import {createSlice} from '@reduxjs/toolkit';


export const noOfResourceSlice = createSlice({
    name : "res",
    initialState:{
        modalStatus : null,
        selectedRes : null
    },
    reducers : {
        openAddNoOfResourceModal : (state) => {
            state.modalStatus = "add";
        },
        openEditNoOfResourceModal : (state , action) => {
            state.modalStatus = "edit";
            state.selectedRes  = action.payload;
        },
        openDeleteNoOfResourceModal : (state,action) => {
            state.modalStatus = "delete";
            state.selectedRes  = action.payload;
        },
        closeNoOfResourceModal : (state) => {
            state.modalStatus = null;
            state.selectedRes  = null;
        }
    }
});


export const {
    openAddNoOfResourceModal,
    openEditNoOfResourceModal,
    openDeleteNoOfResourceModal,
    closeNoOfResourceModal
} = noOfResourceSlice.actions;


export default noOfResourceSlice.reducer;