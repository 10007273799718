import {createSlice} from '@reduxjs/toolkit';



export const couponSlice = createSlice({
    name :"coupon",
    initialState:{
        modalStatus : null,
        selectedCoupon:null
    },
    reducers:{
        openAddCouponModal : (state) => {
            state.modalStatus = "add";
        },
        openEditCouponModal : (state,action) => {
            state.modalStatus = "edit";
            state.selectedCoupon = action.payload;
        },
        openDeleteCouponModal : (state , action) => {
            state.modalStatus = "delete";
            state.selectedCoupon = action.payload;
        },
        closeCouponModal : (state) => {
            state.modalStatus = null;
            state.selectedCoupon = null;
        }
    }
});


export const {
    openAddCouponModal,
    openDeleteCouponModal,
    openEditCouponModal,
    closeCouponModal
} = couponSlice.actions;


export default couponSlice.reducer;