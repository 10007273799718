import {
  Box,
  Button,
  Container,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import styles from "./Management.module.css";
import TabsView from "../Components/Requests/TabsView";

const RequestManagement = () => {

  const roles = sessionStorage.getItem("roles");

  const refreshPage = () => {
    window.location.reload();
  }

  return (
    roles?.split(",").includes("PRIV_ES_DISPLAY_REQUESTS") ? (
    <Paper elevation={3} className={styles.pageContainer} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/thumbnail_Management.png)`}}>
      <Box>
        <Toolbar className={styles.subNavBar}>
          <Typography className={styles.topic}>Request Management</Typography>
          <Button variant="contained" color="primary" className={styles.buttonItem} onClick={refreshPage} > Refresh </Button>
        </Toolbar>
      </Box>
      <Container>
        <TabsView />
      </Container>

    </Paper> ) : null
  );
};

export default RequestManagement;
