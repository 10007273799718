import React, { useState } from "react";
import { Delete, Edit, Event } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ButtonGroup,
  IconButton,
  Pagination,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  openDeleteResourceModal,
  openEditResourceModal,
  openAvailabilityResourceModal,
} from "../../Store/Slices/resourceSlice";
import { PaginationDetails } from "../../Utils/Utils";
import styles from "./ResourcrList.module.css";

const ResourceList = (data) => {
  const dispatch = useDispatch();

  const dataSet = Array.isArray(data.data) ? data.data : [];
  const { page, count, handleChange, _DATA } = PaginationDetails(dataSet);

  const roles = sessionStorage.getItem("roles");

  return (
    <>
      <div className={styles.container}>
        {_DATA.currentData()?.map((resource) => (
          <div key={resource.resourceId} className={styles.row}>
            <div className={styles.subContainer}>
              <div className={styles.imageContainer}>
                <Avatar
                  src={
                    resource.pic
                      ? `${process.env.REACT_APP_IMAGE_URL}${resource.pic}`
                      : `${process.env.REACT_APP_IMAGE_URL}Default/User_Avatar.jpg`
                  }
                />
              </div>
              <div className={styles.textContainer}>
                <Typography className={styles.text1}>
                  {resource.name}
                </Typography>
                <Typography className={styles.text2}>
                  {resource.email}
                </Typography>
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <ButtonGroup variant="text" aria-label="text button group">
                <IconButton
                  onClick={() => {
                    dispatch(
                      openAvailabilityResourceModal(resource.resourceId)
                    );
                  }}
                >
                  <Event />
                </IconButton>

                {roles?.split(",").includes("PRIV_ES_EDIT_RESOURCE") ?  (<IconButton
                  onClick={() => {
                    dispatch(openEditResourceModal(resource.resourceId));
                  }}
                >
                  <Edit />
                </IconButton>) : null}
                {roles?.split(",").includes("PRIV_ES_DELETE_RESOURCE") ?  (<IconButton
                  onClick={() => {
                    dispatch(openDeleteResourceModal(resource.resourceId));
                  }}
                >
                  <Delete />
                </IconButton>) : null}
              </ButtonGroup>
            </div>
          </div>
        ))}
      </div>
      <Box my={2} display="flex" justifyContent="center">
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          color="primary"
          onChange={handleChange}
          className={styles.pagination}
        />
      </Box>
    </>
  );
};

export default ResourceList;
