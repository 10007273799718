import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { closeNoOfResourceModal } from "../../Store/Slices/noOfResourceSlice"
import AddNoOfResource from "./AddNoOfResource";
import EditNoOfResource from "./EditNoOfResource";
import DeleteNoOfResource from "./DeleteNoOfResource";


const NoOfResDialog = () => {
    const dispatch = useDispatch();
    const {modalStatus} = useSelector((state) => state.res);

    let content;
    let deleteView = false;

    const roles = sessionStorage.getItem("roles");

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            dispatch(closeNoOfResourceModal());
        }
    };

    switch (modalStatus) {
        case "add":
            content = roles?.split(",").includes("PRIV_ES_ADD_NO_RES") ? <AddNoOfResource handleClose={handleClose} /> : null;
            break;
        case "edit":
            content =  roles?.split(",").includes("PRIV_ES_EDIT_NO_RES") ? <EditNoOfResource handleClose={handleClose} /> : null;
            break;
        case "delete":
            deleteView = true;
            content = roles?.split(",").includes("PRIV_ES_DELETE_NO_RES") ? <DeleteNoOfResource handleClose={handleClose} /> : null;
            break;
        default:
            content = null;
    };

    return (
        <Dialog
            fullWidth={true}
            open={["add", "edit", "delete"].includes(modalStatus)}
            onClose={handleClose}
            aria-labelledby="coupon-dialog-title"
            aria-describedby="coupon-dialog-description"
            
        >
            <DialogContent>{content}</DialogContent>
        </Dialog>
    );


};

export default NoOfResDialog;