import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useAddStateMutation } from "../../Store/Services/StateService";
import styles from "./AddState.module.css";
import { toast } from "react-toastify";

const schema = yup.object({
  state: yup.string().required("State is required"),
});

const AddState = ({ handleClose }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [addState] = useAddStateMutation();

  const submit = async (data) => {
    try {
      await addState(data).unwrap();
      handleClose();
      toast.success("State added successfully !", { position: "bottom-right" });
    } catch (err) {
      toast.error("Error , Something went wrong", { position: "bottom-right" });
    }
  };

  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography
            fontWeight={600}
            className={styles.dialogTitle}
            variant="h6"
          >
            Add State
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(submit)} autoComplete="off">
        <div className={styles.formItem}>
          <label className={styles.formLabel}> State :</label>
          {errors.state && errors.state.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("state")}
            className={styles.formInput}
            placeholder="Enter State"
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddState;
