import {
  Typography,
  TextField,
  Button,
  IconButton,
  Autocomplete,
  FormHelperText
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useSelector} from "react-redux";
import { toast } from "react-toastify";
import {
  useUpdateUserMutation,
  useGetUserByIdQuery,
} from "../../Store/Services/UserService";
import { object, string, yup } from "yup";
import * as Yup from "yup";
import { useGetUserBrQuery } from "../../Store/Services/UserService";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./AddUser.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const schema = object({
  firstName: Yup.string().required(""),
  lastName: Yup.string().required(""),
  email : string().trim().email("Please provide a valid email").required('Email is required'),
  userName: Yup.string().required(""),
}).required();

const EditUser = ({ handleClose }) => {

  const item = useSelector((state) => state.user.selectedUser);
 
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm({ 
    resolver: yupResolver(schema) });

  const {
    data: brDatas,
    isSuccess: brSuccess,
    isLoading: brLoading,
  } = useGetUserBrQuery();

  const [userUpdateResponse] = useUpdateUserMutation();

  const {
    data: userDetails,
    isSuccess: userSuccess,
    isLoading: userLoading,
  } = useGetUserByIdQuery(item);

  const [userBr, setUserBr] = useState([]);
  const [deletedBr, setDeletedBr] = useState([]);
  const [newUserBr, setNewUserBr] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState([]);

  useEffect(() => {
    if (userSuccess && userDetails?.length > 0) {
      const firstUser = userDetails[0];

      const userBrIds = firstUser?.roles?.map((role) => role.brId) || [];
      const selectedUserRoles = brDatas.filter((role) =>
        userBrIds.includes(role.brId)
      );

      reset({
        firstName: firstUser?.firstName,
        lastName: firstUser?.lastName,
        email: firstUser?.email,
        userName: firstUser?.userName,
        userBr: selectedUserRoles,
      });
      setSelectedUserRole(selectedUserRoles);
    }
  }, [userDetails, userSuccess, reset]);

  const onSubmit = async (data) => {

    const newRolesIds = newUserBr.map((br) => br.brId); 
    const deletedRolesIds = deletedBr.map((br) => br.brId);

    const formData = {
      userId: item,
      newRoles: newRolesIds,
      deletedRoles: deletedRolesIds,
      ...data,
    };
    try {
      const updateUser = await userUpdateResponse(formData).unwrap();
      toast.success("User successfully updated!", { position: "bottom-right" });
    } catch (err) {
      console.error("Failed to save the user: ", err);
      toast.error("Erorr, something went wrong!", { position: "bottom-right" });
    }
    handleClose();
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Edit User
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {/* first name */}
        <div className={styles.formItem}>
          <label className={styles.formLabel}> First Name :</label>
          {errors.firstName && errors.firstName.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <TextField
            {...register("firstName")}
            className={styles.formInput}
            placeholder="Enter FirstName"
            size="small"
            fullWidth
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formLabel}> Last Name :</label>
          {errors.lastName && errors.lastName.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <TextField
            {...register("lastName")}
            className={styles.formInput}
            placeholder="Enter LastName"
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> User Name :</label>
          {errors.userName && errors.userName.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <TextField
            {...register("userName")}
            className={styles.formInput}
            placeholder="Enter Username"
            size="small"
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}>Email : </label>
          <TextField
            {...register("email")}
            className={styles.formInput}
            placeholder="Enter Email"
            size="small"
            fullWidth
          />
           {errors.email && <FormHelperText error> {errors.email?.message} </FormHelperText> }
        </div>

        <div style={{ padding: "10px 0 10px 0" }}>
          <label className={styles.formLabel}>User Role:</label>
          {/* {userBr.length === 0 && <span style={{ color: "red" }}> *</span>} */}
          <Controller
            name="userBr"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                {...field}
                options={brDatas || []}
                multiple
                filterSelectedOptions
                getOptionLabel={(option) => option.brName}
                isOptionEqualToValue={(option, value) =>
                  option.brId === value.brId
                }
                value={selectedUserRole || []}
                onChange={(_, newBr, reason, detail) => {
                  if (reason === "removeOption") {
                    // Remove the deleted role from selectedUserRole
                    setSelectedUserRole((prev) =>
                      prev.filter((role) => role.brId !== detail.option.brId)
                    );
                    // Add the deleted role to deletedBr
                    setDeletedBr((prev) => [...prev, detail.option]);
                  } else if (reason === "selectOption") {
                    setNewUserBr((prev) => [...prev, detail.option]);
                    setSelectedUserRole((prev) => [...prev, detail.option]);
                  }
                  setUserBr(
                    newBr.map((br) => ({
                      brId: br.brId,
                      brName: br.brName,
                      userDeleteId: br.userDeleteId,
                    }))
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            )}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button type="submit" variant="contained" className={styles.button}>
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditUser;
