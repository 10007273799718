import { createSlice } from '@reduxjs/toolkit';

export const slotSlice = createSlice({
  name: "slot",
  initialState: {
    modalStatus: false,
    selectedSlot: null
  },
  reducers: {
    openAddSlotModal: (state) => {
      state.modalStatus = "add";
    },
    openEditSlotModal: (state, action) => {
      state.modalStatus = "edit";
      state.selectedSlot = action.payload;
    },
    openDeleteSlotModal: (state, action) => {
      state.modalStatus = "delete";
      state.selectedSlot = action.payload;
    },
    closeSlotModal: (state) => {
      state.modalStatus = false;
      state.selectedSlot = null;
    }
  }
});

export const {
  openAddSlotModal,
  openEditSlotModal,
  openDeleteSlotModal,
  closeSlotModal
} = slotSlice.actions;

export default slotSlice.reducer;