// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bookings_container__IBQcd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Bookings_paper__ofece {
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(89, 124, 215, 0.25);
  background: linear-gradient(
    91deg,
    rgba(222, 248, 248, 0.32) 23.22%,
    rgba(222, 248, 248, 0) 99.28%
  );
  width: 100%;
  margin: 1rem;
  overflow: auto;
  max-width: 45rem;
}

.Bookings_backButton__HwZ4c {
  align-self: flex-start;
  padding: 0.2rem;
  border: 1px #47C2BB solid;
  color: #47C2BB;
}

.Bookings_header__DxzOb {
    display: flex;
    justify-content: space-between; 
    margin-bottom: 2rem;
    margin-top: 4rem;
    width: 50rem;
    flex-direction: row;

  }
  
  .Bookings_pageTitle__7jpZN {
    flex-grow: 1;
    margin-left: 2rem;
    color: #47C2BB;

  }

  .Bookings_requestDetailsContainer__8xCI- {
    padding: 1rem;
    margin: 0;
    border: 1px solid rgba(71, 194, 187, 0.5);
    border-radius: 0.5rem;
    margin: 0.5rem 0 0 0;
    width: 100%;
    margin: 1rem;
    overflow: auto;
    max-width: 40rem;
  }
`, "",{"version":3,"sources":["webpack://./src/Pages/Bookings.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qDAAqD;EACrD;;;;GAIC;EACD,WAAW;EACX,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,yBAAyB;EACzB,cAAc;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;;EAErB;;EAEA;IACE,YAAY;IACZ,iBAAiB;IACjB,cAAc;;EAEhB;;EAEA;IACE,aAAa;IACb,SAAS;IACT,yCAAyC;IACzC,qBAAqB;IACrB,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,cAAc;IACd,gBAAgB;EAClB","sourcesContent":[".container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.paper {\r\n  padding: 2rem;\r\n  border-radius: 10px;\r\n  box-shadow: 0px 4px 10px 0px rgba(89, 124, 215, 0.25);\r\n  background: linear-gradient(\r\n    91deg,\r\n    rgba(222, 248, 248, 0.32) 23.22%,\r\n    rgba(222, 248, 248, 0) 99.28%\r\n  );\r\n  width: 100%;\r\n  margin: 1rem;\r\n  overflow: auto;\r\n  max-width: 45rem;\r\n}\r\n\r\n.backButton {\r\n  align-self: flex-start;\r\n  padding: 0.2rem;\r\n  border: 1px #47C2BB solid;\r\n  color: #47C2BB;\r\n}\r\n\r\n.header {\r\n    display: flex;\r\n    justify-content: space-between; \r\n    margin-bottom: 2rem;\r\n    margin-top: 4rem;\r\n    width: 50rem;\r\n    flex-direction: row;\r\n\r\n  }\r\n  \r\n  .pageTitle {\r\n    flex-grow: 1;\r\n    margin-left: 2rem;\r\n    color: #47C2BB;\r\n\r\n  }\r\n\r\n  .requestDetailsContainer {\r\n    padding: 1rem;\r\n    margin: 0;\r\n    border: 1px solid rgba(71, 194, 187, 0.5);\r\n    border-radius: 0.5rem;\r\n    margin: 0.5rem 0 0 0;\r\n    width: 100%;\r\n    margin: 1rem;\r\n    overflow: auto;\r\n    max-width: 40rem;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Bookings_container__IBQcd`,
	"paper": `Bookings_paper__ofece`,
	"backButton": `Bookings_backButton__HwZ4c`,
	"header": `Bookings_header__DxzOb`,
	"pageTitle": `Bookings_pageTitle__7jpZN`,
	"requestDetailsContainer": `Bookings_requestDetailsContainer__8xCI-`
};
export default ___CSS_LOADER_EXPORT___;
