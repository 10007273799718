// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ceoMsg_container__qumxy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ceoMsg_image__wTxn6 {
  border-radius: 0 3rem;
}

.ceoMsg_topic__qcaDH {
  color: #414141;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; 
  margin-bottom: 30px;
}

.ceoMsg_ceo_msg__yuP9X {
  width: 580px;
  height: 463px;
  flex-shrink: 0;
  color: #000;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; 
}

@media (min-width: 769px) {
  .ceoMsg_mobileImage__IEzF-{
    display: none;
  }
}

@media (max-width: 770px) {
  .ceoMsg_container__qumxy {
    flex-direction: column;
  }

  .ceoMsg_image__wTxn6 {
    display: none;
  }

  .ceoMsg_ceo_msg__yuP9X{
    width: 100%;
  }

  .ceoMsg_ceoMsgContainer__t\\+4vA {
    height: 16rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ceoMsg_mobileImage__IEzF- {
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    justify-content: center;
    display: flex;
    margin: auto;
    margin-bottom: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/aboutUs/ceoMsg.module.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;EACd,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,uBAAuB;EACzB;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,YAAY;IACZ,mBAAmB;EACrB;AACF","sourcesContent":["\r\n.container {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  flex-wrap: wrap;\r\n}\r\n\r\n.image {\r\n  border-radius: 0 3rem;\r\n}\r\n\r\n.topic {\r\n  color: #414141;\r\n  font-size: 48px;\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  line-height: 120%; \r\n  margin-bottom: 30px;\r\n}\r\n\r\n.ceo_msg {\r\n  width: 580px;\r\n  height: 463px;\r\n  flex-shrink: 0;\r\n  color: #000;\r\n  text-align: justify;\r\n  font-size: 14px;\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  line-height: 150%; \r\n}\r\n\r\n@media (min-width: 769px) {\r\n  .mobileImage{\r\n    display: none;\r\n  }\r\n}\r\n\r\n@media (max-width: 770px) {\r\n  .container {\r\n    flex-direction: column;\r\n  }\r\n\r\n  .image {\r\n    display: none;\r\n  }\r\n\r\n  .ceo_msg{\r\n    width: 100%;\r\n  }\r\n\r\n  .ceoMsgContainer {\r\n    height: 16rem;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n  }\r\n\r\n  .mobileImage {\r\n    height: 9rem;\r\n    width: 9rem;\r\n    border-radius: 6rem;\r\n    justify-content: center;\r\n    display: flex;\r\n    margin: auto;\r\n    margin-bottom: 1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ceoMsg_container__qumxy`,
	"image": `ceoMsg_image__wTxn6`,
	"topic": `ceoMsg_topic__qcaDH`,
	"ceo_msg": `ceoMsg_ceo_msg__yuP9X`,
	"mobileImage": `ceoMsg_mobileImage__IEzF-`,
	"ceoMsgContainer": `ceoMsg_ceoMsgContainer__t+4vA`
};
export default ___CSS_LOADER_EXPORT___;
