import { apiSlice } from "../Slices/apiSlice";


export const ChargeService = apiSlice.injectEndpoints({
    endpoints : builder => ({
        getAllCharges : builder.query({
            query : () => '/charges',
            providesTags : ['charge']
        }),

        getChargeById : builder.query({
            query : (id) => `/charges/${id}`,
            providesTags:['charge']
        }),

        addCharge : builder.mutation({
            query : (chargeDetails) => ({
                url : '/charges',
                method : 'POST',
                body : chargeDetails
            }),
            invalidatesTags:['charge']
        }),

        updateCharge : builder.mutation({
            query : (chargeDetails) => ({
                url : `/charges/${chargeDetails.chargeId}`,
                method:'PUT',
                body:chargeDetails
            }),
            invalidatesTags:['charge']
        }),

        deleteCharge : builder.mutation({
            query : (id) => ({
                url : `/charges/${id}`,
                method:'DELETE',
            }),
            invalidatesTags:['charge']
        })


    })
});


export const {
    useGetAllChargesQuery,
    useGetChargeByIdQuery,
    useAddChargeMutation,
    useUpdateChargeMutation,
    useDeleteChargeMutation,
} = ChargeService;