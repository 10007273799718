import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { useUpdateHrsMutation } from "../../Store/Services/NoOfHrsService";
import { useGetHrsByIdQuery } from "../../Store/Services/NoOfHrsService";
import { useSelector } from "react-redux";
import styles from "./Hours.module.css";

const schema = yup.object().shape({
  noHrs: yup.string().required(),
});

const EditHours = ({ handleClose }) => {
  const hrs = useSelector((state) => state.hrs);

  let hrsData = useMemo(() => {
    return { id: "" };
  }, []);

  const {
    data: noOfHrsDetails,
    isSuccess: noOfHrsSuccess,
    isLoading: noOfHrsLoading,
  } = useGetHrsByIdQuery(hrs.selectedHrs);

  if (noOfHrsSuccess) {
    hrsData = noOfHrsDetails?.noOfHrsList?.[0];
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (
      noOfHrsSuccess &&
      Array.isArray(noOfHrsDetails) &&
      noOfHrsDetails.length > 0
    ) {
      const firstNoOfHrs = noOfHrsDetails[0];
      reset({
        noHrs: firstNoOfHrs?.noHrs,
      });
    }
  }, [noOfHrsDetails, noOfHrsSuccess, reset]);


  const [updateHrs] = useUpdateHrsMutation();

  const submit = async (data) => {
    const formData = {
        ...data,
        noHrsId:hrs.selectedHrs
    };

    try{
        await updateHrs(formData).unwrap();
        toast.success("No Of Hours successfully updated!", {position:'bottom-right'});
    }catch(err){
        console.error(err);
        toast.error("Error, something went wrong!", {position:'bottom-right'})
    }

    handleClose();

    };

    const onKeyDown = (event) => {
        if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
          event.preventDefault();
        }
    };

    return (
        <div className={styles.addCouponContainer}>
          <div className={styles.headingContainer}>
            <div className={styles.dialogHeader}>
              <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
                Edit Number Of Hours
              </Typography>
            </div>
            <div className={styles.close}>
              <IconButton className={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
     
          <form onSubmit={handleSubmit(submit)}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}> No Of Hours :</label>
              {errors.noHrs && errors.noHrs.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput {...register("noHrs")}
                className={styles.formInput}
                size="small"
                onKeyDown={onKeyDown}
                autoComplete="off"
                fullWidth
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                className={styles.submitButton}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      );

};

export default EditHours;






