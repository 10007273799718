import React from "react";
import {
  Typography,
  OutlinedInput,
  Button,
  FormControlLabel,
  Rating,
  Divider,
  Grid,
} from "@mui/material";

import styles from "./RequestDetails.module.css";
import { useGetRequestByIdQuery } from "../../Store/Services/RequestService";
import { useParams } from "react-router-dom";

const RequestDetails = ({ type }) => {
  const { reqId } = useParams();


  const { data: requestData } = useGetRequestByIdQuery(reqId);

  return (
    requestData &&
    requestData.length > 0 && (
      <div className={styles.container}>
        <div className={styles.requestDetailsContainer}>
          <div className={styles.headingTopContainer}>
            <Typography
              className={styles.bookingTitle}
              fontWeight={600}
              variant={"h5"}
            >
              {requestData[0].catName}
            </Typography>

            <Typography className={styles.status}>
              {requestData[0].reqStatus}
            </Typography>
          </div>
          <div className={styles.headingContainer}>
            {requestData[0].freqName && (
              <label>({requestData[0].freqName})</label>
            )}
          </div>
          <div className={styles.headingContainer}>
            {requestData[0].location && (
              <label className={styles.titleLabel}>
                {requestData[0].location}
              </label>
            )}
          </div>
          <div className={styles.headingContainer}>
            {(requestData[0].addressLine1 ||
              requestData[0].addressLine2 ||
              requestData[0].addressLine3) && (
              <label className={styles.titleLabel}>
                {`${requestData[0].addressLine1} ${requestData[0].addressLine2} ${requestData[0].addressLine3}`}
              </label>
            )}
          </div>
        </div>

        <div className={styles.requestDetailsContainer}>
          <Typography
            className={styles.subTitle}
            fontWeight={600}
            variant={"body1"}
          >
            Price Details
          </Typography>
          <Grid container spacing={-20}>
            <Grid item xs={6}>
              <div className={styles.listItem}>
                {requestData[0].totalPrice && (
                  <label className={styles.formLabel}>Sub Total:</label>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.listItem}>
                {requestData[0].totalPrice && (
                  <label className={styles.formLabel}>
                    AED {requestData[0].totalPrice}
                  </label>
                )}
              </div>
            </Grid>
          </Grid>

          {requestData[0].charges.map((charge, index) => (
            <Grid container spacing={-20} key={index}>
              <Grid item xs={6}>
                <div className={styles.listItem}>
                  <label className={styles.formLabel}>
                    {charge.description} ({charge.chargePercentage}%):
                  </label>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.listItem}>
                  <label className={styles.formLabel}>
                    AED {charge.finalAmount}
                  </label>
                </div>
              </Grid>
            </Grid>
          ))}

          <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />

          <div className={styles.listItem}>
            {requestData[0].paymentMethod && (
              <label className={styles.formLabel}>
                Pay By {requestData[0].paymentMethod}
              </label>
            )}
          </div>
        </div>

        <div className={styles.requestDetailsContainer}>
          <Typography
            className={styles.subTitle}
            fontWeight={600}
            variant={"body1"}
          >
            Contact Details
          </Typography>
          <Grid container spacing={-20}>
            <Grid item xs={6}>
              <div className={styles.listItem}>
                {requestData[0].phone && (
                  <label className={styles.formLabel}>Phone:</label>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.listItem}>
                {requestData[0].phone && (
                  <label className={styles.formLabel}>
                    {requestData[0].phone}
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.listItem}>
                {requestData[0].mobile && (
                  <label className={styles.formLabel}>Mobile:</label>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.listItem}>
                {requestData[0].mobile && (
                  <label className={styles.formLabel}>
                    {requestData[0].mobile}
                  </label>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.listItem}>
                {requestData[0].email && (
                  <label className={styles.formLabel}>Email:</label>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.listItem}>
                {requestData[0].email && (
                  <label className={styles.formLabel}>
                    {requestData[0].email}
                  </label>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        {type === "PAST" && (
          <div className={styles.requestDetailsContainer}>
            <form>
              <Typography
                className={styles.subTitle}
                fontWeight={600}
                variant={"body1"}
              >
                Reviews
              </Typography>

              <div style={{ marginTop: "1rem", display: "inline-flex" }}>
                <OutlinedInput hidden style={{ display: "none" }} />
                <label style={{ fontWeight: 600, paddingRight: "2rem" }}>
                  Ratings:
                </label>
                <FormControlLabel
                  control={
                    <>
                      <input type="number" hidden readOnly />
                      <Rating size="large" precision={1} />
                    </>
                  }
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <label className={styles.commentTitle}>Your Review:</label>
                <div className={styles.listItem}>
                  <OutlinedInput
                    className={styles.formInput}
                    multiline
                    rows={3}
                    size="small"
                    fullWidth
                  />
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  className={styles.submitButton}
                  variant="contained"
                >
                  Add Review
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    )
  );
};

export default RequestDetails;
