import { createSlice } from "@reduxjs/toolkit";


export const brSlice = createSlice({
    name :'br',
    initialState:{
        modalStatus : null,
        selectedBr : null
    },
    reducers:{
        openAddBrModal : (state) => {
            state.modalStatus = "add";
        },
        openEditBrModal : (state,action) => {
            state.modalStatus = "edit";
            state.selectedBr = action.payload;
        },
        openDeleteBrModal : (state,action) => {
            state.modalStatus = "delete";
            state.selectedBr = action.payload;
        },
        closeBrModal : (state) => {
            state.modalStatus = null;
            state.selectedBr = null;
        }
    }
});


export const {
    openAddBrModal,
    openDeleteBrModal,
    openEditBrModal,
    closeBrModal
} = brSlice.actions;

export default brSlice.reducer;