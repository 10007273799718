// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .LoginPage_parentDiv__XnjPd {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size:100% 100%;
  }
  
  .LoginPage_paperStyle__PQ\\+BY {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 2px 2px 25px 2px rgba(0, 0, 0, 0.25);;
    width: 80%;
    margin: 5rem;
    overflow: auto; 
  }

  .LoginPage_image__-6j\\+4 {
    width: 100%;
    display: grid;
    object-fit: contain;
  }

  .LoginPage_formContainer__c4bhv {
    margin: 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .LoginPage_logo__Jl7X0 {
    height: 5.5rem;
    object-fit: contain;
  }

  @media (max-width: 700px) {
    .LoginPage_paperStyle__PQ\\+BY {
      padding: 1rem; 
      margin: 1rem; 
    }
  }

  .LoginPage_formItem__hgJEz{
    margin-top: 1rem;
  }

  .LoginPage_login__qsQeI{
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`, "",{"version":3,"sources":["webpack://./src/Pages/LoginPage.module.css"],"names":[],"mappings":";EACE;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gDAAgD;IAChD,UAAU;IACV,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE;MACE,aAAa;MACb,YAAY;IACd;EACF;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,uBAAuB;EACzB","sourcesContent":["\r\n  .parentDiv {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-size:100% 100%;\r\n  }\r\n  \r\n  .paperStyle {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    border-radius: 10px;\r\n    box-shadow: 2px 2px 25px 2px rgba(0, 0, 0, 0.25);;\r\n    width: 80%;\r\n    margin: 5rem;\r\n    overflow: auto; \r\n  }\r\n\r\n  .image {\r\n    width: 100%;\r\n    display: grid;\r\n    object-fit: contain;\r\n  }\r\n\r\n  .formContainer {\r\n    margin: 2.5rem 1rem;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n  }\r\n\r\n  .logo {\r\n    height: 5.5rem;\r\n    object-fit: contain;\r\n  }\r\n\r\n  @media (max-width: 700px) {\r\n    .paperStyle {\r\n      padding: 1rem; \r\n      margin: 1rem; \r\n    }\r\n  }\r\n\r\n  .formItem{\r\n    margin-top: 1rem;\r\n  }\r\n\r\n  .login{\r\n    align-items: center;\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentDiv": `LoginPage_parentDiv__XnjPd`,
	"paperStyle": `LoginPage_paperStyle__PQ+BY`,
	"image": `LoginPage_image__-6j+4`,
	"formContainer": `LoginPage_formContainer__c4bhv`,
	"logo": `LoginPage_logo__Jl7X0`,
	"formItem": `LoginPage_formItem__hgJEz`,
	"login": `LoginPage_login__qsQeI`
};
export default ___CSS_LOADER_EXPORT___;
