import React  from 'react';
import { useDispatch } from 'react-redux';
import { openDeleteChargeModal, openEditChargeModal } from '../../Store/Slices/chargeSlice';
import { PaginationDetails } from '../../Utils/Utils';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Pagination , Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const ChargeList = ({data}) => {
    const dispatch = useDispatch();
    const { page, count, handleChange, _DATA } = PaginationDetails(data);

    const handleEdit = (chargeId) => {
        dispatch(openEditChargeModal(chargeId));
    };

    const handleDelete = (chargeId) => {
        dispatch(openDeleteChargeModal(chargeId));
    };

    const currentData = _DATA?.currentData() || data;

    const roles = sessionStorage.getItem("roles");


    return (
        <>
            <TableContainer sx={{ height: "43rem" }}>
                <Table  sx={{borderCollapse:'separate' , borderSpacing:'0px 4px' , height:'max-content'}} stickyHeader>
                    <TableHead  >
                        <TableRow >
                            <TableCell width={50}> Charge Id</TableCell>
                            <TableCell width={50}> Charge Description </TableCell>
                            <TableCell width={50}> Amount </TableCell>
                            <TableCell width={50}> Percentage </TableCell>
                            <TableCell align="center" width={"30%"}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {currentData.map((charge) => (
                            <TableRow sx={{ height: "30px" }} key={charge.chargeId}>
                                <TableCell  component='th' scope='row' sx={{ borderBottom: "none", fontWeight: 'bold', width: 100, height: 'auto !important' }}>#{charge.chargeId}</TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 150, height: 'auto !important' }}>{charge.chargeDesc}</TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 150, height: 'auto !important' }}>{charge.amount}</TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 150, height: 'auto !important' }}>{charge.percentage}</TableCell>
                                <TableCell align="center" sx={{ borderBottom: "none", width: '15%', height: 'auto !important' }}>
                                   {roles?.split(",").includes("PRIV_ES_EDIT_CHARGE") ?   (<IconButton onClick={() => handleEdit(charge.chargeId)}>
                                        <EditIcon />
                                    </IconButton>) : null}
                                    {roles?.split(",").includes("PRIV_ES_DELETE_CHARGE") ?  (<IconButton onClick={() => handleDelete(charge.chargeId)}>
                                        <DeleteIcon />
                                    </IconButton>) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            
            </TableContainer>
            <Box my={2} display="flex" justifyContent="center">
                <Pagination
                    count={count}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                    size='large'
                    variant = 'outlined'
                />
            </Box>
                
        
          
        </>
    );

};

export default ChargeList;