import { useState } from "react";
import usePagination from "../Hooks/usePagination";

 export const PaginationDetails = (data) => {
    let [page, setPage] = useState(1);
    const PER_PAGE = 3;

    const _DATA = usePagination(data, PER_PAGE);

    const count = Math.ceil(data?.length / PER_PAGE) || 0;
    
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    return { page, count, handleChange, _DATA };
};