import {apiSlice} from '../Slices/apiSlice';


export const PrivilegeService = apiSlice.injectEndpoints({
    endpoints : builder => ({
        getAllPrivs : builder.query({
            query : () => "/sec-priv",
            providesTags : ['priv']
        }),

        getPrivById : builder.query({
            query : (privId) => `/sec-priv/${privId}`,
            providesTags : ['priv']
        }),

        addPriv : builder.mutation({
            query : (privDetails) => ({
                url : '/sec-priv',
                method:'POST',
                body:privDetails
            }),
            invalidatesTags:['priv']
        }),

        updatePriv : builder.mutation({
            query:(privDetails) => ({
                url : `/sec-priv/${privDetails.privId}`,
                method:'PUT',
                body:privDetails
            }),
            invalidatesTags:['priv']
        }),

        deletePriv :  builder.mutation({
            query : (privId) => ({
                url:`/sec-priv/${privId}`,
                method:'DELETE',
            }),
            invalidatesTags:['priv']
        })

       
    })
});


export const {
    useGetAllPrivsQuery,
    useGetPrivByIdQuery,
    useAddPrivMutation,
    useUpdatePrivMutation,
    useDeletePrivMutation,
} = PrivilegeService;