import { apiSlice } from "../Slices/apiSlice";

export const ResCalService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getAllResCal: builder.query({
      query: () => "/res-cal",
      providesTags: ["resource"],
    }),

    getResCalById: builder.query({
      query: (resCalId) => `/res-cal/${resCalId}`,
      providesTags: ["resource"],
    }),

    getResCalSlotByResCalId: builder.query({
      query: (resCalId) => `/res-cal-slot/get-by-resCalId/${resCalId}`,
      providesTags: ["resource"],
    }),

    createResCal: builder.mutation({
      query: (Details) => ({
        url: `/res-cal`,
        method: "POST",
        body: Details,
      }),
      invalidatesTags: ["resource"],
    }),

    updateResCal: builder.mutation({
      query: (details) => ({
        url: `/res-cal/${details.resCalId}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["resource"],
    }),

    deleteResCal: builder.mutation({
      query: (resCalId) => ({
        url: `/res-cal/${resCalId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["resource"],
    }),  
  }),
});

export const {
  useGetAllResCalQuery,
  useGetResCalByIdQuery,
  useGetResCalSlotByResCalIdQuery,
  useCreateResCalMutation,
  useUpdateResCalMutation,
  useDeleteResCalMutation,
} = ResCalService;
