import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  MenuItem,
  Grid,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import styles from "./BrPriv.module.css";
import { useCreateMappingMutation } from "../../Store/Services/brPrivService";
import { useGetAllBrsQuery } from "../../Store/Services/BrService";
import { useGetAllPrivsQuery } from "../../Store/Services/PrivilegeService";

const schema = yup
  .object({
    brId: yup.string().notOneOf(["init"]).required(""),
  })
  .required();

const AddBrPriv = ({ handleClose }) => {
  const [privileges, setPrivileges] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: roleData } = useGetAllBrsQuery();
  const roles = roleData?.map((br) => (
    <MenuItem key={br.brId} value={br.brId}>
      {br.brName}
    </MenuItem>
  ));
  
  const { data: privilegeData, isSuccess: privSuccess } = useGetAllPrivsQuery();
  const privList = privilegeData?.map((priv) => ({
    privId: priv.privId,
    privName: priv.privName,
  }));

  const [createBrPriv] = useCreateMappingMutation();

  const onSubmit = async (data) => {
    const dataSet = {
      ...data,
      privIds: privileges,
    };
    if (privileges.length !== 0) {
      try {
        const respond = await createBrPriv(dataSet).unwrap();
        // toast.success("BrPriv successfully added!");
        handleClose();
      } catch (err) {
        console.error("Failed to save the BrPriv: ", err);
        toast.error("Erorr, something went wrong!", {position:'bottom-right'});
      }
    }
  };

  return (
<div className={styles.container}>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Add Privilege to Role
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItem}>
          <label className={styles.formLabel}>Role:</label>
          {errors.brId && errors.brId.type === "notOneOf" && (
            <span className={styles.validation}> *</span>
          )}
          <Select
            {...register("brId")}
            defaultValue="init"
            className={styles.formInput}
            fullWidth
            size="small"
          >
            <MenuItem value="init">Select a Role</MenuItem>
            {roles}
          </Select>
        </div>

        <div className={styles.formItem}>
          <label className={styles.formLabel}>Privileges:</label>
          {privileges?.length === 0 && (
            <span className={styles.validation}> *</span>
          )}
          {privSuccess && (
            <Autocomplete
              className={styles.formInput}
              onChange={(_, newPriv) => {
                setPrivileges(newPriv.map((priv) => priv.privId));
              }}
              size="small"
              multiple
              filterSelectedOptions
              isOptionEqualToValue={(option, value) =>
                option.privName === value.privName
              }
              options={privList}
              getOptionLabel={(option) => option.privName}
              renderInput={(params) => (
                <TextField placeholder="User Privileges" {...params} />
              )}
            />
          )}
        </div>
        {/* Submit Button */}
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>

      </form>
    </div>
  );
};

export default AddBrPriv;
