import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useAddChargeMutation } from "../../Store/Services/ChargeService";
import styles from "./Charge.module.css";
import CategoryList from "../Shared/CategoryList";
import { toast } from "react-toastify";

const schema = yup.object({
  chargeDesc: yup.string().required("Charge Description is required"),
  amount: yup.string().test({
    test: function(value) {
      const percentageFieldValue = this.parent.percentage;
      return value || percentageFieldValue;
    },
  }),
  percentage: yup.string().test({
    test: function(value) {
      const amountFieldValue = this.parent.amount;
      return value || amountFieldValue;
    },
  }),
}).required();

const AddCharge = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [addCharge] = useAddChargeMutation();

  const [openCatList, setOpenCatList] = useState(false);
  const [catName, setCatName] = useState("");
  const [selectedCat, setSelectedCat] = useState();
  const [disabledFields , setDisabledFields] = useState({
    amount:false,
    percentage:false,
  })


  const handleAmountChange = (event) => {
    const amountValue = event.target.value;
    setDisabledFields({amount:false,percentage:amountValue.length>1});
  }

  const handlePercentageChange = (event) => {
    const percentageValue = event.target.value;
    setDisabledFields({amount:percentageValue.length > 1,percentage:false})
  }

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {

    if(disabledFields.amount){
      data.amount = 0;
    }

    if(disabledFields.percentage){
      data.percentage = 0;
    }

    const formData = {
      ...data,
      catId: selectedCat || 0,
    };

    try {
      await addCharge(formData).unwrap();
      handleClose();
      toast.success("Charge added successfully !", {
        position: "bottom-right",
      });
    } catch (err) {
      console.error("Failed to save charge", err);
      toast.error("Erorr, something went wrong!", { position: "bottom-right" });
    }

    handleClose();
  };

  const clearCatgeory = () => {
    setSelectedCat(undefined);
    setCatName('');
  };

  const handleBackSpace = (event) => {
    if(event.key === 'Backspace' && selectedCat){
      clearCatgeory();
    }
  }

  const onKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography
            fontWeight={600}
            className={styles.dialogTitle}
            variant="h6"
          >
            Add Charge
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Charge Description :</label>
          {errors.chargeDesc && errors.chargeDesc.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("chargeDesc")}
            className={styles.formInput}
            placeholder="Enter Charge Description"
            size="small"
            fullWidth
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formLabel}>Category:</label>
          <OutlinedInput
            className={styles.formInput}
            placeholder="Select Category"
            size="small"
            onClick={() => setOpenCatList(true)}
            onKeyDown={handleBackSpace}
            autoComplete="off"
            fullWidth
            value={catName}
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Amount :</label>
          {errors.amount && errors.amount.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("amount")}
            className={styles.formInput}
            placeholder="Enter Amount"
            size="small"
            autoComplete="off"
            onKeyDown={onKeyDown}
            disabled={disabledFields.amount}
            onChange={handleAmountChange}
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Percentage :</label>
          {errors.percentage && errors.percentage.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("percentage")}
            className={styles.formInput}
            placeholder="Enter Percentage"
            size="small"
            autoComplete="off"
            disabled={disabledFields.percentage}
            onChange={handlePercentageChange}
            onKeyDown={onKeyDown}
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>

      <CategoryList
        open={openCatList}
        onClose={() => setOpenCatList(false)}
        setSelectedCat={setSelectedCat}
        selectedCat={selectedCat}
        setCatName={setCatName}
        onlySubCategories={true}
      />
    </div>
  );
};

export default AddCharge;
