import React from "react";
import styles from "./Management.module.css";
import { Box, Button, Paper, Toolbar, Container, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useGetAllSlotsQuery } from "../Store/Services/SlotService";
import { openAddSlotModal } from "../Store/Slices/slotSlice";
import SlotDialog from "../Components/Slot/SlotDialog";
import SearchBar from "../Components/Shared/SearchBar";
import SlotTable from "../Components/Slot/SlotTable";

const SlotManagement = () => {

    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState([]);
  
    const {
      data: slot,
      isLoading,
    } = useGetAllSlotsQuery();

    const roles = sessionStorage.getItem("roles")
  
  return (
    roles?.split(",").includes("PRIV_ES_DISPLAY_SLOTS") ? (
    <Paper elevation={3} className={styles.pageContainer} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/thumbnail_Management.png)`}}>
      <Box>
        <Toolbar className={styles.subNavBar}>
          <Typography className={styles.topic}>Slot Management</Typography>
          {roles?.split(",").includes("PRIV_ES_ADD_SLOT") ?  (<Button
            variant="contained"
            color="primary"
            className={styles.buttonItem}
            onClick={() => dispatch(openAddSlotModal())}
          >
            Add Slot
          </Button> ) : null}
        </Toolbar>
      </Box>

      <Container className={styles.container}>
        {!isLoading && (
          <SearchBar
            data={slot || []}
            setSearchData={setSearchData}
            text="Search Slots"
            search="slot"
          />
        )}
      </Container>

      <Container>
        <SlotTable data={searchData} />
      </Container>

      <SlotDialog />

    </Paper> ) : null
  );
};

export default SlotManagement;
