import { createSlice } from "@reduxjs/toolkit";

export const priceSlice = createSlice({
  name: "price",
  initialState: {
    modalStatus: null,
    selectedPrice: null,
  },

  reducers: {
    openAddPriceModal: (state) => {
      state.modalStatus = "add";
    },
    openEditPriceModal: (state, action) => {
      state.modalStatus = "edit";
      state.selectedPrice = action.payload;
    },
    openDeletePriceModal: (state, action) => {
      state.modalStatus = "delete";
      state.selectedPrice = action.payload;
    },
    closePriceModal: (state) => {
      state.modalStatus = false;
      state.selectedPrice = null;
    },
  },
});

export const {
  openAddPriceModal,
  openEditPriceModal,
  openDeletePriceModal,
  closePriceModal,
} = priceSlice.actions;

export default priceSlice.reducer;
