import { Grid, Typography } from '@mui/material';
import React from 'react';
import Styles from "./ourStory.module.css";

function OurStory() {
    return (
        <div className={Styles.outerContainer}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={Styles.topic}>Our Story</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={`${process.env.REACT_APP_IMAGE_URL}DoneRight/PAGES/groupFlag.png`} alt="Men" className={Styles.image} loading='lazy' />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography className={Styles.description}>
                    At DoneRight, we understand the essence of every clean space. Whether it's an office seeking pristine professionalism, a home longing for comfort, or a garden yearning for freshness, we're here to make it happen.<br /><br />
                    But we're not just about cleaning; we're about sustainability too. By incorporating eco-friendly practices and innovative techniques, we forge a deeper connection with our clients and the environment.
                    With DoneRight, your space isn't just clean, it's a reflection of our commitment to perfection.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default OurStory;

