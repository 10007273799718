import React , {useState} from 'react';
import { useDispatch } from 'react-redux';
import { openDeleteAreaModal, openEditAreaModal } from '../../Store/Slices/areaSlice';
import { PaginationDetails } from '../../Utils/Utils';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Pagination , Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './AreaList.module.css';
import usePagination from '../../Hooks/usePagination';




const AreaList = ({ data , stateMap }) => {
    const dispatch = useDispatch();
    const { page, count, handleChange, _DATA } = PaginationDetails(data);


    const handleEdit = (areaId) => {
        dispatch(openEditAreaModal(areaId));
    };

    const handleDelete = (areaId) => {
        dispatch(openDeleteAreaModal(areaId));
    };

    const roles = sessionStorage.getItem("roles");

    const currentData = _DATA?.currentData() || data;

    return (
        <>
            <TableContainer sx={{ height: "43rem"}} >
                <Table  sx={{borderCollapse:'separate' , borderSpacing:'0px 4px' , height:'max-content'}} stickyHeader>
                    <TableHead  >
                        <TableRow >
                            <TableCell width={50} >Area Id</TableCell>
                            <TableCell width={50}> State </TableCell>
                            <TableCell width={50}> Area </TableCell>
                            <TableCell align="center" width={"30%"}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {currentData.map((area) => (
                            <TableRow   sx={{ height: "30px" }} key={area.areaId}>
                                <TableCell  component='th' scope='row' sx={{ borderBottom: "none", fontWeight: 'bold', width: 150, height: 'auto !important' }}>#{area.areaId}</TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 150, height: 'auto !important' }}> {stateMap[area.stateId] || area.stateId} </TableCell>
                                <TableCell sx={{ borderBottom: "none", width: 150, height: 'auto !important' }}>{area.desc}</TableCell>
                                <TableCell align="center" sx={{ borderBottom: "none", width: '15%', height: 'auto !important' }}>
                                    {roles?.split(",").includes("PRIV_ES_EDIT_AREA") ? (<IconButton onClick={() => handleEdit(area.areaId)}>
                                        <EditIcon />
                                    </IconButton> ) : null}
                                    {roles?.split(",").includes("PRIV_ES_DELETE_AREA") ?  (<IconButton onClick={() => handleDelete(area.areaId)}>
                                        <DeleteIcon />
                                    </IconButton> ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            
            </TableContainer>
            <Box my={1}    display="flex" justifyContent="center">
                <Pagination
                    count={count}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                    variant='outlined'
                    size='large'
                />
            </Box>
                
        
          
        </>
    );
};

export default AreaList;
