import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  OutlinedInput,
  Grid,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from "./Request.module.css";
import { useUpdateRequestStatusMutation } from "../../Store/Services/RequestService";
import { toast } from "react-toastify";

const RequestStartDialog = ({ onClose, reqId }) => {
  const [comment, setComment] = useState("");
  const [UpdateRequest] = useUpdateRequestStatusMutation();

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    UpdateRequest({
      reqId: reqId,
      resStatusId: 6,
      sender: "Admin",
      remark: comment,
    })
      .unwrap()
      .then((updatedRequest) => {
        toast.success("Status successfully updated!", {
          position: "bottom-right",
        });

        onClose();
      })
      .catch((error) => {
        toast.error("Error updating request status!", {
          position: "bottom-right",
        });

        console.error("Error updating request status:", error);
      });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <div className={styles.headingContainer}>
          <Typography fontWeight={600} variant={"h6"}>
            Start Request
          </Typography>
          <IconButton className={styles.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}>Comment:</label>
              <div>
                <OutlinedInput
                  multiline
                  className={styles.formInput}
                  placeholder="Add your comment"
                  fullWidth
                  value={comment}
                  onChange={handleCommentChange}
                />
              </div>
            </div>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            {" "}
            <Button
              type="submit"
              className={styles.submitButton}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RequestStartDialog;
