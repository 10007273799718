import { Box, Button, Container, Paper, Toolbar, Typography } from "@mui/material"
import { useState } from "react";
import { useDispatch } from "react-redux"
import SearchBar from "../Components/Shared/SearchBar";
import ChargeDialog from "../Components/Charges/ChargeDialog";
import ChargeList from "../Components/Charges/ChargeList";
import { useGetAllChargesQuery } from "../Store/Services/ChargeService";
import { closeChargeModal, openAddChargeModal } from "../Store/Slices/chargeSlice";

import styles from "./Management.module.css"

const ChargesManagement = () => {
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState([])
    const { data, isSuccess, isLoading } = useGetAllChargesQuery();


    const handleClose = () => {
        dispatch(closeChargeModal());
    }

    const roles = sessionStorage.getItem("roles")

    return (
        roles?.split(",").includes("PRIV_ES_DISPLAY_CHARGES") ? (
        <Paper elevation={3} className={styles.pageContainer}>
            <Box>
                <Toolbar className={styles.subNavBar}>
                    <Typography className={styles.topic}>Charges Management</Typography>
                    {roles?.split(",").includes("PRIV_ES_ADD_CHARGE") ?  (<Button variant="contained" color="primary" className={styles.buttonItem} onClick={() => dispatch(openAddChargeModal())}>Add Charge</Button>) : null}
                </Toolbar>
            </Box>
            <Container id={styles.container}>
                <SearchBar
                    data={isSuccess || isLoading ? data : []}
                    setSearchData={setSearchData}
                    text="Search Charges"
                    search="charge"
                />
            </Container>

            <Container>
               {isSuccess && <ChargeList data={Array.isArray(searchData) && searchData.length > 0 ? searchData : data}/>}
            </Container>

            <ChargeDialog handleClose={handleClose} />
        </Paper> ) : null
    )
}

export default ChargesManagement
