import { createSlice } from "@reduxjs/toolkit";


export const freqSlice = createSlice({
    name : "freq",
    initialState:{
        modalStatus:null,
        selectedFreq : null
    },


    reducers:{
        openAddFreqModal : (state) => {
            state.modalStatus = "add";
        },
        openEditFreqModal : (state,action) => {
            state.modalStatus = "edit";
            state.selectedFreq = action.payload;
        },
        closeFreqModal : (state) => {
            state.modalStatus = null;
            state.selectedFreq = null
        },
        openDeleteFreqModal : (state,action) => {
            state.modalStatus = "delete";
            state.selectedFreq = action.payload
        }
    }
});


export const { 
    openAddFreqModal,
    openEditFreqModal,
    openDeleteFreqModal,
    closeFreqModal
} = freqSlice.actions;

export default freqSlice.reducer;