import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDeleteResourceMutation } from "../../Store/Services/ResourceService";
import { closeResourceModal } from "../../Store/Slices/resourceSlice";
import DeletePopUp from "../Shared/DeletePopUp";

const DeleteResource = () => {
  const dispatch = useDispatch();
  const resource = useSelector((state) => state.resource);

  const [deleteResource] = useDeleteResourceMutation();

  const onDeleteConfirm = async () => {
    const id = resource.selectedResource;
    try {
      const response = await deleteResource(id);
      const deletedResource = response.data; 
      toast.success("Resource successfully deleted!", {position:'bottom-right'});
    } catch (err) {
      console.error("Failed to delete resource: ", err);
      toast.error("Error, something went wrong!", {position:'bottom-right'});
    }
    dispatch(closeResourceModal());
  };

  return (
    <DeletePopUp
      data="Resource"
      close={() => dispatch(closeResourceModal())}
      onDeleteConfirm={onDeleteConfirm}
    />
  );
};

export default DeleteResource;
