import { apiSlice } from '../Slices/apiSlice';

export const SlotService = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllSlots: builder.query({
            query: () => "/slots",
            providesTags: ['slot']
        }),

        getSlotById: builder.query({
            query: (slotId) => `/slots/${slotId}`,
            providesTags: ['slot']
        }),

        createSlot: builder.mutation({
            query: (details) => ({
                url: '/slots',
                method: 'POST',
                body: details
            }),
            invalidatesTags: ['slot']
        }),

        updateSlot: builder.mutation({
            query: (details) => ({
                url: `/slots/${details.slotId}`,
                method: 'PUT',
                body: details
            }),
            invalidatesTags: ['slot']
        }),

        deleteSlot: builder.mutation({
            query: (slotId) => ({
                url: `/slots/${slotId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['slot']
        }),

    })
});

export const {
    useGetAllSlotsQuery,
    useGetSlotByIdQuery,
    useCreateSlotMutation,
    useUpdateSlotMutation,
    useDeleteSlotMutation

} = SlotService;