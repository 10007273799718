import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  Button,
  IconButton,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useAddPriceMutation } from "../../Store/Services/PriceService";
import { useGetAllNoResQuery } from "../../Store/Services/NoOfResourceService";
import { useGetAllNoHrsQuery } from "../../Store/Services/NoOfHrsService";
import { useForm } from "react-hook-form";
import CategoryList from "../Shared/CategoryList";
import { useDispatch } from "react-redux";
import styles from "./Price.module.css";

const schema = yup
  .object({
    catId: yup.string().required(""),
    noResId: yup.string().required(""),
    noHrsId: yup.string().required(""),
    off: yup.string().required(""),
    amount: yup.string().required(""),
  })
  .required();

const AddPrice = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [addPrice] = useAddPriceMutation();
  const [openCatList, setOpenCatList] = useState(false);
  const [catName, setCatName] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [noResOptions, setNoResOptions] = useState([]);
  const [noHrsOptions, setNoHrsOptions] = useState([]);


  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { data: noResData } = useGetAllNoResQuery();
  const { data: noHrsData } = useGetAllNoHrsQuery();

  useEffect(() => {
    if (noResData) {
      const mappedOptions = noResData.map((option) => ({
        value: option.noRes,
        label: option.noRes, 
      }));
      setNoResOptions(mappedOptions);
    }
  }, [noResData]);

  useEffect(() => {
    if (noHrsData) {
      const mappedOptions = noHrsData.map((option) => ({
        value: option.noHrs, 
        label: option.noHrs, 
      }));
      setNoHrsOptions(mappedOptions);
    }
  }, [noHrsData]);


  const onSubmit = async (data) => {
    const formData = {
      ...data,
      catId: selectedCat,
      noResId: parseInt(data.noResId, 10), 
      noHrsId: parseInt(data.noHrsId, 10), 
    };

    try {
      await addPrice(formData).unwrap();
      toast.success("Price successfully created!", { position: "bottom-right" });
    } catch (err) {
      console.error("Failed to save price ", err);
      toast.error("Error, something went wrong!", { position: "bottom-right" });
    }
    handleClose();
  };

  const onKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  }

  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
            Add Price
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className={styles.formItem}>
          <label className={styles.formLabel}>Category:</label>
          {selectedCat === "" && <span className={styles.validation}> *</span>}
          <OutlinedInput
            {...register("catId")}
            className={styles.formInput}
            placeholder="Select Category"
            size="small"
            onClick={() => setOpenCatList(true)}
            autoComplete="off"
            fullWidth
            value={catName}
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> No Of Resource :</label>
          {errors.noResId && errors.noResId.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <Select
            {...register("noResId")}
            className={styles.formInput}
            placeholder="Select Number of Resource"
            size="small"
            fullWidth
          >
            {noResOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> No Of Hours :</label>
          {errors.noHrsId && errors.noHrsId.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <Select
            {...register("noHrsId")}
            className={styles.formInput}
            placeholder="Select Number of Hours"
            size="small"
            fullWidth
          >
            {noHrsOptions.map((option) => (
              <MenuItem   key={option.labe} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Offer :</label>
          {errors.off && errors.off.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("off")}
            className={styles.formInput}
            placeholder="Enter Offer"
            size="small"
            autoComplete="off"
            onKeyDown={onKeyDown}
            fullWidth
          />
        </div>
        <div className={styles.formItem}>
          <label className={styles.formLabel}> Amount :</label>
          {errors.amount && errors.amount.type === "required" && (
            <span className={styles.validation}> *</span>
          )}
          <OutlinedInput
            {...register("amount")}
            className={styles.formInput}
            placeholder="Enter Amount"
            size="small"
            autoComplete="off"
            onKeyDown={onKeyDown}
            fullWidth
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
      <CategoryList
        open={openCatList}
        onClose={() => setOpenCatList(false)}
        setSelectedCat={setSelectedCat}
        selectedCat={selectedCat}
        setCatName={setCatName}
        onlySubCategories={true}
      />
    </div>
  );
};


export default AddPrice;
