import { apiSlice } from "../Slices/apiSlice";



export const NoOfResourceService = apiSlice.injectEndpoints({
    endpoints : builder => ({
        getAllNoRes : builder.query({
            query : () => "/no-res/get-allNoRes",
            providesTags:['res']
        }),

        getNoOfResById : builder.query({
            query : (noResId) => `/no-res/${noResId}`,
            providesTags: ['res']
        }),

        addNoOfRes : builder.mutation({
            query : (noOfResDetails) => ({
                url : '/no-res',
                method:'POST',
                body:noOfResDetails
            }),
            invalidatesTags:['res']
        }),

        updateNoOfRes : builder.mutation({
            query : (noOfResDetails) => ({
                url : `/no-res/${noOfResDetails.noResId}`,
                method:'PUT',
                body:noOfResDetails
            }),
            invalidatesTags:['res']
        }),

        deleteNoOfRes : builder.mutation({
            query : (noResId) => ({
                url : `/no-res/${noResId}`,
                method : 'DELETE',
            }),
            invalidatesTags:['res']
        })
    })
});


export const {
    useGetAllNoResQuery,
    useGetNoOfResByIdQuery,
    useAddNoOfResMutation,
    useUpdateNoOfResMutation,
    useDeleteNoOfResMutation,
} = NoOfResourceService;